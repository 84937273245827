import React from 'react';
import DefaultTheme from 'Themes/Default';
import axios from 'axios';

import UserContextProvider from 'Hooks/User/UserContextProvider';
import LoadingContextProvider from 'Hooks/Loading/LoadingContextProvider';
import ClinicContextProvider from 'Hooks/Clinic/ClinicSelect/ContextProvider';
import ModalContextProvider from 'Hooks/Modals/ModalContextProvider';
import Router from './Router';

const getTheme = async () => {
  const {data} = await axios.get('/api/v2/theme');
  return data;
}

const App = () => (
	<DefaultTheme variables={getTheme()}>
		<LoadingContextProvider>
			<UserContextProvider>
				<ModalContextProvider>
					<ClinicContextProvider>
						<Router />
					</ClinicContextProvider>
				</ModalContextProvider>
			</UserContextProvider>
		</LoadingContextProvider>
	</DefaultTheme>
);

export default App;
