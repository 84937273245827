import get from 'lodash/get';
import React, { useCallback, useEffect, useState } from 'react';

import { ContainerTech } from '../../Components/Containers';
import { Divider, RegularTitle } from '../../Components/Typography';
import validateCpf from '../../Utils/validateCpf';
import { Control, HelperText, Input, SubmitButton } from './Components';

const Pessoal = ({ data, onSave = () => { } }) => {
	const contact = get(data, 'beneficiary', {});

	const [values, setValues] = useState({
		name: get(contact, 'name.full', ''),
		cpf: get(contact, 'cpf', ''),
		birthdate: formatDate(get(contact, 'birthdate')),
		gender: get(contact, 'gender', 'Masculino'),
	});

	const [errors, setErrors] = useState({});

	const onChange = name => ({ target: { value } }) => setValues(v => ({ ...v, [name]: value }));
	const readonly = name => !!get(data.beneficiary, name);

	useEffect(() => {
		setValues({
			name: get(contact, 'name.full', ''),
			cpf: get(contact, 'cpf', ''),
			birthdate: formatDate(get(contact, 'birthdate')),
			gender: get(contact, 'gender', 'Masculino'),
		});
	}, [data]);

	const onCpfBlur = useCallback(() => {
		setErrors({ cpf: validateCpf(values.cpf.replace(/\D/g, '')) ? null : 'CPF inválido' });
	}, [values.cpf]);

	return (
		<>
			<ContainerTech>
				<RegularTitle>Dados Pessoais</RegularTitle>
				<Control data-required="true">
					<span>Nome</span>
					<Input
						type="text"
						value={values.name}
						onChange={onChange('name')}
						readOnly={readonly('name.full')}
						autoComplete={Math.random()}
					/>
				</Control>

				<Control data-required="true">
					<span>CPF</span>
					{errors.cpf != null ? <HelperText data-invalid>{errors.cpf}</HelperText> : null}

					<Input
						type="text"
						mask="999.999.999-99"
						value={values.cpf}
						onChange={onChange('cpf')}
						readOnly={readonly('cpf')}
						autoComplete={Math.random()}

						onBlur={onCpfBlur}
						data-invalid={errors.cpf != null}
					/>
				</Control>

				<Control data-required="true">
					<span>Data de Nascimento</span>
					<Input
						type="text"
						value={values.birthdate}
						mask="99/99/9999"
						placeholder="DD/MM/AAAA"
						readOnly={readonly('birthdate')}
						onChange={onChange('birthdate')}
					/>
				</Control>

				<Control data-required="true" data-show-arrow="true">
					<span>Sexo</span>
					<Input as="select" value={values.gender} onChange={onChange('gender')} data-default="true">
						<option>Masculino</option>
						<option>Feminino</option>
						<option value="Não se Aplica">Outro</option>
						<option value="Desconhecido">Prefiro não informar</option>
					</Input>
				</Control>
				{readonly('name.full') || readonly('cpf') || readonly('birthdate') ? (
					<HelperText>
						Nome, CPF e Data de nascimento só podem ser alterados entrando em contato via{' '}
						<a href="tel:0800 500 0404">0800 500 0404</a> ou <a href="https://wa.me/5508005000404">WhatsApp</a>
					</HelperText>
				) : null}
			</ContainerTech>

			<Divider />
			<br />
			<ContainerTech>
				<RegularTitle>Alterar/Cadastrar Senha</RegularTitle>
				<HelperText>(opcional)</HelperText>
				<br />
				<Control>
					<span>Nova senha</span>
					<Input type="password" autoComplete="new-password" onChange={onChange('pass')} />
				</Control>
				<Control>
					<span>Confirmar senha</span>
					<Input type="password" autoComplete="new-password" onChange={onChange('confirmPass')} />
				</Control>

				{get(data, 'boundType') === 'Titular' ? (
					<Control>
						<span>Senha atual</span>
						<Input type="password" autoComplete="new-password" onChange={onChange('currentPass')} />
					</Control>
				) : null}

				<SubmitButton style={{ marginTop: 16 }} onClick={() => onSave('personal', values)}>
					Salvar Alterações
				</SubmitButton>
			</ContainerTech>
		</>
	);
};

const formatDate = dd => {
	if (!dd) return '';

	const date = dd.replace(/T.+/, '').split('-');
	const d = new Date(date[0], date[1] - 1, date[2], 12, 0, 0);
	const p0 = s => (String(s).length === 1 ? `0${s}` : s);

	return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(p0).join('');
};

export default Pessoal;
