import { useState, useEffect } from 'react';

import useField from 'Hooks/General/useField';
import useTabs from 'Hooks/General/useTabs';
import useClinicContext from 'Hooks/Clinic/ClinicSelect';
import useUser from 'Hooks/User';

import { getData, getUserLocation } from './effects';

const useSearch = () => {
	const { user } = useUser();
	const { onClinicClose } = useClinicContext();
	const { setTab, isTabActive, tab } = useTabs(['consultas', 'exames', 'telemedicina', 'farmacias']);

	const [specialities, setSpecialities] = useState({ allSpecialities: [], telemedicineSpecialities: [] });
	const [places, setPlaces] = useState([]);
	const specialityField = useField();
	const placeField = useField();

	const onTabClick = item => () => setTab(item);
	const search = () => {
		const params = {
			speciality: isTabActive('exames') ? 'Laboratório' : specialityField.value,
			place: placeField.value,
			telemedicina: isTabActive('telemedicina') ? 'sim' : 'não',
		};

		if (isTabActive('telemedicina')) {
			delete params.place;
		}

		const parsed = new URLSearchParams(params).toString();
		window.location.hash = `/search?${decodeURIComponent(parsed)}`;
	};

	useEffect(() => {
		const myLocationOption = {
			value: 'Minha Localização',
			omnipresent: true,
			async onClick() {
				try {
					const { lat, lng } = await getUserLocation();

					return { value: `@${lat},${lng}` };
				} catch (e) {
					return { value: null };
				}
			},
		};
		getData().then(({ allPlaces, allSpecialities, telemedicineSpecialities }) => {
			setPlaces([myLocationOption, ...allPlaces]);
			setSpecialities({ allSpecialities, telemedicineSpecialities });
		});
	}, []);

	useEffect(onClinicClose, []);

	const farmas = [
		{ img: '/img/farmas/droga-araujo.png', site: 'https://www.araujo.com.br/' },
		{ img: '/img/farmas/droga-mina-brasil.png', site: 'https://www.drogariaminasbrasil.com.br/' },
		{ img: '/img/farmas/droga-pacheco.png', site: 'https://www.drogariaspacheco.com.br/' },
		{ img: '/img/farmas/droga-raia-logo.png', site: 'https://www.drogaraia.com.br/' },
		{ img: '/img/farmas/droga-sao-paulo.png', site: 'https://www.drogariasaopaulo.com.br/' },
		{ img: '/img/farmas/drogasil-logo.png', site: 'https://www.drogasil.com.br/', h: 36 },
		{ img: '/img/farmas/paguemenos-logo.png', site: 'https://www.paguemenos.com.br/' },
		{ img: '/img/farmas/panvel_logo.png', site: 'https://www.panvel.com.br/' },
	];

	return { specialities, places, specialityField, placeField, onTabClick, search, isTabActive, farmas, tab, user };
};

export default useSearch;
