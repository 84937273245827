import styled from 'styled-components';

const Logo = styled.div`
	grid-area: logo;
	background: url('/img/logo-header.svg');
	background-size: contain;
	background-repeat: no-repeat;
	display: block;
	height: 33px;
	width: 43px;
	justify-self: center;
	align-self: center;
`;

export default Logo;
