import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ShowSchedule = styled.a`
	font-size: 15px;
	width: 167px;
	margin-bottom: 47px;
	color: ${({ theme }) => theme.btnBlue};
	text-decoration: underline;
	display: block;
	justify-content: left;
	padding-left: 0;
	border: 0;
	font-family: Lato, sans-serif;
`;

export const btnTabs = styled.h5`
	height: 21px;
	font-size: 18px;
	font-weight: bold;
	color: #4a4a4a;
`;

export const SearchButton = styled.button`
	grid-area: search-button;
	height: 2.25em;
	font-size: 18px;
	border-radius: 20px;
	color: #ffffff;
	background: ${({ theme }) => theme.primary};
	font-family: Lato, sans-serif;
	border: 0;

	.invert-colors & {
		color: ${({ theme }) => theme.primary};
		background: #ffffff;
	}

  ${({ keepOpacityOnDisable }) => keepOpacityOnDisable ? ({
    "[disabled], :disabled": {
      opacity: 1,
      background: "lightgray"
    }
  }) : ({})}
`;

export const ContractButton = styled(SearchButton)`
	margin-top: 18px;
	width: 100%;
	height: 48px;
	font-family: Lato, sans-serif;
`;

export const TimeButton = styled(SearchButton)`
	width: 162px;
	height: 37px;
	margin-top: 12px;
	font-family: Lato, sans-serif;
`;

export const CornerButton = styled.div`
	position: absolute;
	right: 0;
	top: 0;
	background: url('/img/card-corner.svg') no-repeat;
	background-repeat: no-repeat;
	height: 74px;
	width: 74px;
	padding: 3px 5px 0px 26px;
	box-sizing: border-box;
	font-family: Lato, sans-serif;

	> span {
		position: absolute;
		font-size: 10px;
		right: 3px;
		line-height: 1.5;
		font-weight: 700;
	}
`;

export const MoreInfo = styled.p`
	grid-area: more-info;
	font-size: 12px;
	text-align: left;
	align-self: self-end;
	font-family: Lato, sans-serif;
`;

const LinkB = styled(TimeButton)`
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	${({ variant }) =>
		variant === 'transparent' && {
			padding: '0.4rem',
			width: 'fit-content',
			background: 'transparent',
			color: '#fff',
			border: '1px solid #fff',
		}}
`;

export const LinkButton = (props) => <LinkB as={Link} {...props} />;
