import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

import ClinicContext from './Context';

const intialContext = {
	clinicData: {},
};

const reducer = (state, { type, payload }) => {
	switch (type) {
		case 'CLINIC_SELECTED':
			return { ...state, clinicData: payload, isOpen: true };
		case 'CLINIC_CLOSED':
			return { ...state, isOpen: false };
		case 'JUST_OPEN':
			return { ...state, isOpen: true }
		default:
			return state;
	}
};

const ClinicContextProvider = ({ children }) => {
	const [data, dispatch] = useReducer(reducer, intialContext);

	const onClinicSelect = ({ data: selectedClinic = {}, params = {} }) => () => {
		const payload = { ...params, ...selectedClinic };

		window.history.pushState(
			payload,
			'Clinic',
			`/#/doctor/${selectedClinic.name}/${selectedClinic.place}${params.telemedicine ? '/telemedicina' : ''}`
		);
		dispatch({ type: 'CLINIC_SELECTED', payload });
	};

	const onClinicClose = () => dispatch({ type: 'CLINIC_CLOSED' });

	const justOpen = () => dispatch({ type: 'JUST_OPEN' });

	return <ClinicContext.Provider value={{ onClinicSelect, onClinicClose, justOpen, ...data }}>{children}</ClinicContext.Provider>;
};

ClinicContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default ClinicContextProvider;
