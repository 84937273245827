import axios from 'axios';
import { without, chain, toUpper, reduce, pipe } from 'ramda';

export const getData = async () => {
	try {
		const [places, { data: { specialities, telemedicineSpecialities }}] = await Promise.all([
			axios.get('/api/v2/search.getPlaces'),
			axios.get('/api/v2/search.getSpecialities'),
		]);

		const removeLabs = pipe(
			without('Laboratório'),
			chain(value => ({ value })),
		);

		const normalize = reduce((acc, elem) => [...acc, { value: toUpper(elem) }], []);

		return { 
			allPlaces: normalize(places.data), 
			allSpecialities: removeLabs(specialities),
			telemedicineSpecialities: removeLabs(telemedicineSpecialities),
		};
	} catch (e) {
		console.error(e);
		return {};
	}
};

export const getUserLocation = () =>
	new Promise((resolve, reject) => {
		navigator.geolocation.getCurrentPosition(({ coords }) => resolve({ lat: coords.latitude, lng: coords.longitude }), reject);
	});

