import styled from 'styled-components';

export const Container = styled.div`
	position: relative;
	margin-bottom: 5px;
	width: -webkit-fill-available;
	width: -moz-available;
	width: fill-available;

	input {
		width: -webkit-fill-available;
		width: -moz-available;
		width: fill-available;
	}
`;

export const List = styled.div`
	position: absolute;
	max-height: 30vh;
	width: -webkit-fill-available;
	width: -moz-available;
	width: fill-available;

	overflow-y: scroll;
	z-index: 10;
	border: 1px solid #dedede;
	border-top: 0;
	margin-top: 1px;

	background-color: #fff;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
`;

export const Item = styled.div`
	padding: 15px 10px;
	border-top: 1px solid #dedede;
	border-bottom: 1px solid #dedede;
	color: #000;

	&:first-of-type {
		border-top: 0;
	}

	&:last-of-type {
		border-bottom: 0;
	}
`;

export const Clean = styled.span`
	@-webkit-keyframes fade-out {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
	@keyframes fade-out {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}

	position: absolute;
	cursor: pointer;

	top: 50%;
	right: 10px;

	font-size: 1.2rem;
	font-weight: 700;
	color: ${({ theme }) => theme.fadedBlue};

	&[data-out=true] {
		cursor: inherit;
		-webkit-animation: fade-out 300ms ease-out both;
		animation: fade-out 300ms ease-out both;
	}
`;