import React from 'react';

import { ContractButton } from '../../Components/Buttons';
import { CardBenefits, RedCardBenefits, CardOption, CardBestOption } from '../../Components/Cards';
import {
	BenefitsTitle,
	BenefitsSubTitle,
	BenefitsTitleCard,
	BenefitsParagraph,
	List,
	ListItem,
	RegularTitle,
	CardInfo,
	Divider,
	Price,
	Pay,
	Plan,
	BestOption,
} from '../../Components/Typography';
import { CircleAvus, CircleDollar, CircleLike, CircleCards } from '../../Components/Icons';
import { CardContainer, OptionHeader, ListDepositons, Container } from './Components';

// eslint-disable-next-line no-empty-pattern
const Benefits = () => {
	const onItemClick = ({ target }) => target.setAttribute('data-open', !JSON.parse(target.getAttribute('data-open')));

	return (
		<Container>
			<BenefitsTitle>Avus Pro</BenefitsTitle>
			<BenefitsSubTitle>Um pacote de vantagens pra você ficar seguro e saudável!</BenefitsSubTitle>
			<CardBenefits data-open="false" onClick={onItemClick}>
				<CircleAvus />
				<BenefitsTitleCard>Até 4 dependentes</BenefitsTitleCard>
			</CardBenefits>
			<BenefitsParagraph>O titular do plano pode adicionar até 4 dependentes sem custo adicional.</BenefitsParagraph>
			<CardBenefits data-open="false" onClick={onItemClick}>
				<CircleDollar />
				<BenefitsTitleCard>Consultas mais baratas</BenefitsTitleCard>
			</CardBenefits>
			<BenefitsParagraph>Tenha acesso a consultas com valores a partir de R$ 30,00.</BenefitsParagraph>
			<CardBenefits data-open="false" onClick={onItemClick}>
				<CircleLike />
				<BenefitsTitleCard>Vantagens</BenefitsTitleCard>
			</CardBenefits>
			<BenefitsParagraph>Obtenha descontos exclusivos em:</BenefitsParagraph>
			<List>
				<ListItem>Academias</ListItem>
				<ListItem>Óticas</ListItem>
				<ListItem>Estéticas</ListItem>
				<ListItem>Postos de Gasolina</ListItem>
			</List>
			<RedCardBenefits data-open="false" onClick={onItemClick}>
				<CircleCards />
				<BenefitsTitleCard>Conta digital + cartão de crédito</BenefitsTitleCard>
			</RedCardBenefits>
			<BenefitsParagraph>
				O cartão AVUS é um cartão de crédito pré-pago de bandeiras VISA ou Mastercard, aceito em milhões de estabelecimentos no
				Brasil. <br />
				<br /> Com ele você pode:
			</BenefitsParagraph>
			<List>
				<ListItem>Comprar Online</ListItem>
				<ListItem>Recarregar créditos no celular</ListItem>
				<ListItem>Pagar contas</ListItem>
				<ListItem>Realizar saques em terminais 24h e lotéricas</ListItem>
				<ListItem>Fazer transferência entre contas</ListItem>
			</List>
			<RegularTitle>Depoimentos</RegularTitle>
			{ListDepositons}
			<CardContainer>
				<CardOption>
					<OptionHeader>
						<Pay>Teste</Pay>
						<Plan>Mensal</Plan>
						<span>R$</span>
					</OptionHeader>
					<Price>29,90</Price>
					<Divider />
					<CardInfo>todos os descontos</CardInfo>
					<CardInfo>Crédito pessoal</CardInfo>
					<CardInfo>até +4 dependentes</CardInfo>
					<ContractButton>contratar</ContractButton>
				</CardOption>
				<CardBestOption>
					<BestOption>Melhor opção</BestOption>
					<OptionHeader>
						<Pay>Teste</Pay>
						<Plan>Mensal</Plan>
						<span>R$</span>
					</OptionHeader>
					<Price>24,90</Price>
					<Divider />
					<CardInfo>todos os descontos</CardInfo>
					<CardInfo>Crédito pessoal</CardInfo>
					<CardInfo>até +4 dependentes</CardInfo>
					<ContractButton>contratar</ContractButton>
				</CardBestOption>
			</CardContainer>
		</Container>
	);
};

export default Benefits;
