import styled from 'styled-components';

const Close = styled.button`
	background: none;
	border: none;
	padding: 0 8px;
	color: white;
	font-size: 1.5rem;
`;

export default Close;
