export default appointment => ({
	id: appointment._id,
	code: appointment.code,
	speciality: appointment.speciality.specialityName,
	status: appointment.status,
	startAt: appointment.startAt,
	credentialed: appointment.credentialed,
	appointmentNotes: appointment.appointmentNotes,
	beneficiary: appointment.beneficiary,
});
