import React from 'react';
import PropTypes from 'prop-types';
import { ToastContainer, ToastMessage } from './Overlay';
import Close from './Close';

const Toast = ({ text, isOpen, duration, variant = 'success', onClose = () => {},onClick = () => {}, isStatic, dockBottom }) => {
    if (!isOpen) return null;
    if (!isStatic) setTimeout(onClose, duration || 1500);

	return (
		<ToastContainer variant={variant} onClick={onClick} isStatic={isStatic} dockBottom={dockBottom}>
			<ToastMessage>
				<h4>{text}</h4>
				<Close onClick={(e) => {
          e.stopPropagation();
          onClose(e);
        }}>&times;</Close>
			</ToastMessage>
		</ToastContainer>
	);
};

Toast.propTypes = {
    text: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    duration: PropTypes.number,
    onClose: PropTypes.func,
    onClick: PropTypes.func,
    dockBottom: PropTypes.bool,
};

export default Toast;
