import React from 'react';
import get from 'lodash/get';

import { Container } from 'Components/Containers';
import { RegularTitle } from 'Components/Typography';
import { Plan, PlanDescription } from 'Views/ContractPlans/Components';

import useUser from 'Hooks/User';

const MyPlan = () => {
	const { user } = useUser();

	const getPaymentDate = (d, s) => {
		const start = new Date(s);
		const date = new Date(start.getFullYear(), start.getMonth(), start.getDate() + d);

		const put0 = s => (String(s).length === 1 ? `0${s}` : s);
		const startDate = [date.getDate(), date.getMonth() + 1, date.getFullYear()].map(put0).join('/');

		return `Primeira cobrança em ${startDate}`;
	};

	return (
		<Container>
			<RegularTitle>Plano Contratado</RegularTitle>
			<Plan>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 12 }}>
					<div style={{ fontWeight: 600, marginRight: 16 }}>{get(user, 'planData.title', '')}</div>
					<span style={{ textAlign: 'right' }} dangerouslySetInnerHTML={{ __html: get(user, 'planData.value') }} />
				</div>
				<div>Situação do plano: {get(user, 'beneficiary.status')}</div>
				{get(user, 'planData.trial', null) && (
					<span style={{ marginTop: 12 }}>{getPaymentDate(get(user, 'planData.trial'), get(user, 'beneficiary.startDate'))}</span>
				)}
			</Plan>
		</Container>
	);
};

export default MyPlan;
