import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import useRecoverPass from 'Hooks/Password/Recover';
import map from 'lodash/map';

import { SenhaLabel as CPFLabel, EntrarButton, Buttons, SmallLink, SenhaInput as CPFInput } from '../Login/Form';
import { Header, Logo } from '../Login/Header';
import Card from '../Login/Card';
import ErrorList from '../Login/ErrorList';
import Container from '../Login/Container';

const RecoverPassword = () => {
	const { submited, errors, hasError, onSubmit, cpfField } = useRecoverPass();

	return (
		<Container>
			<Card>
				<div style={{ display: 'flex', flexFlow: 'wrap-reverse', justifyContent: 'space-between', marginBottom: '36px' }}>
					<Header style={{ fontSize: 'larger', color: '#585858' }}>Recuperar senha</Header>
					<Logo src="/img/logo-login.svg" />
					{hasError ? (
						<ErrorList>
							{map(errors, (error, i) => (
								<li key={`error-${i}`}>{error.message}</li>
							))}
						</ErrorList>
					) : null}
				</div>

				{submited && !hasError ? (
					<>
						<p style={{ gridArea: 'cpf-label' }}>Siga as instruções enviadas por email para recuperar sua conta.</p>
						<p style={{ gridArea: 'cpf-label', marginTop: '15px' }}>
							Caso não esteja recebendo o email, entre em contato conosco pelo&nbsp;
							<a href="tel:08005000404" rel="noopener noreferrer">
								0800.500.0404
							</a>
							{' '}das 8h às 18h.
						</p>
					</>
				) : (
					<>
						<p style={{ gridArea: 'cpf-label' }}>
							Um email com instruções para recuperação da senha será enviado ao email cadastrado na sua conta.
						</p>

						<CPFLabel htmlFor="cpf">CPF</CPFLabel>
						<CPFInput id="cpf" type="text" placeholder="Seu CPF" {...cpfField} mask="999.999.999-99" />

						<Buttons>
							<EntrarButton onClick={onSubmit} style={{ width: '100%', marginTop: '18px' }}>
								Enviar
							</EntrarButton>
							<SmallLink data-back as={Link} to="/">
								voltar
							</SmallLink>
						</Buttons>
					</>
				)}
			</Card>
		</Container>
	);
};

RecoverPassword.propTypes = {
	cpf: PropTypes.string,
	onCpfChange: PropTypes.func,
	onSubmit: PropTypes.func,
	hasError: PropTypes.bool,
	errors: PropTypes.arrayOf(PropTypes.shape({ message: PropTypes.string })),
	submited: PropTypes.bool,
};

export default RecoverPassword;
