import axios from 'axios';
import apiFilters from 'Utils/api-filters';
import get from 'lodash/get';

const changeGroupAndRole = async ({ cpf }) => {
	const { data: response } = await axios.get(
		'/api/data/User/find',
		apiFilters({ term: 'username', operator: 'equals', value: cpf })
	);
	if (!response.success) throw response.errors;
	const {
		data: [data],
	} = response;

	const { data: response2 } = await axios.put('/api/data/User', {
		ids: [{ _id: data._id, _updatedAt: { $date: get(data, '_updatedAt.$date', data._updatedAt) } }],
		data: {
			group: { _id: 'QG4WykaDgYQp4QdZv' },
			role: { _id: 'tDHfzTRQDkpuDWLkp' },
		},
	});
	if (!response2.success) throw response2.errors;
};

export default changeGroupAndRole;
