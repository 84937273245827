import styled from 'styled-components';

const Burger = styled.div`
	grid-area: burger;
	background: url('/img/bars-solid.svg');
	background-size: 50%;
	background-position: center;
	background-repeat: no-repeat;
	display: block;
	height: 32px;
	width: 32px;
	align-self: center;
	justify-self: right;
	margin-right: 16px;

	&:hover {
		opacity: 0.5;
	}
`;

export default Burger;
