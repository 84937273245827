import { useState } from 'react';
import useField from 'Hooks/General/useField';

import size from 'lodash/size';
import axios from 'axios';
import apiFilters from 'Utils/api-filters';

const useGeneratePass = () => {
	const [errors, setErrors] = useState([]);
	const newPasswordField = useField();
	const repeatPasswordField = useField();

	const onSubmit = async ({ params }) => {
		const localErrors = [];
		if (newPasswordField.value !== repeatPasswordField.value) {
			localErrors.push({
				field: 'password',
				message: 'Senhas não correspondem',
			});
		}
		if (newPasswordField.value.length < 6) {
			localErrors.push({ message: 'As senhas devem ter no mínimo 6 caracteres.' });
		}
		if (size(localErrors) > 0) return setErrors(localErrors);

		try {
			const { data: response } = await axios.get(
				'/api/data/Contact/find',
				{
					...apiFilters({
						term: 'cpf',
						operator: 'equals',
						value: String(params.cpf),
					}),
					headers: { 'agent-ip': Date.now() }
				}
			);
			if (!response.success) throw response.errors;
			const {
				data: [data],
			} = response;

			const { data: passResponse } = await axios.put('/api/data/Contact', {
				ids: [{ _id: data._id, _updatedAt: { $date: data._updatedAt } }],
				data: {
					password: newPasswordField.value,
				},
			}, { headers: { 'agent-ip': Date.now() } });
			if (!passResponse.success) throw passResponse.errors;

			window.location.hash = '/';
		} catch (e) {
			console.error(e);
			setErrors([{ message: 'Ocorreu um erro. Tente novamente mais tarde.' }]);
		}
	};

	return { onSubmit, newPasswordField, repeatPasswordField, errors, hasError: errors.length > 0 };
};

export default useGeneratePass;
