/* eslint-disable react/no-danger */
import React from 'react';

const Telemedicina = ({ match }) => {
	const scripts = {
		telemedicina: 'https://landbot.io/u/H-427715-SAHDTYXMVOGANZTT/index.html',
		'covid-19': 'https://landbot.io/u/H-430745-N3XFQT9PU5T2XPEQ/index.html',
	};

	const adjustPosition = land => {
		land.container.id = 'landbot-frame';
		land.container.style.top = '54px';
		land.container.style.zIndex = '30';
		land.container.firstElementChild.style.top = '54px';
		land.container.firstElementChild.style.zIndex = '30';
	};

	React.useEffect(
		() => {
			// If landbot already exists, only change its instance
			if (window.landbot) {
				window.landbot = new window.LandbotFullpage({
					index: scripts[match.params[0]],
				});

				adjustPosition(window.landbot);
				return;
			}

			// Otherwise, inject the landbot script
			const script = document.createElement('script');
			script.id = 'landbot-script';
			script.async = true;
			script.src = 'https://static.landbot.io/landbot-widget/landbot-widget-1.0.0.js';
			script.onload = () => {
				window.landbot = new window.LandbotFullpage({
					index: scripts[match.params[0]],
				});

				adjustPosition(window.landbot);
			};

			document.body.appendChild(script);
		},
		[match.params]
	);

	return <p>Loading</p>;
};

export default Telemedicina;
