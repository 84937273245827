import React from 'react';
import styled from 'styled-components';

export const LabelExam = styled.label`
	grid-area: label;
	font-size: 15px;
	margin-bottom: 4px;
`;

export const LabelLocal = styled.label`
	grid-area: label_default;
	font-size: 15px;
	margin-bottom: 4px;
`;

export const SelectExam = styled.select`
	background-image: url(/img/angle-down-solid.svg);
	background-repeat: no-repeat;
	margin-bottom: 18px;
	background-position-y: 15px;
	background-position-x: 97%;
	background-size: 12px;
	height: 37px;
	border: 1px solid #979797;
	padding-left: 16px;
	font-size: 18px;
	color: #4a4a4a;
	border-radius: 0.2rem;

	&[data-loading='true'] {
		background-image: url(/img/loading.svg);
		background-position: 50% 0;
		background-size: 2rem;
		pointer-events: none;
		opacity: 0.7;
	}

	appearance: none;
	-webkit-appearance: none;
	&::-ms-expand {
		display: none;
	}
`;

export const EntrarButton = styled.button`
	width: fit-content;
	height: 2em;
	font-size: 18px;
	border-radius: 20px;
	color: #ffffff;
	background: ${({ theme }) => theme.violetRed};
	border: none;
	font-family: Lato, sans-serif;
	margin-top: 16px;
`;

export const CadastroButton = styled(EntrarButton)`
	whitespace: 'nowrap';
	background: ${({ theme }) => theme.lighter};
	border: 1px solid ${({ theme }) => theme.violetRed};
	color: ${({ theme }) => theme.mulberry};
`;

export const SelectPlace = styled(SelectExam)`
	background-image: url(/img/map-marker-alt-solid.svg);
	background-repeat: no-repeat;
	background-position-x: 97%;
	background-position-y: 10px;
	background-size: 12px;
	grid-area: select-place;
	height: 37px;
	border: 1px solid #979797;
	padding-left: 16px;
	font-size: 18px;
	color: #4a4a4a;
	margin-bottom: 15px;
	border-radius: 0.2rem;
`;

export const InputWrapper = styled.label`
	width: 100%;
	max-width: 400px;
	padding: 0.3rem 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	font-weight: 700;
`;

export const TextArea = styled.textarea`
	width: -webkit-fill-available;
	width: -moz-available;
	width: fill-available;
	border: 1px solid #c5c5c5;
	border-radius: 3px;
	margin: 0.3rem 0;
	padding: 0.3rem;
	resize: vertical;
	font-family: Lato, sans-serif;
`;

const CheckStyle = styled.input`
	display: none;

	& + span {
		position: absolute;
		width: 15px;
		height: 15px;
		right: 0;
		border: 1px solid #c5c5c5;
		border-radius: 4px;
	}
	&[data-direction='left'] {
		& + span {
			left: 0;
		}
		& + span + span {
			margin-left: 25px
		}
	}
	&[data-direction='right'] {
		& + span {
			right: 0;
		}
	}

	&:checked + span {
		background-color: #ffffff;

		&::after {
			content: '✓';
			position: absolute;
			right: 0;

			font-size: 20px;
			color: #fff;
			width: 104%;
			height: 100%;
			margin-top: -3px;
			color: ${({ theme }) => theme.mulberry};
		}
	}

	&:disabled + span {
		background-color: #eee;
	}
`;

export const Checkbox = (props) => (
	<>
		<CheckStyle type="checkbox" {...props} />
		<span />
	</>
);
