import styled from 'styled-components';

export const IconDate = styled.div`
	grid-area: burger;
	background: url('/img/calendar-solid.svg');
	background-size: contain;
	background-repeat: no-repeat;
	display: block;
	height: 18px;
	width: 21px;
	align-self: center;
	justify-self: left;
`;

export const IconClock = styled.div`
	height: 18px;
	width: 21px;
	grid-area: burger;
	background: url('/img/clock-solid.svg') no-repeat;
	background-repeat: no-repeat;
	display: block;
	align-self: center;
	justify-self: left;
`;

const Circle = styled.div`
	width: 54px;
	height: 54px;
	border-radius: 50%;
`;

export const CircleAvus = styled(Circle)`
	background: white url(/img/logo-mono.svg) no-repeat center;
	background-size: auto 20px;
	pointer-events: none;
`;

export const CircleDollar = styled(Circle)`
	background: white url(/img/dollar.svg) no-repeat center;
	background-size: auto 35px;
`;

export const CircleLike = styled(Circle)`
	background: white url(/img/like.svg) no-repeat center;
`;

export const CircleCards = styled(Circle)`
	background: white url(/img/cards.svg) no-repeat center;
`;

export const DoctorAvatar = styled.img`
	width: calc(100% - 2px);
	object-fit: scale-down;
	max-height: 200px;
	max-width: 500px;
	border: 1px solid rgba(240, 240, 240, 1);
`;
