import { useState } from 'react';

const useField = initialValue => {
	const [value, setValue] = useState(initialValue);

	const onChange = ({ target: { value: newValue } }) => {
		setValue(newValue);
	};

	return {
		value,
		setValue,
		onChange,
	};
};

export const useCheckbox = initialValue => {
	const [checked, setValue] = useState(initialValue);

	const onChange = ({ target: { checked: newValue } }) => {
		setValue(newValue);
	};

	return {
		checked,
		setValue,
		onChange,
	};
};

export default useField;
