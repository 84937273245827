import map from 'lodash/map';
import axios from 'axios';

const updateAppointment = async ({ existingAppointment, returnField, observationField, selectedDays, selectedHours }) => {
	const { _updatedAt, _id } = existingAppointment;
	const payloadUpdate = {
		ids: [{ _id, _updatedAt: { $date: _updatedAt } }],
		data: {
			status: 'Aberta',
			cancellationReason: 'Remarcado via app pelo usuário',
			returnAppointment: returnField.checked ? 'Sim' : 'Não',
			appointmentNotes: `
				Preço: <b>R$: 00</b>
				<br/>
				<br/>
				<h2>Dias solicitados</h2>
				<ul>
				${map(selectedDays, day => {
					const date = new Date(day);
					return `<li>${date.getDate()}/${date.getMonth() + 1}</li>`;
				}).join('')}
				</ul>
				<br />
				<br />
				<h2>Horas solicitadas</h2>
				<ul>${map(selectedHours, hour => `<li>${hour}</li>`).join('')}</ul>
				<br />
				<br />
				${observationField.value ? `Observação do cliente: ${observationField.value}` : ''}
				`,
		},
	};

	const res = await axios.put('/api/data/Appointment', payloadUpdate);
	return res;
};

export default updateAppointment;
