import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { Link } from 'react-router-dom';

import useClinicData from 'Hooks/Clinic/ClinicData';

import { Verified, TitleDetail, DetailInfo, RegularTitle, Avatar, Deposition, Name } from '../../Components/Typography';
import { DoctorInfo, DepositionsCard } from '../../Components/Cards';
import { DoctorAvatar } from '../../Components/Icons';
import { LinkButton } from '../../Components/Buttons';

import { OverlayContainer, AvatarContainer, InfoContainer, ContainerTech, ContainerScholar } from '../../Components/Containers';
import GoogleMap from '../../Components/GoogleMap';

import Voltar from './Voltar';

const LabDetail = ({ history }) => {
	const { onBackClick, isOpen, isNavigated, isLab, clinicData = {} } = useClinicData(history);
	window.scrollTo(0, 0);

	if (!clinicData.name || !isOpen) return null;

	const telemedicine = window.location.hash.endsWith('/telemedicina');
	return (
		<OverlayContainer data-closed={!isOpen} style={{ marginTop: isNavigated ? '0' : '4rem' }}>
			{isNavigated ? <Voltar onClick={onBackClick} text="Voltar para lista" /> : null}
			<DoctorInfo>
				<AvatarContainer>
					<DoctorAvatar src={clinicData.avatarUrl} alt="" />
				</AvatarContainer>
				<InfoContainer>
					<Verified style={{ alignSelf: 'flex-end' }}>verificado</Verified>
				</InfoContainer>
			</DoctorInfo>
			<TitleDetail>{clinicData.name}</TitleDetail>
			<ContainerScholar>{clinicData.zone}</ContainerScholar>
			<ContainerTech>
				<DetailInfo>{clinicData.crm}</DetailInfo>
				<DetailInfo style={{ fontSize: '16px' }}>{clinicData.description}</DetailInfo>
			</ContainerTech>
			{clinicData.depositionsCard && clinicData.depositionsCard.length ? (
				<>
					<RegularTitle>Depoimentos</RegularTitle>
					<ContainerScholar>
						{map(clinicData.depositionsCard, entry => (
							<DepositionsCard key={entry.name}>
								<Avatar src={entry.img} alt={entry.name} />
								<Deposition>{entry.deposition}</Deposition>
								<Name>- {entry.name}</Name>
							</DepositionsCard>
						))}
					</ContainerScholar>
				</>
			) : null}

			{!telemedicine && (
				<>
					<RegularTitle>Localização</RegularTitle>
					<DetailInfo style={{ fontSize: '16px' }}>{(clinicData.detailedAddress || '').replace(/undefined/, '')}</DetailInfo>
					{clinicData.zone && <DetailInfo style={{ fontSize: '16px' }}>{clinicData.zone}</DetailInfo>}
					{clinicData.coords && (
						<DetailInfo>
							<GoogleMap position={clinicData.coords} />
						</DetailInfo>
					)}
				</>
			)}

			{(clinicData.schedules && clinicData.schedules.length) || (clinicData.isLab && (clinicData.email || clinicData.phones)) ? (
				<>
					<RegularTitle>Contatos e Horários</RegularTitle>
					<DetailInfo>{clinicData.schedules}</DetailInfo>
					<ContainerScholar>
						{clinicData.isLab && clinicData.email ? (
							<DetailInfo>
								<strong style={{ fontWeight: 600 }}>Email:</strong> {clinicData.email}
							</DetailInfo>
						) : null}

						{clinicData.isLab && clinicData.phones && clinicData.phones.length ? (
							<DetailInfo>
								<strong style={{ fontWeight: 600 }}>Telefones:</strong> {clinicData.phones.join(' - ')}
							</DetailInfo>
						) : null}

						{clinicData.isLab && clinicData.site ? (
							<DetailInfo>
								<strong style={{ fontWeight: 700 }}>Site:</strong> {clinicData.site}
							</DetailInfo>
						) : null}
					</ContainerScholar>
				</>
			) : null}

			<LinkButton
				as={isLab ? 'a' : Link}
				href={isLab ? `tel:${clinicData.phones[0] || '0800.500.0404'}` : undefined}
				to={{
					pathname: `/schedule/${clinicData.name}/${
						clinicData.speciality ||
						(clinicData.specialities && clinicData.specialities.includes('Laboratório')
							? 'Laboratório'
							: (clinicData.specialities && clinicData.specialities[0]) || '')
					}/${clinicData.code}${telemedicine ? '/telemedicina' : ''}`,
					state: { price: clinicData.price },
				}}
				style={{ width: '100%' }}
			>
				{isLab ? `Chamar ${clinicData.phones[0] || '0800.500.0404'}` : 'Solicitar agendamento'}
			</LinkButton>
		</OverlayContainer>
	);
};

LabDetail.propTypes = {
	history: PropTypes.object,
};

export default LabDetail;
