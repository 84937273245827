import React from 'react';
import useUser from 'Hooks/User';

import map from 'lodash/map';
import get from 'lodash/get';
import { uniqBy, path } from 'ramda';

import cdnImage from 'Utils/cdnImage';
import { Container } from '../../Components/Containers';
import { RegularTitle, Divider } from '../../Components/Typography';

const VirtualCard = () => {
	const { user } = useUser();

	if (!get(user, 'beneficiary')) {
		return <Container>Ocorreu um erro!</Container>;
	}

	const dependents =
		user.planData.dependents && user.planData.dependents.length
			? uniqBy(path(['beneficiary', 'code']))(user.planData.dependents)
			: null;

	return (
		<Container>
			<RegularTitle style={{ marginBottom: '.5rem' }}>Titular</RegularTitle>
			<img
				src={
					cdnImage(user.beneficiary.virtualCard) ||
					`https://avus-integrations.konecty.com/virtualcard/${user.beneficiary._id}`
				}
				alt=""
				width="100%"
				style={{ margin: 'auto' }}
			/>

			<Divider />
			{dependents && (
				<>
					<RegularTitle style={{ marginBottom: '.5rem' }}>Dependentes</RegularTitle>
					{map(dependents, ({ _id: id, virtualCard }) => (
						<img
							key={id}
							src={cdnImage(virtualCard) || `https://avus-integrations.konecty.com/virtualcard/${id}`}
							alt=""
							width="100%"
							style={{ margin: '0 auto 1rem' }}
						/>
					))}
				</>
			)}
		</Container>
	);
};

export default VirtualCard;
