import get from 'lodash/get';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import useField from 'Hooks/General/useField';
import useModals from 'Hooks/Modals';
import useSearch from 'Hooks/Search';
import useUser from 'Hooks/User';

import AutoComplete from 'Components/AutoComplete';
import { SearchButton } from '../../Components/Buttons';
import { AbosoluteBottom, Container, SelectContainer, TabContainer } from '../../Components/Containers';
import { InputWrapper } from '../../Components/Forms';
import { Tab, Title } from '../../Components/Typography';

const Search = () => {
	const { specialities, places, specialityField, placeField, onTabClick, search, isTabActive, farmas, tab, user } = useSearch();
	const {
		fns: { setPatient },
	} = useUser();

	const { checkModal } = useModals();
	const { value: client, setValue: setClientChange } = useField(get(user, 'beneficiary._id'));
	const [isUserDisabled, setUserDisabled] = useState(false);

	const location = useLocation();
	useEffect(() => {
		const defaultTab = new URLSearchParams(location.search).get('tab');
		if (defaultTab) {
			onTabClick(defaultTab)();
		}
	}, [location.search]);

	useEffect(() => {
		if (user && user.beneficiary && user.userLoaded) {
			checkUserDisabled().then(setUserDisabled);
		}
	}, [user]);

	const checkUserDisabled = async () => {
		const openPlans = await checkModal('plans', { user });
		if (openPlans) return true;

		const openRegister = await checkModal('register', { user });
		if (openRegister) return true;

		const openTerms = await checkModal('terms', { user });
		if (openTerms) return true;

		const openRedirect = await checkModal('redirect', { user });
		if (openRedirect) return true;

		const openAppMigration = await checkModal('appMigration', { user });
		if (openAppMigration) return true;

		return user.beneficiary.status !== 'Ativo';
	};

	useEffect(() => {
		specialityField.setValue('');
	}, [tab]);

	useEffect(() => {
		if (!user || !user.beneficiary) return;

		const selectedId = client != null ? client : user.beneficiary._id;
		const patient = [user.beneficiary].concat(user.dependents || []).find(usr => usr._id === selectedId);
		if (user.patient?._id === patient?._id) return;

		setPatient(patient);
	}, [client, user]);

	const searchButtonDisabled = useMemo(() => {
		if (isTabActive('consultas')) return !placeField.value || !specialityField.value || !client;
		if (isTabActive('exames')) return !placeField.value;

		return !specialityField.value;
	}, [placeField.value, specialityField.value, client]);

	const patients = useMemo(
		() =>
			user.userLoaded
				? [{ label: user.name, value: get(user, 'beneficiary._id') }].concat(
					(user.dependents || [])
						.filter(e => e.status === 'Ativo')
						.map(dep => ({ label: get(dep, 'beneficiary.name.full', ''), value: dep._id }))
				)
				: [],
		[user]
	);

	return (
		<Container primaryBg>
			<Title style={{ marginBottom: '.5rem' }}> Marque sua consulta Avus</Title>

			<TabContainer>
				<Tab onClick={onTabClick('consultas')} data-active={isTabActive('consultas')}>
					Consultas
				</Tab>
				<Tab onClick={onTabClick('exames')} data-active={isTabActive('exames')}>
					Exames
				</Tab>
				<Tab onClick={onTabClick('telemedicina')} data-active={isTabActive('telemedicina')}>
					Telemedicina
				</Tab>
				<Tab onClick={onTabClick('farmacias')} data-active={isTabActive('farmacias')}>
					Farmácias
				</Tab>
			</TabContainer>
			<SelectContainer>
				{isTabActive('consultas') && specialities.allSpecialities.length > 0 && (
					<AutoComplete
						items={specialities.allSpecialities}
						onSelect={specialityField.setValue}
						inputProps={{ placeholder: 'Pesquise por especialidade' }}
						label="Especialidade"
						openOnFocus
					/>
				)}

				{isTabActive('telemedicina') && (
					<>
						<AutoComplete
							items={specialities.telemedicineSpecialities}
							onSelect={specialityField.setValue}
							inputProps={{ placeholder: 'Pesquise por especialidade' }}
							label="Especialidade"
							openOnFocus
						/>
						<span style={{ fontSize: '10pt', margin: '-10px 0 15px' }}>Consultas com especialistas em hora marcada</span>
					</>
				)}

				{isTabActive(['consultas', 'telemedicina']) && patients.length > 0 && (
					<AutoComplete
						items={patients}
						onSelect={setClientChange}
						inputProps={{ placeholder: 'Selecione' }}
						label="Para o paciente"
						openOnFocus
					/>
				)}

				{isTabActive(['consultas', 'exames']) && (
					<AutoComplete
						items={places}
						onSelect={placeField.setValue}
						inputProps={{ placeholder: 'Pesquise por localidade' }}
						defaultValue="Minha Localização"
						label="Localização ou Cidade"
					/>
				)}

				{isTabActive(['consultas', 'exames', 'telemedicina']) && (
					<SearchButton onClick={search} type="submit" disabled={searchButtonDisabled}>
						Pesquisar
					</SearchButton>
				)}

				{isTabActive('farmacias') && (
					<div style={{ background: 'white', borderRadius: '.5rem', padding: '0 1rem', boxSizing: 'border-box' }}>
						{Array.isArray(farmas)
							? farmas.map(({ img, site, w = 150, h = 50 }) => (
								<span role="button" onClick={() => window.open(site)} style={{ width: '100%' }} key={site}>
									<InputWrapper style={{ margin: '1rem 0', maxWidth: 'none' }}>
										<img src={img} alt={site} width={`${w}px`} height={`${h}px`} />
										<Tab style={{ lineHeight: 2 }}>
											<img src="/img/right.svg" width="10px" style={{ filter: 'brightness(0.7)' }} alt="" />
										</Tab>
									</InputWrapper>
								</span>
							))
							: 'Nenhuma farmácia encontrada'}
					</div>
				)}
			</SelectContainer>

			{isTabActive('telemedicina') && (
				<AbosoluteBottom>
					<hr style={{ background: '#fff', border: 0, width: '100%', height: 2 }} />
					<p>Atendimento de urgência com um Clínico Geral</p>

					<a href="/#/duty" disabled={isUserDisabled}>
						<SearchButton aria-disabled={isUserDisabled}>Plantão 24hs</SearchButton>
					</a>
				</AbosoluteBottom>
			)}
		</Container>
	);
};

export default Search;
