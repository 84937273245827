import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import reduce from 'lodash/reduce';

import useUser from 'Hooks/User';
import { sortByPrice } from 'Utils/sort';
import { doSearch, buildFilterData } from './effect';

const useResult = () => {
	const [data, setData] = useState([]);
	const [displayData, setDisplayData] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [warning, setWarning] = useState(null);
	const location = useLocation();
	const { user } = useUser();

	const search = new URLSearchParams(location.search);
	const params = reduce([...search.entries()], (acc, [k, v]) => ({ ...acc, [k]: decodeURIComponent(v) }), {});
	params.telemedicine = params.telemedicina === 'sim';

	const getTitle = () => {
		try {
			let { place = 'Minha Localização' } = params;
			const isCoords = /^@[-]\d+\.?\d*,[-]\d+\.?\d*$/.test(place);

			if (isCoords) place = 'Minha Localização';

			return { speciality: params.speciality, place };
		} catch (e) {
			console.error(e);
			return { speciality: '', place: '' };
		}
	};

	useEffect(() => {
		setLoading(true);
		if (!user || (!user.beneficiary && !user.hasNoBeneficiary) || (isLoading && !data)) return;

		doSearch(Object.assign(params, { user }))
			.then(({ result, error, warning }) => {
				if (error) {
					setError(error);
					setData([]);
					setDisplayData([]);
					setLoading(false);
					return;
				}

				const sorted = sortByPrice(result);
				if (warning) {
					setWarning(warning)
				}
				setData(sorted);
				setDisplayData(sorted);
				setLoading(false);
			})
			.catch(error => {
				console.error(error);
				setLoading(false);
				setData([]);
				setDisplayData([]);
			});
	}, [window.location, user]);

	return { data, displayData, setDisplayData, title: getTitle(), isLoading, filterData: buildFilterData(data), params, error, warning };
};

export default useResult;
