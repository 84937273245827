import { compose, sortBy, replace, prop, sort } from 'ramda';
import lodashSort from 'lodash/sortBy';

export const sortByDistance = sortBy(
	compose(
		Number,
		replace(' km', ''),
		prop('distance')
	)
);

export const sortByVerified = sort((e, x) => x.verified - e.verified);
export const sortByPrice = e => lodashSort(e, ['price']);
