import React from 'react';
import get from 'lodash/get';

import Popup, { ClosePopup, PopupHeader, PopupActions, PopupContent } from 'Components/Popup';
import { EntrarButton, CadastroButton } from '../../Views/Login/Form';

const RegisterPopup = ({ close }) => (
	<Popup>
		<PopupHeader>
			<h4>Informações de Cadastro</h4>
			<ClosePopup onClick={close} />
		</PopupHeader>
		<PopupContent>
			<div style={{ padding: '1rem', textAlign: 'center' }}>Confira suas informações de cadastro e complete onde necessário!</div>
		</PopupContent>
		<PopupActions>
			<CadastroButton onClick={close}>Agora não</CadastroButton>
			<EntrarButton
				type="submit"
				onClick={() => {
					location.hash = '#/register';
					close();
				}}
			>
				Ir para cadastro
			</EntrarButton>
		</PopupActions>
	</Popup>
);

const checkRegister = ({ user }) => {
	const tags = get(user, 'beneficiary.tags', []);
	const isCompleted = tags.some((tag) => tag.key === 'Cadastro Completo' && tag.value.length);

	return !isCompleted && !user.term.isModalOpen;
};

export { checkRegister };
export default RegisterPopup;
