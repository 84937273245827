import RandomPass from 'Components/Email/RandomPass';
import axios from 'axios';

const sendPasswordRequestByEmail = async ({ id, cpf, to }) => {
	const emailHtml = RandomPass(cpf);

	const message = {
		type: 'Email',
		archived: null,
		from: 'Não responda <nao-responda@avus.com.br>',
		body: emailHtml,
		to,
		discard: false,
		medium: null,
		priority: 'Alta',
		read: null,
		referrerURL: '',
		sendAt: { $date: new Date().toISOString() },
		status: 'Send',
		subject: 'Avus - Definição de senha',
		_user: null,
		contact: [{ _id: id }],
	};

	const { data: response } = await axios.post('/api/data/Message', message, { headers: { 'agent-ip': Date.now() } });
	return response.success;
};

export default sendPasswordRequestByEmail;
