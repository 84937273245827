import styled from 'styled-components';

const Card = styled.div`
	display: flex;
	flex-flow: column;
	width: 100%;
	grid-gap: 16px;
	padding: 16px;
	border-radius: 4px;
	margin-top: 2rem;
	box-sizing: border-box;
`;

export default Card;
