import React, { useState } from 'react';
import PropTypes from 'prop-types';

import LoadingContext from './context';

const LoadingContextProvider = ({ children }) => {
	const [isLoading, setLoading] = useState(false);
	const stack = {};

	const toggleLoading = () => setLoading(v => !v);

	const loading = name => {
		stack[name || 'entry'] = 'loading';
		setLoading(true);
	};
	const loaded = name => {
		stack[name || 'entry'] = 'loaded';
		if (!Object.values(stack).some(e => e === 'loading')) {
			setLoading(false);
		}
	};

	return <LoadingContext.Provider value={{ isLoading, toggleLoading, loading, loaded }}>{children}</LoadingContext.Provider>;
};

LoadingContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default LoadingContextProvider;
