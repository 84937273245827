import styled from 'styled-components';
import MaskInput from 'react-input-mask';

export const CPFLabel = styled.label`
	font-size: 15px;
	font-weight: 700;
`;

export const CPFInput = styled(MaskInput)`
	position: relative;
	font-family: Lato, sans-serif;
	margin-top: 8px;
	appearance: none;
	box-shadow: none;
`;

export const SenhaLabel = styled.label`
	font-size: 15px;
	font-weight: 700;
	margin-top: 12px;
`;

export const EmailLabel = styled.label`
	font-size: 15px;
	font-weight: 500;
	margin-top: 17px;
`;

export const SenhaInput = styled.input`
	font-family: Lato, sans-serif;
	margin-top: 8px;
	width: 100%;
	appearance: none;
	box-shadow: none;
`;
export const EmailInput = styled(SenhaInput)('');

export const TooglePassword = styled.i`
	position: absolute;
	width: 20px;
	height: 20px;
	align-self: center;
	opacity: 0.6;
	cursor: pointer;
	right: 1rem;
	bottom: 8px;
`;

export const Buttons = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	grid-area: entrar;
	width: 100%;
	margin-top: 8px;
	border-radius: 20px;
`;

export const EntrarButton = styled.button`
	width: calc(50% - 8px);
	height: 2em;
	font-size: 18px;
	border-radius: 20px;
	color: #ffffff;
	background: ${({ theme }) => theme.violetRed};
	border: none;
	font-family: Lato, sans-serif;
	.invert-colors & {
		color: ${({ theme }) => theme.violetRed};
		background: white;
	}
`;

export const CadastroButton = styled(EntrarButton)`
	whitespace: nowrap;
	background: ${({ theme }) => theme.lighter};
	border: 1px solid ${({ theme }) => theme.violetRed};
	color: ${({ theme }) => theme.mulberry};

	.invert-colors & {
		background: transparent;
		border-color: white;
		color: white;
	}
`;

export const VoltarButton = styled(EntrarButton)`
	background: transparent;
	border: none;
	color: ${({ theme }) => theme.primary};
	.invert-colors & {
		background: transparent;
		color: white;
	}
`;

export const SmallLink = styled(VoltarButton)`
	width: auto;
	text-decoration: underline;
	font-size: medium;
	flex-basis: 100%;
	text-align: left;
	padding: 0;
	margin-top: 8px;

	.invert-colors & {
		background: transparent;
		color: white;
	}

	&[data-back] {
		&:before {
			content: '< ';
		}
	}
`;
