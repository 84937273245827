import styled from 'styled-components';

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 10px;
  &> b {
    font-weight: 600;
  }

  &> span {
    text-align: right;
    line-height: 1.5;
  }
`;

export const RelativeContainer = styled.div`
  position: relative;
  width: 100%;

  input {
    width: calc(100% - 16px); // Wrapper container padding
  }

  button {
    position: absolute;
    right: 0;
    top: 5px;
    margin: 2px;
    border-radius: 0;

    &[data-success=true] {
      color: ${({ theme }) => theme.success || "green"};
      border: ${({ theme }) => `2px solid ${theme.success || "green"}`};
      background: none;
    }
  }
`;