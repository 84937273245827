import axios from 'axios';
import formatAppointment from 'Utils/formatAppointment';
import head from 'lodash/head';
import apiFilters from 'Utils/api-filters';

// eslint-disable-next-line import/prefer-default-export
export const doCancel = async ({ id, reason }) => {
	try {
		const { data } = await axios.get('/api/data/Appointment/find', apiFilters({ term: '_id', operator: 'equals', value: id }));
		if (!data.data || !data.data.length) {
			throw new Error('Consulta nao encontrada');
		}
		const { _id, _updatedAt } = data.data[0];

		const payload = {
			ids: [{ _id, _updatedAt: { $date: _updatedAt } }],
			data: {
				status: 'Cancelada',
        cancellationReason: 'Cancelado via app pelo usuário',
        cancelReason: reason
			},
		};

		const { data: response } = await axios.put('/api/data/Appointment', payload);

		if (response.success) {
			window.location.hash = '/';
		}

		if (!response.success) throw response.errors;

		const appointment = head(response.data);
		return formatAppointment(appointment);
	} catch (e) {
		console.error(e);
		return [];
	}
};
