import styled from 'styled-components';

const NewPassTitle = styled.h1`
	grid-area: header;
	font-family: Lato;
	font-weight: bold;
	height: 100%;
	display: flex;
	align-items: flex-end;
	font-size: 24px;
	color: ${({ theme }) => theme.dark};
`;

export default NewPassTitle;
