import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';

import useGeneratePass from 'Hooks/Password/Generate';

import { NewSenhaLabel, NewSenhaInput, SenhaLabel, SenhaInput, EntrarButton } from './Form';
import NewPassTitle from './NewPassTitle';
import ErrorList from './ErrorList';
import Card from './Card';
import Container from './Container';
import TopBar from '../../App/TopBar';

const NewPassword = ({ match }) => {
	const { onSubmit, newPasswordField, repeatPasswordField, errors, hasError } = useGeneratePass(match);

	return (
		<>
			<TopBar />
			<Container>
				<Card>
					<NewPassTitle>Cadastre uma nova senha</NewPassTitle>
					{hasError ? (
						<ErrorList>
							{map(errors, (error, i) => (
								<li key={`error-${i}`}>{error.message}</li>
							))}
						</ErrorList>
					) : null}
					<NewSenhaLabel htmlFor="senha">
						Nova Senha <small style={{ fontSize: 'small', color: '#828282' }}>Min. 6 caracteres</small>
					</NewSenhaLabel>
					<NewSenhaInput id="new-pass" type="password" {...newPasswordField} />

					<SenhaLabel htmlFor="senha">Repetir Senha</SenhaLabel>
					<SenhaInput id="pass" type="password" {...repeatPasswordField} />
					<EntrarButton type="button" onClick={() => onSubmit(match)}>
						Enviar
					</EntrarButton>
				</Card>
			</Container>
		</>
	);
};

NewPassword.propTypes = {
	match: PropTypes.object,
};

export default NewPassword;
