import { useContext } from 'react';

import UserContext from './context';

const useUser = () => {
	const context = useContext(UserContext);
	return context;
};

export default useUser;
