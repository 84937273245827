import { useContext } from 'react';

import LoadingContext from './context';

const useLoading = () => {
	const context = useContext(LoadingContext);
	return context;
};

export default useLoading;
