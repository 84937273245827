import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: auto;
	width: 100%;
	margin-top: 2.5rem;
`;

export default Container;
