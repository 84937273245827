import styled from 'styled-components';
import { InputWrapper } from '../../Components/Forms';

const Return = styled(InputWrapper)`
	width: initial;
	padding: 1rem 0;
	flex-direction: row;
	margin: 1.5rem 0;

	> span {
		max-width: 80vw;
	}
`;

export default Return;
