import React, { useMemo } from 'react';

import Popup, { ClosePopup, PopupActions, PopupContent, PopupHeader } from 'Components/Popup';
import { CadastroButton, EntrarButton } from '../../Views/Login/Form';

const RedirectPopup = ({ close, user }) => {
    if (!user || !user.beneficiary || !user.planData) {
        close();
        return null;
    }

    const redirectLink = useMemo(() => {
        const link = new URL(user.planData.registrationRedirectLink);

        if (/{cpf}/i.test(link.toString())) {
            return link.toString().replace(/{cpf}/i, user.cpf);
        }

        link.searchParams.append('cpf', user.cpf);
        return link.toString();
    }, [user.planData.registrationRedirectLink, user.cpf]);

    return (
        <Popup>
            <PopupHeader>
                <h4>Seu plano não está ativo</h4>
                <ClosePopup onClick={close} />
            </PopupHeader>
            <PopupContent>
                <div style={{ padding: '1rem', textAlign: 'center' }}>
                    <p dangerouslySetInnerHTML={{ __html: user.planData.registrationMessage ?? "" }}></p>
                </div>
            </PopupContent>
            <PopupActions>
                <CadastroButton onClick={close}>Agora não</CadastroButton>
                <EntrarButton type="submit">
                    <a href={redirectLink} target='_blank' style={{ color: "inherit", textDecoration: "none" }}>Ir para cadastro</a>
                </EntrarButton>
            </PopupActions>
        </Popup>
    )
};

const checkRedirect = ({ user }) => {
    if (!user || !user.beneficiary || !user.planData) return false;
    if (['Ativo'].includes(user.beneficiary.status)) return false;

    return user.planData.registrationRedirectLink != null;
};

export { checkRedirect };
export default RedirectPopup;
