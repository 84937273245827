import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import axios from 'axios';
import reduce from 'lodash/reduce';
import get from 'lodash/get';

import { Container, AlertContainer } from 'Components/Containers';
import { Control, Input, SubmitButton } from '../Register/Components';
import { RegularTitle } from 'Components/Typography';
import { PlanActions as FlexContainer } from 'Views/ContractPlans/Components';
import { Overlay } from 'Components/Modal/Overlay';
import Toast from 'Components/Toast';

import useUser from 'Hooks/User';
import useModals from 'Hooks/Modals';

const Payment = () => {
	const [data, setData] = useState({ name: '', card: '', expiryMonth: '', expiryYear: '', cvv: '' });
	const [status, setStatus] = useState({ loading: false, submited: false });
	const { user } = useUser();
	const { disableModal, openModal } = useModals();

	const location = useLocation();
	const params = new URLSearchParams(location.search);

	const onChange = (name) => ({ target: { value } }) => setData((d) => ({ ...d, [name]: value }));

	useEffect(() => {
		if (!params.get('plan')) {
			window.location.hash = '/';
		}
	}, []);

	const onSubmit = async () => {
		try {
			const search = reduce([...params.entries()], (acc, [k, v]) => ({ ...acc, [k]: v }), {});

			setStatus({ loading: true, submited: false, errors: null });
			const { data: res } = await axios.post('/api/v2/payment', { ...data, ...search, beneficiary: get(user, 'beneficiary') });

			setStatus({ loading: false, submited: true, success: res.success, errors: res.errors });

			if (res.success) {
				disableModal('plans');
				openModal('success', {
					text:
						res.successMessage ||
						'Transação cadastrada com sucesso!!<br/>Sua assinatura será ativada tão logo a transação seja confirmada',
					redirect: '/',
					forceReload: true,
				});
			}
		} catch (e) {
			console.error(e);
		}
	};

	const closeToast = () => setStatus((v) => ({ ...v, submited: false }));

	return (
		<Container>
			{status.loading && <Overlay />}
			<Toast
				text={status.success ? 'Sua solicitação está em andamento.' : 'Houve um erro!'}
				isOpen={!status.loading && status.submited}
				onClose={closeToast}
				variant={status.success ? 'success' : 'error'}
				duration={2500}
			/>

			<RegularTitle>Pagamento</RegularTitle>

			<h3 style={{ marginTop: 32, fontWeight: 'bold', fontSize: '1.25rem' }}>Informações do Cartão de Crédito</h3>
			<div style={{ marginTop: '40px' }}>
				<Control data-required>
					<span>Nome do Dono do Cartão</span>
					<Input value={data.name} onChange={onChange('name')} placeholder="Nome Completo" />
				</Control>

				<Control data-required>
					<span>Número do Cartão</span>
					<Input value={data.card} mask="9999 9999 9999 9999" onChange={onChange('card')} placeholder="Número do Cartão" />
				</Control>

				<FlexContainer>
					<Control data-required>
						<span>Validade</span>
						<FlexContainer>
							<Input
								placeholder="MM"
								mask="99"
								value={data.expiryMonth}
								onChange={onChange('expiryMonth')}
								style={{ width: '4rem' }}
							/>
							<Input
								placeholder="YYYY"
								mask="9999"
								value={data.expiryYear}
								onChange={onChange('expiryYear')}
								style={{ width: '4rem' }}
							/>
						</FlexContainer>
					</Control>
					<Control data-required>
						<span>CVV</span>
						<Input placeholder="CVV" mask="999" value={data.cvv} onChange={onChange('cvv')} style={{ width: '4rem' }} />
					</Control>
				</FlexContainer>
				<Control>
					<SubmitButton onClick={onSubmit}>Solicitar Plano</SubmitButton>
				</Control>
			</div>
			{Array.isArray(get(status, 'errors')) && (
				<AlertContainer>
					{status.errors.map((msg) => (
						<li key={msg}>{msg}</li>
					))}
				</AlertContainer>
			)}
		</Container>
	);
};

export default Payment;
