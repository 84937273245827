import styled from 'styled-components';

export const NewSenhaLabel = styled.label`
	grid-area: new-senha-label;
	font-size: 15px;
	font-weight: 700;
	font-family: Lato, sans-serif;
`;
export const NewSenhaInput = styled.input`
	grid-area: new-senha-input;
	font-family: Lato, sans-serif;
`;

export const SenhaLabel = styled.label`
	grid-area: senha-label;
	font-size: 15px;
	font-weight: 700;
	font-family: Lato, sans-serif;
`;
export const SenhaInput = styled.input`
	grid-area: senha-input;
	font-family: Lato, sans-serif;
`;
export const EntrarButton = styled.button`
	grid-area: entrar;
	width: 50%;
	height: 2em;
	font-size: 18px;
	border-radius: 5px;
	color: #ffffff;
	font-family: Lato, sans-serif;
	border: none;
	background: ${({ theme }) => theme.violetRed};
`;
