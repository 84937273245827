import styled from "styled-components";
import { ContractButton } from 'Components/Buttons';

export const ResultNumbers = styled.section`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	span {
		font-size: 11pt;
		font-weight: 700;
		color: ${({ theme }) => theme.frenchBlue};
	}
`;

export const FilterButton = styled.button`
	color: ${({ theme }) => theme.frenchBlue};
	background-color: rgba(0, 0, 0, 0);

	border: 1px solid ${({ theme }) => theme.frenchBlue};
	border-radius: 3px;

	font-size: 12pt;
	padding: 5px 10px;
`;

export const CheckboxWrapper = styled.label`
	position: relative;

	display: flex;
	align-items: center;

	width: 100%;
	margin-top: 10px;
`;

export const SubmitButton = styled(ContractButton)`
	background-color: ${({ theme }) => theme.frenchBlue};
	height: 30px;

	margin-top: -10px;
	margin-bottom: 10px;
`;