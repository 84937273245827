import React from 'react';

import Popup, { PopupActions, PopupContent } from 'Components/Popup';
import { EntrarButton, CadastroButton } from '../../Views/Login/Form';

const PlanPopup = ({ close }) => (
	<Popup>
		<PopupContent>
			<div style={{ padding: '1rem', fontSize: 'medium' }}>
				Você precisa ativar o seu plano. <br />
				Quer verificar suas opções?
			</div>
		</PopupContent>
		<PopupActions>
			<CadastroButton onClick={close}>Agora não</CadastroButton>
			<EntrarButton
				type="submit"
				onClick={() => {
					window.location.hash = '/plans';
					close();
				}}
			>
				Ver opções
			</EntrarButton>
		</PopupActions>
	</Popup>
);

const checkPlans = ({ user }) => {
	if (!user || !user.beneficiary) return null;

	return user.beneficiary.status === 'Rascunho' && !user.term.isModalOpen;
};

export { checkPlans };
export default PlanPopup;
