/* eslint-env browser */
import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';

ReactDOM.render(<App />, document.getElementById('main'));

if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
	navigator.serviceWorker.getRegistrations().then(registrations => {
		for (const registration of registrations) {
			registration.unregister();
		}
	});
	// navigator.serviceWorker
	// 	.register('/serviceWorker.js')
	// 	.then(() => console.info('Service worker running'))
	// 	.catch(() => console.error('Service worker error'));
}
