import React, { useEffect } from 'react';

import { Container } from 'Components/Containers';
import { RegularTitle, Info } from 'Components/Typography';
import { LinkButton } from 'Components/Buttons';
import useClinicContext from '../../Hooks/Clinic/ClinicSelect';

const data = {
	title: 'Obrigado por sua solicitação de agendamento!',
	data1:
		'Nossa equipe está verificando a disponibilidade de horários conforme sua preferência. Em breve você receberá a confirmação.',
	data2: '',
};

const Confirm = () => {
	const { onClinicClose } = useClinicContext();
	useEffect(() => {
		onClinicClose();
	}, []);

	return (
		<Container style={{ marginTop: '12%' }}>
			<RegularTitle>{data.title}</RegularTitle>
			<Info>{data.data1}</Info>
			<Info>{data.data2}</Info>

			<LinkButton to="/">Voltar ao inicio</LinkButton>
		</Container>
	);
};
export default Confirm;
