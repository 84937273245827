import React from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';

import Login from 'Views/Login';
import NewPassword from 'Views/NewPassword';
import Search from 'Views/Search';
import Confirm from 'Views/Confirm';
import Benefits from 'Views/Benefits';
import Result from 'Views/Result';
import Cancel from 'Views/Cancel';
import LabDetail from 'Views/LabDetail';
import Schedule from 'Views/Schedule';
import RecoverPassword from 'Views/RecoverPassword';
import VirtualCard from 'Views/VirtualCard';
import Register from 'Views/Register';
import Files from 'Views/Files';
import ContractPlans from 'Views/ContractPlans';
import Payment from 'Views/Payment';
import MyPlan from 'Views/MyPlan';
import Voucher from 'Views/Voucher';
import Appointments from 'Views/Appointments';
import AppointmentDetail from 'Views/AppointmentDetail';
import Telemedicina from 'Views/Telemedicina';
import FramePage from 'Views/Frame';
import DutyPage from "Views/Duty";

import { ContainerRoot } from 'Components/Containers';
import Loading from 'Components/SpinnerLoader';

import useUser from 'Hooks/User';
import useLoading from 'Hooks/Loading';
import TopBar from './TopBar';

const Router = () => {
	const { user = {}, doLogin } = useUser();
	const { isLoading } = useLoading();

	return (
		<ContainerRoot>
			{isLoading && <Loading />}
			<>
				{user.isLoggedin ? (
					<>
						<TopBar />

						<HashRouter>
							<Switch>
								<Route exact path="/" name="Search" component={Search} />
								<Route exact path="/search" name="Search" component={Result} />
								<Route exact path="/doctor/:name/:place/:telemedicine?" name="LabDetail" component={LabDetail} />
								<Route exact path="/schedule/:name/:speciality/:code/:telemedicine?" name="Calendar" component={Schedule} />
								<Route exact path="/confirm" name="Confirm" component={Confirm} />
								<Route exact path="/cancel/:id" name="cancel" component={Cancel} />
								<Route exact path="/benefits" name="Benefits" component={Benefits} />
								<Route exact path="/virtual-card" name="Virtual Card" component={VirtualCard} />
								<Route exact path="/appointments" name="Appointments" component={Appointments} />
								<Route exact path="/appointment-detail" name="Appointments" component={AppointmentDetail} />
								<Route exact path="/(telemedicina|covid-19)" name="Chat" component={Telemedicina} />
								<Route exact path="/register/:dep?" name="Register" component={Register} />
								<Route exact path="/files" name="Files" component={Files} />
								<Route exact path="/plans" name="Plans" component={ContractPlans} />
								<Route exact path="/payment" name="Payment" component={Payment} />
								<Route exact path="/my-plan" name="My Plan" component={MyPlan} />
                <Route exact path="/voucher" name="Voucher" component={Voucher} />
                <Route exact path="/health-monitor" name="Health monitor" component={FramePage} />
                <Route exact path="/duty" name="Duty 24hs" component={DutyPage} />
							</Switch>
						</HashRouter>
					</>
				) : (
					<HashRouter>
						<Switch>
							<Route exact path="/new-password/:cpf" name="NewPassword" component={NewPassword} />
							<Route exact path="/recover-password" name="Recover password" component={RecoverPassword} />
							<Route
								exact
								path="/login/:token"
								name="Login"
								render={history => {
									const token = history.match.params && history.match.params.token;
									if (token && token.length) {
										doLogin({ hashedToken: decodeURIComponent(token) });
									}
								}}
							/>
							<Route exact path="/" component={Login} />
						</Switch>
					</HashRouter>
				)}
			</>
		</ContainerRoot>
	);
};

export default Router;
