import styled from 'styled-components';

export const Header = styled.h1`
	grid-area: header;
	height: 100%;
	display: flex;
	align-items: flex-end;
	font-size: 24px;
	font-weight: 700;
	color: white;
`;
export const Logo = styled.img`
	grid-area: logo;
	justify-self: end;
`;
