import map from 'lodash/map';
import get from 'lodash/get';
import axios from 'axios';

const createAppointment = async ({
	spec,
	user,
	credentialed,
	observationField,
	returnField,
	discount,
	finalPrice,
	originalPrice,
	selectedDays,
	selectedHours,
	beneficiary,
	...rest
}) => {
	const payload = {
		...rest,
		speciality: spec,
		subsidy: { currency: 'BRL', value: discount },
		avusPrice: { currency: 'BRL', value: originalPrice },
		appointmentPrice: { currency: 'BRL', value: finalPrice },
		accreditedPrice: get(spec, 'accreditedPrice'),
    isFree: finalPrice === 0,
		user,
		beneficiary,
		credentialed,
		subsidyType: user.planData.subsidyType,
		planData: user.planData,
		observation: observationField.value,
		returnAppointment: `${returnField.checked ? 'Sim' : 'Não'}`,
		appointmentNotes: `
			Preço: <b>R$: ${finalPrice}</b>
			<br/>
			<br/>
			<h2>Dias solicitados</h2>
			<ul>
			${map(selectedDays, day => {
				const date = new Date(day);
				return `<li>${date.getDate()}/${date.getMonth() + 1}</li>`;
			}).join('')}
			</ul>
			<br />
			<br />
			<h2>Horas solicitadas</h2>
			<ul>${map(selectedHours, hour => `<li>${hour}</li>`).join('')}</ul>
			<br />
			<br />
			${observationField.value ? `Observação do cliente: ${observationField.value}` : ''}
			`,
	};
	const res = await axios.post('/api/data/Appointment', payload);
	return res;
};

export default createAppointment;
