import styled from 'styled-components';

export const Overlay = styled.div`
	position: fixed;

	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 300;

	background: rgba(0, 0, 0, 0.3);

	display: flex;
	align-items: center;
	justify-content: center;

	&[hidden] {
		display: none;
	}
`;

export const PopupContainer = styled.div`
	position: fixed;
	background: #fff;
	border-radius: 4px;

	width: 80vw;
	min-height: 200px;

	max-width: 400px;
	max-height: 80vh;

    display: flex;
    flex-direction: column;
`;
