import head from 'lodash/head';

import axios from 'axios';
import apiFilters from 'Utils/api-filters';
import formatDoctor from 'Utils/formatDoctor';

/* eslint-disable import/prefer-default-export */
export const getClinic = async code => {
	try {
		const { data: response } = await axios.get(
			'/api/data/Credentialed/find',
			apiFilters({
				term: 'code',
				operator: 'equals',
				value: code,
			})
		);
		if (!response.success) throw response.errors;
		if (!response.data.length) throw new Error('Credenciado não existe.');

		const credentialed = head(response.data);

		return formatDoctor(credentialed);
	} catch (e) {
		console.error(e);
		return {};
	}
};
