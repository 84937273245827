import { useEffect, useState } from 'react';
import useClinicContext from 'Hooks/Clinic/ClinicSelect';
import { getInitialData } from './effects';

const useClinicData = history => {
	const { clinicData, onClinicClose, onClinicSelect, isOpen } = useClinicContext();
	const [data, setData] = useState(clinicData);

	useEffect(
		() => {
			if (!window.location.hash.includes('/doctor') || !isOpen) {
				return;
			}

			if (clinicData.name && window.location.hash.includes(encodeURI(clinicData.name))) {
				return;
			}

			if (!clinicData.name) {
				getInitialData().then(res => {
					if (res && typeof res == "object" && Object.keys(res).length) {
						if (typeof onClinicSelect === 'function') 
							onClinicSelect({ data: res, params: { telemedicine: res.telemedicine } })();
						else 
							setData(res);
					}
				});
			}
			return;
		},
		[clinicData]
	);

	const onBackClick = () => {
		window.history.back();
		onClinicClose();
	};

	window.addEventListener('popstate', onClinicClose);

	return {
		clinicData: clinicData.name ? clinicData : data,
		onBackClick,
		isNavigated: history && !!history.location.search.length,
		isLab: clinicData && clinicData.type && clinicData.type === 'Laboratorio',
		isOpen,
	};
};

export default useClinicData;
