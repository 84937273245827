import { useState } from 'react';
import { not } from 'ramda';

const useModal = () => {
	const [isTrue, setValue] = useState(false);

	const setTrue = () => setValue(true);
	const setFalse = () => setValue(false);
	const toggle = () => setValue(not);

	return { isTrue, setTrue, setFalse, toggle };
};

export default useModal;
