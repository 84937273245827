import styled from 'styled-components';

const variants = {
	success: 'rgba(103, 169, 222, 0.8)',
	error: 'rgba(220, 50, 50, 0.8)',
  warning: 'rgba(224, 216, 98, 0.9)'
};

export const ToastContainer = styled.div`
	position: ${({ isStatic }) => isStatic ? "block" : "fixed"};
	background: #fff;
	border-radius: 4px;
	z-index: 300;

	width: calc(100% - 32px);

	max-width: 460px;
	max-height: 80vh;
	
	left: 0;
	margin: 0 16px;
  ${({ dockBottom }) => dockBottom ? ({ bottom: "40px" }) : ({ top: "58px" })}

	background-color: ${({ variant }) => variants[variant] || variants.success};
	color: white;
`;

export const ToastMessage = styled.div`
	display: flex;
	padding: 16px;
	justify-content: space-between;
	align-items: center;

	& > h4 {
		font-weight: 600;
	}
`;
