import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import get from 'lodash/get';
import pick from 'lodash/pick';

import { Container, AlertContainer } from 'Components/Containers';
import { Title } from 'Components/Typography';
import { SearchButton } from 'Components/Buttons';
import DotsLoader from 'Components/DotsLoader';
import Toast from 'Components/Toast';

import { Control, Input } from 'Views/Register/Components';
import { Item, RelativeContainer } from './Components';
import useUser from 'Hooks/User';
import useBool from 'Hooks/General/useBool';

const Voucher = () => {
	const user = useUser();

	const [value, setValue] = useState('');
	const [data, setData] = useState();
	const [status, setStatus] = useState({ loading: false, errors: null });
	const { isTrue: isToastOpen, setTrue: openToast, setFalse: closeToast } = useBool();

	const onChange = useCallback(({ target }) => {
    setValue(target.value);
    setStatus({ validated: false });
  }, [setValue]);

	const validate = useCallback(async () => {
		setStatus({ validating: true, errors: null, validated: false });
		const { data: response } = await axios.get(`/api/v2/voucher/validate/${value}`);

		if (response.success) {
			setData(response.data);
			setStatus({ validating: false, validated: true });
			return;
		}

		setStatus({ validating: false, errors: response.errors });
	}, [value, setStatus, setData]);

	const onSubmit = useCallback(async () => {
		setStatus(v => ({ ...v, loading: true, errors: null }));

		const post = {
			voucher: pick(data, ['contractPlan', 'contract', 'relatedCompany', 'daysToExpiration', '_id', '_updatedAt']),
			user: user.user,
		};

		const { data: response } = await axios.post('/api/v2/voucher/activate', post);
		if (response.success) {
			setStatus(v => ({ ...v, loading: false }));
			openToast();
		} else {
			setStatus(v => ({ ...v, loading: false, errors: response.errors }));
		}
	}, [data, user, setStatus]);

	const onSuccess = useCallback(() => {
		closeToast();
		window.location.hash = '/';
		window.location.reload();
	}, []);

	return (
		<Container>
			<Toast text="Voucher ativado!" isOpen={isToastOpen} onClose={onSuccess} duration={2500} />
			<Title>Voucher</Title>
			<p>Valide o Voucher recebido para ativar/renovar seu plano Avus.</p>

			<Control>
				<span>Voucher</span>
				<RelativeContainer>
					<Input type="text" onChange={onChange} value={value} />
					<SearchButton onClick={validate} data-success={status.validated}>
            {status.validated ? "OK!" : "Validar"}
          </SearchButton>
				</RelativeContainer>
			</Control>

			{(status.loading || status.validating) && <DotsLoader />}
			{data && !status.validating && (
				<div style={{ lineHeight: '30px', margin: '40px 0' }}>
					<Item>
						<b>Plano:</b> <span>{get(data, 'contractPlan.name')}</span>
					</Item>
					<Item>
						<b>Duração:</b> <span>{get(data, 'daysToExpiration')} dias</span>
					</Item>
          <Item>
						<b>Descrição:</b> <span>{get(data, 'description')}</span>
					</Item>
					<SearchButton onClick={onSubmit} style={{ width: '100%', marginTop: 15 }}>
						Ativar
					</SearchButton>
				</div>
			)}

			{Array.isArray(status.errors) && status.errors.length > 0 && (
				<AlertContainer>
					{status.errors.map(({ msg }) => (
						<li key={msg}>{msg}</li>
					))}
				</AlertContainer>
			)}
		</Container>
	);
};

export default Voucher;
