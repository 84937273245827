import { useEffect, useState } from 'react';
import size from 'lodash/size';

import useField from 'Hooks/General/useField';
import useUser from 'Hooks/User';
import useLoading from 'Hooks/Loading';

import axios from 'axios';
import apiFilters from 'Utils/api-filters';
import get from 'lodash/get';
import map from 'lodash/map';

import sendPasswordRequestByEmail from '../Password/sendPasswordRequestByEmail';
import changeGroupAndRole from './changeGroupAndRole';

const useLogin = () => {
	const userField = useField('');
	const emailField = useField('');
	const passwordField = useField('');
	const [isCadastro, setIsCadastro] = useState(false);
	const [isPassShown, setIsPassShown] = useState(false);
	const [submited, setSubmited] = useState(false);
	const [errors, setErrors] = useState([]);

	const { loading, loaded } = useLoading();
	const { doLogin, user = {} } = useUser();

	const toogleShowPass = () => setIsPassShown(value => !value);
	const setCadastro = flag => () => setIsCadastro(flag);

	const onCadastroClick = async () => {
		try {
			userField.value = userField.value.replace(/\D/g, '');
			if (emailField.value.length === 0) throw new Error('Informe seu e-mail.');
			if (userField.value.length === 0) throw new Error('Informe seu CPF.');

			loading('login');
			const { data: response } = await axios.get(
				'/api/data/Contact/find',
				apiFilters({
					term: 'cpf',
					operator: 'equals',
					value: userField.value,
				})
			);
			if (!response.success) throw response.errors;

			// Get contact and check if its active
			if (get(response, 'data.0.status') !== 'Ativo') throw new Error('Acesso indisponível. Entre em contato conosco.');

			const {
				data: [data],
			} = response;

			if (data.password) throw new Error('Usuário já existe.');

			// Otherwise, create user through Konecty's authentication
			const { data: userResponse } = await axios.put('/api/data/Contact', {
				ids: [{ _id: data._id, _updatedAt: { $date: get(data, '_updatedAt.$date', data._updatedAt) } }],
				data: {
					email: [{ address: emailField.value }],
					password: Math.random()
						.toString(36)
						.slice(-8),
				},
			});
			if (!userResponse.success) throw userResponse.errors;

			const success = await sendPasswordRequestByEmail({
				cpf: userField.value,
				id: data._id,
				to: emailField.value,
			});
			if (!success) throw new Error('Houve um erro na criação de sua conta');

			// changeGroupAndRole({ cpf: userField.value });

			setSubmited(true);
			loaded('login');
		} catch (e) {
			console.error(e);
			setErrors(map(e instanceof Array ? e : [e], err => ({ message: err.message })));
			loaded('login');
		}
	};

	const onLoginClick = async () => {
		try {
			userField.value = userField.value.replace(/\D/g, '');
			loading('doLogin');
			if (size(userField.value) === 0) {
				throw new Error('Informe seu CPF (Somente os números)');
			}

			if (size(passwordField.value) === 0) {
				throw new Error('Informe sua senha');
			}

			await doLogin({ loginInfo: { user: userField.value, password: passwordField.value } });
			loaded('doLogin');
		} catch (e) {
			if (e.response) setErrors([{ message: e.response.data }]);
			else setErrors(map(e instanceof Array ? e : [e], err => ({ message: err.message })));

			loaded('doLogin');
		}
	};

  useEffect(() => {
    if (user.message) {
      setErrors(v => [...new Set([].concat(v, { message: user.message }))]);
    }
  }, [user.message, setErrors]);

	return {
		userField,
		emailField,
		passwordField,
		isCadastro,
		isPassShown,
		submited,
		errors,
		hasError: size(errors) > 0,
		toogleShowPass,
		setCadastro,
		onCadastroClick,
		onLoginClick,
	};
};

export default useLogin;
