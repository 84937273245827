import React, { useState } from 'react';
import map from 'lodash/map';
import xor from 'lodash/xor';
import size from 'lodash/size';

import { Control, Input } from 'Views/Register/Components';
import { Checkbox } from 'Components/Forms';
import { CheckboxWrapper, SubmitButton } from './Components';
import AutoComplete from 'Components/AutoComplete';

const Filter = ({ open, data, onSubmit }) => {
	const [filters, setFilters] = useState({
		name: null,
		cities: [],
		district: null,
	});

	const numberedStrings = obj => map(obj, (count, city) => `${city} (${count})`);
	const buildItems = arr => map(arr, itm => ({ value: itm }));
	const onCheck = value => () => setFilters(f => ({ ...f, cities: xor(filters.cities, [value]) }));

	const cities = numberedStrings(data.cities);
	const uniqCity = size(cities) === 1;

	return (
		<div style={{ marginTop: 15, fontSize: 'small', display: open ? 'block' : 'none' }}>
			<Control>
				<span>Nome</span>
				<Input
					style={{ height: 22, width: '-webkit-fill-available', width: '-moz-available', width: 'fill-available' }}
					onChange={({ target }) => setFilters(v => ({ ...v, name: target.value }))}
				/>
			</Control>

			{cities && cities.length && (
				<Control as="div" style={{ marginTop: '-10px' }}>
					<span>Cidades</span>
					{map(cities, (city, i) => (
						<CheckboxWrapper key={city + i}>
							<Checkbox data-direction="left" onChange={onCheck(city)} disabled={uniqCity} checked={uniqCity} />
							<span>{city}</span>
						</CheckboxWrapper>
					))}
				</Control>
			)}

			<Control>
				<AutoComplete
					label="Bairro"
					items={buildItems(data.districts)}
					inputProps={{ style: { height: 22 } }}
					onSelect={v => setFilters(f => ({ ...f, district: v }))}
					openOnFocus
				/>
			</Control>

			<SubmitButton onClick={() => onSubmit(filters)}>Filtrar</SubmitButton>
		</div>
	);
};

export default Filter;
