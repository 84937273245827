import axios from 'axios';
import apiFilters from 'Utils/api-filters';
import formatAppointment from 'Utils/formatAppointment';
import head from 'lodash/head';

// eslint-disable-next-line import/prefer-default-export
export const doSearch = async location => {

	try {
		const urlCode = location.href;
		const slug = urlCode.split('?code=');
		const code = slug[1];
		const { data: response } = await axios.get(
			'/api/data/Appointment/find',
			apiFilters({
				term: 'code',
				operator: 'equals',
				value: Number(code),
			})
		);
		if (!response.success) throw response.errors;

		const appointment = head(response.data);
		return formatAppointment(appointment);
	} catch (e) {
		console.error(e);
		return {};
	}
};
