/* eslint-disable no-return-assign */
import apiFilters from 'Utils/api-filters';
import axios from 'axios';
import localforage from 'localforage';
import get from 'lodash/get';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React, { useEffect, useReducer } from 'react';

import useLoading from 'Hooks/Loading';
import UserContext from './context';

const intialContext = {
	isLoggedin: false,
	user: {},
};

const reducer = (state, action) => {
	console.groupCollapsed('UserContextReducer');
	console.log('Action:', action);
	console.log('State:', state);
	console.groupEnd();
	switch (action.type) {
		case 'LOGIN_SUCCESSFUL': {
			const { login, name, email, token, beneficiary, appointments, dependents, userLoaded } = action.payload;
			return {
				...state,
				isLoggedin: true,
				cpf: login || state.cpf,
				name: name || state.name,
				email: email || state.email,
				token: token || state.token,
				beneficiary: beneficiary || state.beneficiary,
				appointments: appointments || state.appointments,
				dependents: dependents || state.dependents,
				userLoaded: userLoaded !== undefined ? userLoaded : state.userLoaded,
				patient: { _id: (beneficiary || state.beneficiary || {})._id, name: name || state.name },
			};
		}
		case 'PLAN_LOADED': {
			return { ...state, hasNoBeneficiary: false, planData: action.payload };
		}
		case 'PLAN_FAILED': {
			return { ...state, hasNoBeneficiary: true, planData: { specialities: [] }, userLoaded: true };
		}
		case 'TERMS': {
			return { ...state, term: action.payload };
		}
		case 'SET_PATIENT': {
			const name = get(action.payload, "beneficiary.name.full");
			return { ...state, patient: { _id: action.payload._id, name } };
		}
		case 'LOGOUT': {
			const { message } = action.payload || {};
			return Object.assign({}, intialContext, { message });
		}
		default:
			return state;
	}
};

const UserContextProvider = ({ children }) => {
	const [user, dispatch] = useReducer(reducer, intialContext);
	const { loading, loaded } = useLoading();

	const doLogin = async ({ loginInfo, hashedToken }) => {
		let token;
		if (loginInfo) {
			const { data } = await axios.post(`/login`, loginInfo);
			const { ...userLogged } = data;
			({ token } = data);

			dispatch({ type: 'LOGIN_SUCCESSFUL', payload: { ...userLogged, token } });
		} else {
			const { data } = await axios.post(`/info`, { token: hashedToken });
			if (!data.success) {
				console.error('Token errado');
				window.location.hash = '/login';
				return;
			}
		}

		await localforage.setItem('token', hashedToken || token);

		loading('user data');
		getUserData()
			.then(() => loaded('user data'))
			.catch(() => loaded('user data'))
			.then(() => (hashedToken ? (window.location.hash = '/') : null));
	};
	const doLogout = async () => {
		dispatch({ type: 'LOGOUT' });
		loading('doLogout');

		try {
			await localforage.removeItem('token');
			await localforage.removeItem('username');
		} catch (e) {
			console.error(e);
		}
		loaded('doLogout');
		window.location.hash = '/';
	};

	const loadUser = async () => {
		const token = await localforage.getItem('token');

		if (token) {
			try {
				const { data } = await axios.post('/info', { token });
				if (data._id) {
					const { cpf: login, name, email } = data;
					dispatch({ type: 'LOGIN_SUCCESSFUL', payload: { token, name: name.full, login, email } });

					return { login };
				}
			} catch (error) {
				localforage.removeItem('token');
				loaded('user effect');
				throw error;
			}
		}
		throw new Error('No user loaded');
	};

	useEffect(() => {
		loading('userData');
		getUserData()
			.then(() => loaded('userData'))
			.catch(() => {
				loaded('userData');

				const unlogged_hashes = ["#/new-password", "#/recover-password", "#/login"];
				if (unlogged_hashes.some(hash => window.location.hash.startsWith(hash)) === false) {
					window.location.hash = '/';
				}
			});
	}, []);

	const getContact = async usuario => {
		if (!usuario) throw new Error('No user loaded');
		const { data: response } = await axios.get(
			'/api/data/Contact/find',
			apiFilters({ term: 'cpf', operator: 'equals', value: usuario.login })
		);
		if (!response.success) throw response.errors;
		await localforage.setItem('username', response.data[0].name.full);
		dispatch({ type: 'LOGIN_SUCCESSFUL', payload: { name: response.data[0].name.full } });

		return response.data[0];
	};

	const getBeneficiary = async contact => {
		const { data: response } = await axios.get(
			'/api/data/Beneficiary/find?sort=[{"_updatedAt": "DESC"}]',
			apiFilters({ term: 'beneficiary._id', operator: 'equals', value: contact._id })
		);

		if (!response.success || response.data.length === 0) {
			dispatch({ type: 'PLAN_FAILED' });
			return { contact };
		}

		const activeBeneficiary = response.data.find(b => ['Ativo', 'Rascunho', 'Aguardando confirmação de pagamento'].includes(b.status));

		return { contact, beneficiary: activeBeneficiary || response.data[0] };
	};

	const getDependents = async ({ contact, ...rest }) => {
		const { data: response } = await axios.get(
			'/api/data/Beneficiary/find',
			apiFilters({ term: 'holder._id', operator: 'equals', value: contact._id })
		);
		dispatch({ type: 'LOGIN_SUCCESSFUL', payload: { dependents: response.data } });

		return { dependents: response.data };
	};

	const getPlan = async ({ beneficiary, ...rest }) => {
		if (beneficiary && beneficiary.contractPlan) {
			const { data: response } = await axios.get(
				'/api/data/ContractPlan/find',
				apiFilters({ term: 'code', operator: 'equals', value: beneficiary.contractPlan.code })
			);

			if (!response.success || response.data.length === 0) {
				dispatch({ type: 'PLAN_FAILED' });
				return { beneficiary, ...rest };
			}

			const plan = response.data[0];
			if (plan == null || plan.allowWebAppUse === false) {
				dispatch({ type: 'LOGOUT', payload: { message: "Seu plano não permite acesso ao Web App" } });
				throw new Error("Plan doesn't allow web app");
			}

			return { plan, beneficiary };
		}
		return { plan: { specialities: [] } };
	};

	const getTags = async ({ beneficiary }) => {
		if (beneficiary) {
			const { data: response } = await axios.get(
				'/api/data/KeyValue/find',
				apiFilters({ term: 'contact._id', operator: 'equals', value: beneficiary.beneficiary._id })
			);

			beneficiary.tags = response.data || [];

			return { beneficiary };
		}
		return {};
	};

	const checkTerms = async ({ beneficiary }) => {
		if (beneficiary) {
			const { data: response } = await axios.get(`/api/v2/checkTerms?beneficiary=${beneficiary._id}`);

			dispatch({
				type: 'TERMS',
				payload: {
					accepted: response.success,
					newTerm: response.newTerms,
				},
			});

			return { terms: response.success };
		}
		return { terms: false };
	};

	const createObject = ({ beneficiary, plan, dependents, contact }) => {
		if (beneficiary && plan) {
			const { boundType } = beneficiary;
			const subsidyPerson = boundType === 'Dependente' ? plan.subsidyDependent : plan.subsidyHolder;
			const subsidyTele = boundType === 'Dependente' ? plan.subsidyDependentTelemedicine : plan.subsidyHolderTelemedicine;
			const subsidy = (subsidyPerson && subsidyPerson.value) || 0;

			dispatch({
				type: 'PLAN_LOADED',
				payload: {
					boundType,
					subsidy,
					specialities: plan.specialitiesSubsidy,
					payment: plan.appointmentPayments,
					maxDependents: plan.numberOfDependents,
					title: plan.promoteTitle || plan.name,
					value: plan.promoteValue,
					beneficiary: {},
					dependents,
					subsidyTele,
					isDutyFree: plan.telemedicineOnCall,
					...pick(plan, ["allowSchedule", "allowTelemedicine", "allowHealthMonitor", "trial", "subsidyType", "registrationMessage", "registrationRedirectLink"]),
					freeStuff: pick(plan, [
						'allowFreeAppointments',
						'freeAppointmentsLimitValue',
						'freeAppointmentsMainLimit',
						'freeAppointmentsSecondaryLimit',
						'freeAppointmentsMainLimitVerification',
						'freeAppointmentsValidByBeneficiary',
						'teleAllowFreeAppointments',
						'teleFreeAppointmentsLimitValue',
						'teleFreeAppointmentsMainLimit',
						'teleFreeAppointmentsSecondaryLimit',
						'teleFreeAppointmentsMainLimitVerification',
						'teleFreeAppointmentsValidByBeneficiary',
					]),
				},
			});

			beneficiary.beneficiary = contact;
			dispatch({ type: 'LOGIN_SUCCESSFUL', payload: { beneficiary, userLoaded: true } });
		} else {
			throw new Error('No plan loaded');
		}
	};

	const addTag = tag => {
		dispatch({
			type: 'LOGIN_SUCCESSFUL',
			payload: {
				beneficiary: {
					...user.beneficiary,
					tags: [...user.beneficiary.tags, tag],
				},
			},
		});
	};

	/**
	 * Selects a patient for the applcation operations (prices, appointments, etc...)
	 * @param {{ _id: string, name: { full: string } }} patient A Konecty's contact object
	 */
	const setPatient = patient => {
		dispatch({ type: 'SET_PATIENT', payload: patient });
	};

	const getUserData = () =>
		new Promise((resolve, reject) =>
			loadUser()
				.then(getContact)
				.then(getBeneficiary)
				.then(async obj => {
					const items = await Promise.all([getDependents(obj), getPlan(obj), getTags(obj), checkTerms(obj)]);
					return Object.assign({}, obj, ...items);
				})
				.then(createObject)
				.then(() => resolve())
				.catch(e => {
					console.error(e);
					reject();
					dispatch({ type: 'PLAN_FAILED' });
				})
		);

	return (
		<UserContext.Provider value={{ user, doLogin, doLogout, getUserData, fns: { checkTerms, getDependents, addTag, setPatient } }}>
			{children}
		</UserContext.Provider>
	);
};

UserContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default UserContextProvider;
