import get from 'lodash/get';
import { pipe, join, replace, chain, ifElse, startsWith, prop, slice, splitAt } from 'ramda';

import cdnImage from 'Utils/cdnImage';

const stringFormat = string => (!string || string === 'undefined' ? '' : string);
const formatTel = phone => {
	const isCel = phone.length === 11;
	return `(${phone.slice(0, 2)}) ${isCel ? phone.slice(2, 7) : phone.slice(2, 6)}-${
		isCel ? phone.slice(7, 20) : phone.slice(6, 20)
	}`;
};
const format0800 = pipe(
	replace(/\./g, ''),
	splitAt(4),
	join('.'),
	splitAt(-4),
	join('.')
);

export default credentialed => ({
	id: credentialed._id,
	name: credentialed.name.full,
	code: credentialed.code,
	type: credentialed.type,
	avatarUrl:
		cdnImage({ key: get(credentialed, 'image.0.key'), etag: get(credentialed, 'image.0.etag') }) || '/img/avatar-placeholder.png',
	email: get(credentialed, 'email.0.address', undefined),
	verified: credentialed.credentialedStage === 'Concluído',
	coords: credentialed.address[0].geolocation,
	place: credentialed.address[0].place,
	zone: credentialed.zone,
	address: credentialed.address[0],
	description: credentialed.siteDescription,
	detailedAddress: `${stringFormat(credentialed.address[0].placeType)} ${stringFormat(
		credentialed.address[0].place
	)}, ${stringFormat(credentialed.address[0].number)} ${stringFormat(credentialed.address[0].complement)}`,
	specialities: credentialed.specialities,
	phones: pipe(
		chain(
			pipe(
				prop('phoneNumber'),
				ifElse(startsWith('0800'), format0800, formatTel)
			)
		),
		slice(0, 2)
	)(credentialed.sitePhone || (credentialed.specialities.includes('Laboratório') ? [{ phoneNumber: '0800.500.0404' }] : [])),
	site: credentialed.site,
	schedules: credentialed.schedules,
	partnership: credentialed.partnership,
	distance: credentialed.distance || '-- km',
	price: credentialed.finalPrice != null ? credentialed.finalPrice : -1,
	discount: credentialed.discount,
	_speciality: credentialed.speciality
});
