import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import size from 'lodash/size';

import useResult from 'Hooks/Result';
import useModals from 'Hooks/Modals';
import useUser from 'Hooks/User';
import { applyFilter } from 'Hooks/Result/effect';

import { LinkButton } from 'Components/Buttons';
import DoctorInfo from '../../Components/DoctorInfo';
import { Container } from '../../Components/Containers';
import { TitleSearch, GeneralInfo, Verified, Disclaimer } from '../../Components/Typography';
import { ResultNumbers, FilterButton } from './Components';
import DotLoader from '../../Components/DotsLoader';
import Orderer from '../../Components/Orderer';
import Filter from './Filter';

import LabDetail from '../LabDetail';

const Search = ({ history }) => {
	const { data, displayData, setDisplayData, title, isLoading, filterData, params, error, warning } = useResult();
	const [disabled, setDisabled] = useState(false);
	const [filterOpen, setFilterOpen] = useState(false);

	const { user } = useUser();
	const { checkModal } = useModals();

	useEffect(() => {
		if (user && user.userLoaded) {
			checkUserDisabled().then(setDisabled);
		}
	}, [user]);

	const checkUserDisabled = async () => {
		const openPlans = await checkModal('plans', { user });
		if (openPlans) return true;

		const openRegister = await checkModal('register', { user });
		if (openRegister) return true;

		const openTerms = await checkModal('terms', { user });
		if (openTerms) return true;

		const openAppMigration = await checkModal('appMigration', { user });
		if (openAppMigration) return true;

		if (params.telemedicine && user.planData.allowTelemedicine === false) return true;

		if (!params.telemedicine && user.planData.allowSchedule === false) return true;

		return user.beneficiary.status !== 'Ativo';
	};

	const toggleFilter = () => setFilterOpen(is => !is);
	const onSubmitFilter = filters => {
		const filtered = applyFilter({ filters, data });
		setDisplayData(filtered);
	};

	return (
		<Container>
			<TitleSearch>
				{title.speciality} {params.telemedicine && <Verified>Telemedicina</Verified>}
				{!params.telemedicine && <GeneralInfo style={{ fontWeight: 500, fontSize: '16px' }}>em {title.place}</GeneralInfo>}
			</TitleSearch>

			{disabled && (
				<Disclaimer style={{ marginBottom: 10 }}>
					Para dar continuidade na visualização dos seus benefícios, é obrigatório aceitar os TERMOS DE USO na página inicial. 
Após aceitar os termos de uso, em CADASTRO, valide o seu cadastro para efetivar a inclusão e poder usufruir dos seus benefícios.
				</Disclaimer>
			)}

			{error != null && (
				<>
					<Disclaimer style={{ marginTop: "1rem", marginBottom: "3rem" }} dangerouslySetInnerHTML={{ __html: error }} />
					<LinkButton to="/">Voltar ao início</LinkButton>
				</>
			)}

			{warning != null && (
				<>
					<Disclaimer style={{ marginTop: "1rem", marginBottom: "3rem" }} dangerouslySetInnerHTML={{ __html: warning }} />
				</>
			)}

			{isLoading && <DotLoader />}
			{!isLoading &&
				error == null &&
				(size(data) > 0 ? (
					<>
						<ResultNumbers id="result-number">
							<span>
								{displayData.length || 0} resultado{displayData.length !== 1 ? 's' : ''} ordenado
								{displayData.length !== 1 ? 's' : ''} por <Orderer {...{ data: displayData, setData: setDisplayData }} />
							</span>
							<FilterButton onClick={toggleFilter}>{filterOpen ? 'Fechar Filtro' : 'Filtrar'}</FilterButton>
						</ResultNumbers>
						<Filter open={filterOpen} data={filterData} onSubmit={onSubmitFilter} />
						<LabDetail history={history} />
						<section id="results" style={{ display: data ? 'initial' : 'none' }}>
							{Array.isArray(displayData) &&
								displayData.map(entry => <DoctorInfo key={entry.id} data={entry} {...{ disabled, params }} />)}
						</section>
					</>
				) : (
					<>
						<h2 style={{ marginBottom: 10 }}>
							{params.telemedicine
								? 'Não há credenciados para esta especialidade em telemedicina.'
								: 'Não há credenciados para esta especialidade na região que você solicitou.'}
						</h2>
						<h3>
							Não se preocupe, neste caso entre em contato com nosso atendimento através do{' '}
							<a href="tel:08005000404" rel="noopener noreferrer">
								0800.500.0404
							</a>{' '}
							(telefone ou Whatsapp), informe a especialidade e o local de necessidade, que nossa equipe buscará por profissionais
							para seu atendimento.
						</h3>
						<LinkButton to="/">Voltar ao início</LinkButton>
					</>
				))}
		</Container>
	);
};

Search.propTypes = {
	history: PropTypes.object,
};

export default Search;
