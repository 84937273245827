import React, { useEffect, useState } from 'react';
import axios from 'axios';
import get from 'lodash/get';
import map from 'lodash/map';

import { Container } from 'Components/Containers';
import { RegularTitle } from 'Components/Typography';
import { Control, Dependent as File } from 'Views/Register/Components';

import apiFilter from 'Utils/api-filters';
import cdnImage from 'Utils/cdnImage';
import useUser from 'Hooks/User';

const Files = () => {
	const { user } = useUser();
	const [files, setFiles] = useState([]);

	const getContract = async () => {
		try {
			const {
				data: { success, data },
			} = await axios.get(
				'/api/data/Contract/find',
				apiFilter({ term: '_id', operator: 'equals', value: get(user, 'beneficiary.contract._id') })
			);

			if (success) {
				const [{ file = [] }] = data;
				setFiles(file);
			}
		} catch (e) {
			console.error(e);
		}
	};

	useEffect(() => {
		if (get(user, 'beneficiary')) {
			getContract();
		}
	}, [user]);

	const fileUrl = ({ key, etag }) => `/api/v2/file?key=${key}&etag=${etag}`;

	return (
		<Container>
			<RegularTitle>Documentos importantes</RegularTitle>
			<div>
				{map(files, ({ description, label, name = '', ...rest }) => (
					<Control key={name}>
						<File style={{ flexDirection: 'column' }} href={fileUrl(rest)} as="a" target="_blank">
							<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
								<b>{label || name.replace(/\..+/, '')}</b>
								<img src="/img/right.svg" width="10px" style={{ filter: 'brightness(0.7)' }} alt="" />
							</div>
							<p style={{ fontSize: '10pt', fontWeight: '500', textAlign: 'left', width: '100%' }}>{description}</p>
						</File>
					</Control>
				))}
			</div>
		</Container>
	);
};

export default Files;
