import styled, { keyframes } from 'styled-components';

export const BounceAnimation = keyframes`
  0% { right: -100%; }
  50% { right: -50% }
  100% { right: 0 }
`;

export const Overlay = styled.div`
	position: absolute;
	height: 100vh;
	width: 100%;
	left: 0px;
	top: 0;
	background-color: #00000099;
	z-index: 1;
	overflow: hidden;
`;

export const Drawer = styled.div`
	position: fixed;
	box-sizing: border-box;
	width: 70vw;
	min-width: 300px;
	padding: 4rem 1rem 3rem;
	right: 0;
	bottom: 0;
	top: 0;
	max-height: 100vh;
	background: ${({ theme }) => theme.primaryGradient};
	z-index: 1001;
	animation: ${BounceAnimation} 0.2s linear;
	overflow: hidden;
	color: white;

	display: flex;
	flex-direction: column;
`;

export const DrawerLink = styled.a`
	display: flex;
	align-items: center;
	margin: 1rem 0;
	color: inherit;
	text-decoration: none;
	font-size: 1rem;
	& img {
		margin-right: 1.75rem;
		width: 2rem;
		margin-left: 0.75rem;
	}
`;

export const ContaAvusLink = styled.a`
	background: url(/img/money-icon.svg);
	background-repeat: no-repeat;
	height: 30px;
	display: block;
	padding-left: 40px;
	padding-top: 9px;
	margin-top: 37px;
`;

export const Close = styled.a`
	width: 16px;
	height: 16px;
	background: url(/img/close.svg);
	background-size: 16px;
	background-repeat: no-repeat;
	z-index: 3;
	position: absolute;
	top: 16px;
	right: 16px;

	background-color: white;
	background-position: center;
	border-radius: 50%;
	padding: 8px;
`;

export const ContentSection = styled.section`
	margin: 0;
	overflow: auto;
`;

export const UserInfo = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	font-weight: 700;
	font-size: 1.25rem;

	img {
		width: 54px;
		margin-right: 1rem;
		object-fit: contain;
	}
`;

export const Separator = styled.hr`
	border: 1px solid #ffffff33;
	margin: 32px -32px 16px;
`;

export const SubMenu = styled.div`
	padding: 0 1rem;

	> a {
		font-size: 0.8rem;
		font-weight: 700;

		> img {
			width: 26px;
			height: 26px;
		}
	}
`;