import React from 'react';
import styled from 'styled-components';

import Popup, { PopupHeader, PopupActions, PopupContent } from 'Components/Popup';
import { EntrarButton } from '../../Views/Login/Form';

const Content = styled(PopupContent)`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	font-size: medium;
`;

const SuccessPopup = ({ close, text, redirect, forceReload = false }) => (
	<Popup>
		<PopupHeader style={{ justifyContent: 'flex-end' }}></PopupHeader>
		<Content>
			<img src="/img/like.svg" alt="" />
			<div style={{ padding: '1rem 0' }} dangerouslySetInnerHTML={{ __html: text || 'Registro atualizado com sucesso!' }} />
		</Content>
		<PopupActions style={{ justifyContent: 'flex-end' }}>
			<EntrarButton
				type="submit"
				style={{ minWidth: '80px' }}
				onClick={() => {
					if (redirect != null) {
						if (forceReload === true) {
							location.href = redirect;
						} else {
							location.hash = redirect;
						}
					}
					close();
				}}
			>
				Ok
			</EntrarButton>
		</PopupActions>
	</Popup>
);

export default SuccessPopup;
