import * as React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

// violetRed: '#046DAA',
// mulberry: '#046DAA',
const colors = {
	primary: '#046DAA',
	violetRed: '#046DAA',
	mulberry: '#046DAA',
	fadedBlue: '#6ca1d2',
	frenchBlue: '#4973af',
	light: '#979797',
	lighter: 'transparent',
	dark: '#4a4a4a',
	btnBlue: '#4a90e2',

	primaryGradient:
		'linear-gradient(45deg, rgba(67,179,219,1) 0%, rgba(5,109,165,1) 29%, rgba(1,129,201,1) 67%, rgba(35,85,139,1) 100%);',
};

const GlobalStyle = createGlobalStyle`
	${reset}
	html {
		-webkit-font-smoothing: antialiased;
	}
	body {
		display: flex;
		justify-content: center;
		font-family: 'Lato', sans-serif;
		color: #4a4a4a;
		min-height: 100vh;
		width: 100vw;
		margin: 0 0;
	}

	select, textarea {
		font-family: 'Lato', sans-serif;
		background-color: white;
	}

	input,
  select[data-default=true] {
		border: solid 1px #979797;
		border-radius: 5px;
		border: 1px solid lightgrey;
		font-size: 18px;
		padding: 8px;
		font-family: 'Lato', sans-serif;
		height: 24px;
		background-color: white;
	}
	[data-show-arrow="true"]:after {
			content: "";
			display: block;
			width: 4px;
			height: 4px;
			border: 4px solid transparent;
			box-sizing: border-box;
			border-bottom-color: currentcolor;
			border-right-color: currentcolor;
			position: absolute;
			right: 28px;
			transform: rotate(45deg);
			top: 42px;
	}

	#main {
		display: flex;
		justify-content: center;
		min-height: 100vh;
		width: 100%;
		// max-width: 768px;
	}

	*:disabled,
	[disabled],
	.disabled {
		opacity: 0.5;
		pointer-events: none;
	}
`;

const Theme = ({ children, variables }) => {
  const [selectedTheme, setTheme] = React.useState(colors);
	variables.then(data => {
    if ('object' === typeof data && Object.keys(data).length > 3) {
      setTheme(data);
    }
  });

	return (
		<ThemeProvider theme={selectedTheme}>
			<>
				<GlobalStyle />
				{children}
			</>
		</ThemeProvider>
	);
};

Theme.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Theme;
