import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import axios from 'axios';

import { Container, TabContainer, SelectContainer, AlertContainer } from 'Components/Containers';
import { RegularTitle, Tab, CardType } from 'Components/Typography';
import DotLoader from 'Components/DotsLoader';
import Voltar from '../LabDetail/Voltar';
import Toast from 'Components/Toast';

import PersonalTab from './tabPessoal';
import ContactTab from './tabContato';
import AddressTab from './tabEndereco';
import DependentTab from './tabDependentes';

import useUser from 'Hooks/User';
import useModals from 'Hooks/Modals';
import useTabs from 'Hooks/General/useTabs';
import useLoading from 'Hooks/Loading';
import useBool from 'Hooks/General/useBool';

const Register = () => {
	const {
		user: { beneficiary, dependents, planData },
		fns: { getDependents: reloadDependents, addTag },
	} = useUser();
	const { loading, loaded } = useLoading();
	const { disableModal } = useModals();

	const [errors, setErrors] = useState([]);
	const [data, setData] = useState();
	const [isLoading, setLoading] = useState(false);
	const { isTrue: isToastOpen, setTrue: openToast, setFalse: closeToast } = useBool();
	const { dep } = useParams();

	const { isTabActive, setTab, tab, nextTab } = useTabs(['pessoal', 'contato', 'endereco', 'dependentes']);
	const onTabClick = (v) => () => setTab(v);
	const isHolder = () => get(data, 'boundType') === 'Titular';

	const onSave = async (section, values) => {
		try {
			loading('save-register');
			const { data: response } = await axios.post('/api/v2/save-register', {
				tab: section,
				values,
				beneficiary: data._id,
				contact: get(data, 'beneficiary._id', beneficiary.beneficiary._id),
				plan: get(beneficiary, 'contractPlan._id'),
				contract: get(beneficiary, 'contract._id'),
				company: get(beneficiary, 'relatedCompany._id'),
				isHolder: isHolder(),
			});

			if (response.errors) {
				console.error(response.errors);
				setErrors(response.errors);
				loaded('save-register');
				return;
			}

			let newBeneficiary = data;
			if (response.beneficiary) {
				newBeneficiary = Object.assign({}, newBeneficiary, response.beneficiary);
				getDependent(newBeneficiary);
			}
			if (response.contact) {
				newBeneficiary.beneficiary = response.contact;
				setData(newBeneficiary);

				if (response.contact.registerComplete) {
					addTag({ name: 'Cadastro Completo', value: 'Sim' });
					disableModal('register');
				}
			}

			openToast();
			loaded('save-register');
			(tab !== 'endereco' || isHolder()) && nextTab();
		} catch (e) {
			console.error(e);
		}
	};

	const getDependent = (ben) => {
		axios.get(`/api/v2/getContact/${ben.beneficiary._id}`).then(({ data }) => {
			ben.beneficiary = data[0];
			setData(ben);
			setLoading(false);

			reloadDependents({ contact: get(beneficiary, 'beneficiary') });
		});
	};

	useEffect(() => {
		if (!beneficiary) return;

		if (dep) {
			const index = dep.replace(/\D/g, '');
			setLoading(true);
			if (dependents[index - 1]) {
				const dep = dependents[index - 1];

				getDependent(dep);
			} else {
				if (Number(index) <= planData.maxDependents) setData({ _id: 'new' });
				else setData(beneficiary);
				setLoading(false);
			}
		} else {
			setData(beneficiary);
		}
		setTab('pessoal');
	}, [beneficiary, dep]);

	useEffect(() => {
		setErrors([]);
	}, [tab]);

	if (!beneficiary) return null;
	if (!data || isLoading)
		return (
			<div style={{ marginTop: '80px' }}>
				<DotLoader />
			</div>
		);

	return (
		<Container style={{ maxWidth: 436 }}>
			<Toast text="Registro Atualizado!" isOpen={isToastOpen} onClose={closeToast} duration={2500} />
			<br />
			{isHolder() ? (
				<>
					<Voltar
						onClick={() => {
							location.hash = '/';
						}}
						text="Voltar para tela Inicial"
					/>
					<br />
				</>
			) : (
				<>
					<Voltar
						onClick={() => {
							setData(beneficiary);
							location.hash = '/register';
						}}
						text="Voltar para o Titular"
					/>
					<br />
				</>
			)}

			<CardType style={{ fontWeight: '600', color: 'inherit' }}>Dados de</CardType>
			<RegularTitle>{get(data, 'beneficiary.name.full', 'Novo Dependente')}</RegularTitle>

			<br />
			<TabContainer>
				<Tab onClick={onTabClick('pessoal')} data-active={isTabActive('pessoal')} data-small>
					Pessoal
				</Tab>
				<Tab onClick={onTabClick('contato')} data-active={isTabActive('contato')} data-small>
					Contato
				</Tab>
				<Tab onClick={onTabClick('endereco')} data-active={isTabActive('endereco')} data-small>
					Endereço
				</Tab>
				{isHolder() && (
					<Tab onClick={onTabClick('dependentes')} data-active={isTabActive('dependentes')} data-small>
						Dependentes
					</Tab>
				)}
			</TabContainer>
			<SelectContainer>
				{Array.isArray(errors) && errors.length > 0 && (
					<AlertContainer>
						{errors.map(({ field, msg }) => (
							<li key={msg}>{msg}</li>
						))}
					</AlertContainer>
				)}
				{isTabActive('pessoal') && <PersonalTab data={data} onSave={onSave} />}
				{isTabActive('contato') && <ContactTab data={data} onSave={onSave} />}
				{isTabActive('endereco') && <AddressTab data={data} onSave={onSave} />}
				{isTabActive('dependentes') && (
					<DependentTab data={{ beneficiary: data, dependents }} maxDependents={planData.maxDependents} />
				)}
			</SelectContainer>
		</Container>
	);
};

export default Register;
