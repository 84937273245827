import { useContext } from 'react';

import ModalContext from './context';

const useModals = () => {
	const context = useContext(ModalContext);
	return context;
};

export default useModals;
