import styled from 'styled-components';

export const Plan = styled.div`
    display: flex;
    flex-direction: column;

    padding: 10px;
    border-radius: 4px;
    margin: 3px 0;
    box-shadow: 2px 2px 4px 0px rgba(189,189,189,0.75);

    b {
        font-weight: bold;
    }

    &> div:nth-child(2) {
        display: flex;
        justify-content: space-between;

        &> span {
            width: 100%;
            text-align: end;
        }
    }
`;

export const PlanDescription = styled.div`
    width: --webkit-fill-available;
		width: -moz-available;
		width: fill-available;

    display: none;
    padding: 5px;
    font-size: 10pt;
    color: #797979;

    &[data-active=true] {
        display: block;
    }
`;

export const PlanActions = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &> input,
    &> button {
        margin-left: 3px;
    }

    &> button {
        height: 1.5em;
        padding: 2px 7px;
    }
`;