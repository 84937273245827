import { DoctorInfo } from 'Components/Cards';
import { AvatarContainer, Date, InfoContainer, OverlayContainer, Time } from 'Components/Containers';
import { CadastroButton } from 'Components/Forms';
import GoogleMap from 'Components/GoogleMap';
import { DoctorAvatar, IconClock, IconDate } from 'Components/Icons';
import SpinnerLoader from 'Components/SpinnerLoader';
import { DateInfo, DetailInfo, RegularTitle, TitleDetail, TitleDoctor, Verified } from 'Components/Typography';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { getClinic } from '../../Hooks/Appointment/credentialed/effect';
import { doSearch } from '../../Hooks/Appointment/effect';
import Voltar from './Voltar';

moment.locale('pt-br');

const showButtons = value => {
	if (value === 'Aberta' || value === 'Agendada') return true;
	return false;
};

const ApointmentDetail = () => {
	const [appointment, setAppointment] = useState({});
	const [clinicData, setClinicData] = useState({});
	const [dots, setDots] = useState(true);

	const cancelAppointment = code => {
		window.location.hash = `/cancel/${code}`;
	};

	useEffect(
		() => {
			window.scrollTo(0, 0);
			doSearch(window.location)
				.then(result => {
					setAppointment(result);

					getClinic(result.credentialed.code).then(clinic => {
						setClinicData(clinic);
						setDots(false);
					});
				})
				.catch(error => {
					console.error(error);
				});
		},
		[window.location]
	);

	const isEditable = useMemo(() => {
		if (["Agendada", "Aberta"].includes(appointment.status) === false) return false;
		if (moment(appointment.startAt).isBefore(moment())) return false;
		if ("Plantão24h" === appointment.speciality) return false;

		return true;
	}, [appointment]);

	const coords = clinicData.coords || false;

	if (dots) return <SpinnerLoader />;

	return (
		<OverlayContainer style={{ marginTop: '50px' }}>
			<Voltar />
			<DoctorInfo>
				<AvatarContainer>
					<DoctorAvatar src={clinicData.avatarUrl} alt="" />
				</AvatarContainer>
				<InfoContainer>
					<Verified style={{ alignSelf: 'flex-end' }}>verificado</Verified>
				</InfoContainer>
			</DoctorInfo>

			<TitleDetail>{clinicData.name}</TitleDetail>

			<DetailInfo>{appointment.speciality}</DetailInfo>

			<RegularTitle>{appointment.status}</RegularTitle>
			<TitleDoctor>Paciente: {appointment.beneficiary.beneficiary.name.full}</TitleDoctor>

			<div style={{ marginTop: '8px' }}>
				{appointment.startAt === undefined && (
					<DetailInfo style={{ marginTop: '16px', marginBottom: '16px' }}>A AVUS está tentando agendar sua consulta.</DetailInfo>
				)}

				{appointment.startAt !== undefined && (
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<Date style={{ marginTop: '8px', width: '40%' }}>
							<IconDate style={{ opacity: '0.5' }} />
							<DateInfo style={{ color: '#808080' }}>{moment(appointment.startAt).format('l')}</DateInfo>
						</Date>
						<Time style={{ marginTop: '8px', width: '40%' }}>
							<IconClock style={{ opacity: '0.5' }} />
							<DateInfo style={{ color: '#808080' }}>{moment(appointment.startAt).format('LT')}</DateInfo>
						</Time>
					</div>
				)}
			</div>

			{isEditable && (
				<CadastroButton
					onClick={() => {
						window.location.hash = `/schedule/${encodeURI(clinicData.name)}/${encodeURI(appointment.speciality)}/${clinicData.code
							}?updateID=${appointment.id}`;
					}}
				>
					Trocar Agendamento
				</CadastroButton>
			)}

			<RegularTitle style={{ marginTop: '16px' }}>Solicitação Original:</RegularTitle>

			<div style={{ marginTop: '16px', width: '100%' }}>
				<>{ReactHtmlParser(appointment.appointmentNotes)}</>
			</div>

			{isEditable && (
				<CadastroButton
					onClick={() => {
						cancelAppointment(appointment.id);
					}}
				>
					Cancelar Agendamento
				</CadastroButton>
			)}

			<RegularTitle style={{ marginTop: '56px' }}>Localização</RegularTitle>
			{coords && <GoogleMap position={clinicData.coords || []} mapStyle={{ margin: '.5rem 0' }} />}
			<DetailInfo style={{ fontSize: '16px', marginTop: '8px' }}>
				{(clinicData.detailedAddress || '').replace(/undefined/, '')}
			</DetailInfo>
			<DetailInfo style={{ fontSize: '16px' }}>{clinicData.city}</DetailInfo>
		</OverlayContainer>
	);
};
export default ApointmentDetail;
