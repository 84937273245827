import styled from 'styled-components';

const Header = styled.div`
	position: fixed;
	z-index: 100;
	top: 0;
	width: 100vw;
	box-sizing: border-box;
	color: ${({ theme }) => theme.light};
	background: ${({ theme }) => theme.primaryGradient};
	background-size: 100vw 100vh;
	margin-left: 8px;
	margin-bottom: 8px;
	margin: 0 0;
	height: 54px;
	display: grid;
	margin-bottom: 40px;
	grid-template-areas: 'back logo burger';
`;

export default Header;
