import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { sortByDistance, sortByVerified, sortByPrice } from 'Utils/sort';
import { cond, equals, not } from 'ramda';

const Trigger = styled.span`
	text-decoration: underline;
	color: ${({ theme }) => theme.btnBlue};
`;

const Option = styled.p`
	padding: 1rem;
	border-bottom: 1px solid #ccc;

	&:first-of-type {
		border-top: 1px solid #ccc;
	}
`;

const Container = styled.div`
	margin: 1rem 0;
`;

const Dropdown = ({ data, setData }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [selected, setSelected] = useState('Preço');

	const onOptionClick = ({ target: { innerText: value } }) => {
		setIsOpen(false);
		if (selected === value) return;

		setSelected(value);
		const newData = cond([
			[equals('Distância'), () => sortByDistance(data)],
			[equals('Verificado'), () => sortByVerified(data)],
			[equals('Preço'), () => sortByPrice(data)],
		]);

		setData(newData(value));
	};

	const onOptionKeyPress = e => {
		if (e.key === 'Enter') return onOptionClick(e);
	};

	const onDropClick = () => setIsOpen(not);

	return (
		<>
			<Trigger role="button" tabIndex="0" onClick={onDropClick} onKeyPress={onOptionClick}>
				{selected}
			</Trigger>
			<Container hidden={!isOpen}>
				<Option tabIndex="0" aria-label="Distância" onKeyPress={onOptionKeyPress} onClick={onOptionClick}>
					Distância
				</Option>
				<Option tabIndex="0" aria-label="Verificado" onKeyPress={onOptionKeyPress} onClick={onOptionClick}>
					Verificado
				</Option>
				<Option tabIndex="0" aria-label="Preço" onKeyPress={onOptionKeyPress} onClick={onOptionClick}>
					Preço
				</Option>
			</Container>
		</>
	);
};

Dropdown.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object),
	setData: PropTypes.func.isRequired,
};

export default Dropdown;
