import propEq from 'lodash/matchesProperty';
import React from 'react';

import useBool from 'Hooks/General/useBool';
import useUser from 'Hooks/User';

import ScrollLock, { TouchScrollable } from 'react-scrolllock';
import Back from './Back';
import Burger from './Burger';
import Header from './Header';
import Logo from './Logo';

import { Close, ContentSection, Drawer, DrawerLink, Overlay, Separator, SubMenu, UserInfo } from './Drawer';

const TopBar = () => {
	const { user, doLogout } = useUser();
	const { isTrue: isOpen, toggle: toggleOpen } = useBool();
	const { isTrue: infoOpen, toggle: toggleInfo } = useBool();
	const isUserActive = () => propEq('beneficiary.status', 'Ativo')(user);

	return (
		<Header>
			<Back />
			<Logo />
			{user.isLoggedin ? <Burger onClick={toggleOpen} role="button" tabIndex="0" onKeyPress={toggleOpen} /> : null}
			{isOpen ? <Overlay onClick={toggleOpen} /> : null}
			{isOpen ? (
				<>
					<ScrollLock />
					<Drawer id="drawer">
						<Close onClick={toggleOpen} />
						<UserInfo>
							<img src="/img/single-person.png" alt="Avatar" />
							{user.name}
						</UserInfo>
						<Separator />
						<TouchScrollable>
							<ContentSection role="menu">
								<DrawerLink href="#/" onClick={toggleOpen} role="menuitem">
									<img src="/img/menu-icons/home.svg" alt="icon" role="presentation" aria-hidden="true" />
									Início
								</DrawerLink>
								<DrawerLink href="#/appointments" onClick={toggleOpen} role="menuitem">
									<img src="/img/menu-icons/calendar.svg" alt="icon" role="presentation" aria-hidden="true" />
									Minhas Consultas
								</DrawerLink>
								<DrawerLink href="#/virtual-card" onClick={toggleOpen} role="menuitem">
									<img src="/img/menu-icons/card.svg" alt="icon" role="presentation" aria-hidden="true" />
									Meu Cartão Virtual
								</DrawerLink>
								<DrawerLink href="#/my-plan" onClick={toggleOpen} role="menuitem">
									<img src="/img/menu-icons/star.svg" alt="icon" role="presentation" aria-hidden="true" />
									Meu Plano Avus
								</DrawerLink>
								<DrawerLink href="#/register" onClick={toggleOpen} role="menuitem">
									<img src="/img/menu-icons/profile.svg" alt="icon" role="presentation" aria-hidden="true" />
									Cadastro
								</DrawerLink>
								<DrawerLink href="#/files" onClick={toggleOpen} role="menuitem">
									<img src="/img/menu-icons/file.svg" alt="icon" role="presentation" aria-hidden="true" />
									Documentos
								</DrawerLink>
								<DrawerLink onClick={toggleInfo} role="menuitem" aria-haspopup="true">
									<img src="/img/menu-icons/headset.svg" alt="icon" role="presentation" aria-hidden="true" />
									Canais de Atendimento
								</DrawerLink>
								<SubMenu id="contact-info" hidden={!infoOpen} aria-hidden={!infoOpen}>
									<DrawerLink href="tel:08005000404">
										<img src="/img/menu-icons/headset.svg" alt="icon" role="presentation" aria-hidden="true" />
										0800 500 0404
									</DrawerLink>
									<DrawerLink href="https://wa.me/5508005000404" target="_blank">
										<img src="/img/menu-icons/whatsapp.svg" alt="icon" role="presentation" aria-hidden="true" />
										0800 500 0404
									</DrawerLink>
									<DrawerLink href="mailto:atendimento@avus.com.br">
										<img src="/img/menu-icons/envelope-alt.svg" alt="icon" role="presentation" aria-hidden="true" />
										atendimento@avus.com.br
									</DrawerLink>
								</SubMenu>
								<DrawerLink onClick={doLogout}>
									<img src="/img/menu-icons/exit.svg" alt="icon" role="presentation" aria-hidden="true" />
									Sair
								</DrawerLink>
							</ContentSection>
						</TouchScrollable>
					</Drawer>
				</>
			) : null}
		</Header>
	);
};

export default TopBar;
