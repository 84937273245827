import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { propEq } from 'ramda';

import debounce from 'lodash/debounce';
import deburr from 'lodash/deburr';
import size from 'lodash/size';

import { Container, List, Item, Clean } from './Components';
import { Control, Input } from 'Views/Register/Components';

const AutoComplete = ({ items = [], onSelect, defaultValue = '', label, inputProps = {}, openOnFocus }) => {
	const [isOpen,   setOpen   ] = useState(false);
	const [text,     setText   ] = useState(defaultValue);
	const [options,  setOptions] = useState(items);
	const [hovering, setHover  ] = useState(false);
	const ref = useRef();

	const onChange = ({ target }) => setText(target.value);
	const searchOptions = debounce(() => {
		const isFocused = document.activeElement === ref.current;

		if (text.length < 2 && !(openOnFocus && isFocused)) {
			onSelect(null);
			setOpen(false);
			return;
		}

		// If the text is equal some item from the array (user selected an option)
		if (items.find(propEq('label', text)) || items.find(propEq('value', text))) return;

		const matches = items.filter(item => new RegExp(deburr(text), 'i').test(deburr(item.label || item.value)) || item.omnipresent);

		if (matches.length) {
			setOptions(matches);
			isOpen || setOpen(true);
		}
	}, 300);

	useEffect(searchOptions, [text]);
	useEffect(() => {
		if (defaultValue && size(items)) {
			const item = (items || []).filter(propEq('value', defaultValue));

			if (item[0])
				onItemClick(item[0])();
		}
	}, [items]);

	const onItemClick = item => async () => {
		setText(item.label || item.value);
		setOpen(false);

		if (typeof item.onClick === 'function') {
			item = await item.onClick();
		}
		
		if (!item.value) {
			setText('');
			return;
		}

		typeof onSelect === 'function' && onSelect(item.value);
	};

	const onFocus = () => openOnFocus && setOpen(true);
	const onBlur  = () => setTimeout(() => setOpen(false), 200);
	const onHover = () => setHover(true);
	const onLeave = () => setHover(false);
	const onClean = () => setText("");

	return (
		<Control>
			<Container onBlur={onBlur} onFocus={onFocus} onMouseEnter={onHover} onMouseLeave={onLeave} ref={ref}>
				<span>{label}</span>
				<Input
					type="text"
					value={text}
					onChange={onChange}
					aria-autocomplete="list"
					{...inputProps}
				/>

				<Clean data-out={!hovering} onClick={onClean} tabIndex="1">
					&times;
				</Clean>

				<List hidden={!isOpen}>
					{options.map((item, i) => (
						<Item
							key={item.value + i}
							role="button"
							aria-sort="ascending"
							aria-roledescription="Select autocompleted value"
							onClick={onItemClick(item)}
						>
							{item.label || item.value}
						</Item>
					))}
				</List>
			</Container>
		</Control>
	);
};

AutoComplete.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string.isRequired,
			label: PropTypes.string,
			onClick: PropTypes.func,
			omnipresent: PropTypes.bool,
		})
	).isRequired,
	onSelect: PropTypes.func,
	defaultValue: PropTypes.string,
	label: PropTypes.string,
	inputProps: PropTypes.object,
	openOnFocus: PropTypes.bool,
};

export default AutoComplete;
