import styled from 'styled-components';

export const Card = styled.div`
	display: grid;
	align-self: center;
	width: 100%;
	grid-template-areas:
		'header header header'
		'error error error'
		'new-senha-label new-senha-label new-senha-label'
		'new-senha-input new-senha-input new-senha-input'
		'senha-label senha-label senha-label'
		'senha-input senha-input senha-input'
		'entrar entrar entrar';
	grid-gap: 16px;
	margin: 8px;
	padding: 16px;
	border: 1px solid ${({ theme }) => theme.light};
	border-radius: 4px;
`;

export const CardShedule = styled.div`
	display: grid;
	grid-template-areas:
		'title title'
		'subtitle subtitle'
		'date  time';
	width: 100%;
	height: 86px;
	border-radius: 4px;
	background-color: ${({ theme }) => theme.fadedBlue};
	margin-top: 13px;
	box-sizing: border-box;
	color: white;
	padding: 9px 18px;
	margin-bottom: 13px;
`;

export const CardBenefits = styled.div`
	display: grid;
	grid-template-areas: 'icon title';
	grid-template-columns: 72px 1fr;
	width: 100%;
	height: 86px;
	border-radius: 4px;
	background-color: ${({ theme }) => theme.fadedBlue};
	box-sizing: border-box;
	color: white;
	padding: 16px;
	margin: 0.5rem 0;
`;

export const RedCardBenefits = styled(CardBenefits)`
	background-color: ${({ theme }) => theme.violetRed};
	margin-bottom: 1rem;
`;

export const DepositionsCard = styled.div`
	display: grid;
	grid-template-areas: 'avatar description' 'name name';
	grid-template-columns: 72px 1fr;
	width: 100%;
	height: 93px;
	margin-top: 13px;
	box-sizing: border-box;
	padding: 16px 0px;
	margin-bottom: 13px;
	overflow: hidden;
`;

export const CardOption = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: end;
	border: 1px solid ${({ theme }) => theme.fadedBlue};
	border-radius: 4px;
	padding: 17px 9px 9px;
	width: 48%;
	height: 282px;
	box-sizing: border-box;
`;

export const CardBestOption = styled(CardOption)`
	padding: 6px 9px 9px;
	height: 100%;
`;

export const DoctorCard = styled.div`
	position: relative;
	display: flex;
	width: 100%;
	height: 180px;
	border-radius: 4px;
	margin-top: 12px;
	box-sizing: border-box;
	color: white;
	padding: 0px;
	border: 1px solid #979797;
	overflow: hidden;
`;

export const DoctorInfo = styled(DoctorCard)`
	grid-template-columns: 173px auto;
	border: none;
	justify-items: self-end;
	margin-bottom: 12px;
	height: auto;
`;
