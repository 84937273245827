import { DateUtils } from 'react-day-picker';
import clamp from 'lodash/clamp';

export default ({ selectedDays, selectedHours, setSelectedDays, setSelectedHours }) => {
	const MIN_UTIL_HOURS = 6;
	const WORK_HOURS = { start: 7, end: 18 };
	const invalidHours = () => {
		if (!selectedHours.length || !selectedDays.length) return false;
		const today = new Date();
		const next = new Date(Date.now() + (24 * 60 * 60 * 1000));

		const sameDay = selectedDays.some(day => DateUtils.isSameDay(today, day));
		const nextWorkDay = selectedDays.some(day => {
			if (DateUtils.isDayInRange(day, { from: today, to: next })) {
				return true;
			}

			return today.getDay() === 5 && day.getDay() === 1;
		});

		if (sameDay) {
			return selectedHours.some(item => {
				const hour = item.split(":")[0];
				return hour - today.getHours() <= MIN_UTIL_HOURS;
			});
		}

		if (nextWorkDay) {
			// Must have at least {MIN_WORK_HOURS} in work days between the time the client is scheduling and the selected hours
			// eg: [14 15 |16| 17 18 19 ... 07 |08|] => 5 hours spaced, it's invalid
			return selectedHours.some(item => {
				const hour = item.split(":")[0];
				const now = clamp(today.getHours(), WORK_HOURS.start, WORK_HOURS.end);
				
				return (WORK_HOURS.end - now) + (hour - WORK_HOURS.start) <= MIN_UTIL_HOURS;
			});
		}

		return false;
	}

	const onHourClick = ({ target: { innerText } }) => {
		const selectedIndex = selectedHours.findIndex(hour => hour === innerText);
		if (selectedIndex !== -1) setSelectedHours(selectedHours.filter(item => item !== innerText));
		else setSelectedHours([...selectedHours, innerText]);
	};

	const onDayClick = (day, { selected, disabled }) => {
		if (disabled) return;

		const days = Array.from(selectedDays);
		if (selected) {
			const selectedIndex = days.findIndex(sc => DateUtils.isSameDay(sc, day));
			days.splice(selectedIndex, 1);
		} else {
			days.push(day);
		}
		setSelectedDays(days);
	};

	return { invalidHours, onHourClick, onDayClick };
};
