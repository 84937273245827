import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import axios from "axios";
import apiFilters from 'Utils/api-filters';

import useSchedule from 'Hooks/Schedule';
import useUser from 'Hooks/User';
import useClinicContext from 'Hooks/Clinic/ClinicSelect';
import useModals from 'Hooks/Modals';
import useLoading from 'Hooks/Loading';

import { Container, SelectContainer } from '../../Components/Containers';
import { RegularTitle, Info, DoctorNameType, DoctorName, Verified, Subtitle } from '../../Components/Typography';
import { InputWrapper, Checkbox, TextArea } from '../../Components/Forms';
import { ContractButton } from '../../Components/Buttons';
import Cal from '../../Components/Cal';
import Return from './Components';

const Calendar = ({ match, location }) => {
	const { justOpen, clinicData, onClinicClose } = useClinicContext();
	const {
		confirm,
		selectedHours = [],
		selectedDays = [],
		onDayClick,
		onHourClick,
		observationField,
		returnField,
		hasNoBeneficiary,
		invalidHours,
		setPrice,
		price,
		calculatePrice,
		telemedicine,
		currentPatient,
		setDoctor
	} = useSchedule(match, location);
	
	const { user } = useUser();
	const { checkModal } = useModals();
	const { loading, loaded } = useLoading();

	useEffect(() => {
		if (price != null || currentPatient == null) return;

		if (clinicData != null && clinicData.price != null) {
			setPrice({ finalPrice: clinicData.price || clinicData.finalPrice || 0, discount: clinicData.discount, speciality: clinicData._speciality });
		} else {
			(async () => {
				const { data } = await axios.get(
					`/api/data/Credentialed/find`,
					apiFilters({ term: 'code', operator: 'equals', value: Number(match.params.code) })
				);
				const doc = get(data, 'data.0');
				setDoctor(doc);

				const prices = await calculatePrice({
					credentialedId: doc._id,
				});
				setPrice(prices);
			})();
		}
	}, [clinicData, price, currentPatient]);

	useEffect(() => {
		onClinicClose();
	}, []);

	const scheduleClick = async () => {
		loading('check-terms');
		const termOpen = await checkModal('terms', { user });
		loaded('check-terms');

		if (!termOpen) {
			confirm(currentPatient._id);
		}
	};

	useEffect(() => {
		const onPopState = ({ target }) => {
			if (target.location.hash.startsWith("#/doctor/")) {
				justOpen();
			}
			window.removeEventListener('popstate', onPopState);
		}
	
		window.addEventListener('popstate', onPopState);
	}, []);

	return (
		<Container>
			<DoctorName>
				{match.params.name} {telemedicine && <Verified style={{ marginLeft: '5px' }}>Telemedicina</Verified>}
			</DoctorName>
			<DoctorNameType>{match.params.speciality || ''}</DoctorNameType>

			<RegularTitle>Solicitar agendamento</RegularTitle>
			{hasNoBeneficiary && (
				<Info>
					Você não possui um contrato ativo, favor contatar <a href="tel:08005000404">0800.500.0404</a>
				</Info>
			)}
			{!hasNoBeneficiary && (
				<>
					<SelectContainer style={{ marginTop: '24px' }}>
						<Subtitle>Paciente: {get(currentPatient, 'name.full', get(user, "patient.name.full", user.patient?.name))}</Subtitle>
					</SelectContainer>

					<Info>
						{'Você poderá escolher 3 opções para efetuar seu agendamento.'}
						<br />
						{'Quais as datas de sua preferência?'}
					</Info>

					<Cal {...{ selectedDays, selectedHours, onHourClick, onDayClick, invalidHours }} />

					<Return>
						<span style={{ marginLeft: '2.5rem' }}>Reconsulta (entrega de exames)</span>
						<Checkbox {...returnField} data-direction="left" />
					</Return>
					<InputWrapper style={{ flexDirection: 'column', alignItems: 'baseline' }}>
						<span>Observação</span>
						<TextArea rows="3" {...observationField} />
					</InputWrapper>

					{!get(user, 'term.accepted') && (
						<p style={{ marginTop: '10px' }}>Você precisa aceitar os termos de serviço para marcar uma consulta.</p>
					)}
					<ContractButton
						onClick={scheduleClick}
						disabled={!selectedDays.length || !selectedHours.length || invalidHours()}
						style={{ marginTop: '5px' }}
					>
						Solicitar agendamento
					</ContractButton>
				</>
			)}
		</Container>
	);
};

Calendar.propTypes = {
	match: PropTypes.object,
};

export default Calendar;
