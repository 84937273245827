import React from 'react';
import styled from 'styled-components';

export const ContainerRoot = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: 'center';
`;

export const ContainerCenter = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: auto;
	width: 100%;
`;

export const ThemableContainer = styled.div`
	padding-top: 4rem;
	min-height: 100%;
	box-sizing: border-box;
	width: 100%;
	${(props) => (props.primaryBg ? `background: ${props.theme.primaryGradient};` : '')}
	padding-bottom: constant(--safe-area-inset-bottom);
	padding-bottom: env(--safe-area-inset-bottom);

	.content {
		padding: 0 16px 16px;
		margin: 0 auto;
		color: ${(props) => (props.primaryBg ? 'white' : props.theme.primary)};
		max-width: 520px;
		display: flex;
		flex-direction: column;
		align-items: left;
		height: auto;
	}
`;

export const Container = (props) => (
	<ThemableContainer {...props}>
		<div className={`${props.noPadding ? '' : 'content'} ${props.primaryBg ? 'invert-colors' : ''}`}>{props.children}</div>
	</ThemableContainer>
);

export const Date = styled.div`
	display: flex;
	flex-direction: row;
	grid-area: date;
	height: 19px;
`;

export const Time = styled.div`
	display: flex;
	flex-direction: row;
	grid-area: time;
	height: 19px;
`;

export const TabContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: left;
	width: 100%;
	margin-bottom: 1.75rem;
`;

export const SelectContainer = styled.div`
	display: grid;
	transition: visibility 1s linear;
	align-self: center;
	width: 100%;
	grid-template-areas:
		'label'
		'select-exam'
		'label_default'
		'select-place'
		'search-button';
`;

export const BoxAvus = styled.div`
	display: grid;
	align-self: center;
	width: 100%;
	margin-top: 109px;
	margin-bottom: 19px;
`;

export const Comparative = styled.div`
	overflow: hidden;
	display: grid;
	width: auto;
	grid-template-rows: 40px 28px 15px auto;
	grid-template-columns: 138px 1fr;
	grid-template-areas:
		'media range'
		'type value'
		'detail detail'
		'more-info more-info';
	padding: 22px 22px;
	background-color: #4973af;
	overflow: hidden;
`;

export const AvatarContainer = styled.div`
	overflow: hidden;
	max-height: 180px;
	width: 100%;
	max-width: 45vw;
`;

export const InfoContainer = styled.div`
	padding: 8px 0px 8px 21px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
`;

export const ContainerTech = styled.div`
	margin-bottom: 30px;
`;

export const ContainerScholar = styled(ContainerTech)`
	margin-top: 16px;
	margin-bottom: 30px;
`;

export const ContainerMap = styled.div`
	height: 154px;
	margin-bottom: 24px;
	margin-bottom: 24px;
`;

export const AlertContainer = styled.div`
	color: #fff;
	background-color: ${({ theme }) => theme.violetRed};
	padding: 1rem 0.5rem;
	margin-bottom: 1rem;
	font-weight: 700;
`;

export const OverlayContainer = styled(ThemableContainer)`
	position: absolute;
	background: #fff;
	left: 0;
	padding: 1rem;
	width: 100vw;
	box-sizing: border-box;

	margin-top: 0;

	@keyframes overlayIn {
		0% {
			transform: translateY(100%);
		}
		100% {
			transform: translateY(0);
		}
	}

	@keyframes overlayOut {
		0% {
			transform: translateY(0);
		}
		100% {
			transform: translateY(100%);
		}
	}

	&[data-closed='true'] {
		animation: overlayOut 0.3s 1;
	}
	&[data-closed='false'] + #results {
		display: none !important;
	}
`;

export const AbosoluteBottom = styled.section`
  margin-top: 30px;
  p {
    font-size: 10pt;
    text-align: center;
  }

  button {
    margin-top: 5px;
    width: 100%;
  }
`;