import React from 'react';
import styled from 'styled-components';

const VoltarSpan = styled.span`
	color: ${({ theme }) => theme.violetRed};
	text-decoration: underline;
	cursor: pointer;
	font-size: 14px;
`;

const AngleLeft = styled.img`
	transform: rotate(85deg);
	width: 12px;
`;

const click = () => {
	window.location.href = `/#/appointments`;
};

const Voltar = () => (
	<div>
		<AngleLeft src="/img/angle-down-solid-red.svg" alt="<" />
		&nbsp;
		<VoltarSpan onClick={click}>Voltar para lista</VoltarSpan>
	</div>
);

export default Voltar;
