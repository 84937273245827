import styled from 'styled-components';

export const Paragraph = styled.p`
	font-size: 20px;
	line-height: 1.5;
`;

export const BenefitsParagraph = styled(Paragraph)`
	margin-top: 14px;
	margin-bottom: 44px;
`;

export const RegularTitle = styled.h2`
	font-size: 24px;
	font-weight: bold;
	line-height: 1.2;
	color: ${({ theme }) => theme.dark};
	margin-bottom: 5px;

	.invert-colors & {
		color: #ffffffcc;
	}
`;
export const BenefitsTitle = styled.h1`
	font-size: 48px;
	font-weight: bold;
	color: ${({ theme }) => theme.mulberry};
	margin-bottom: 7px;
	line-height: 1.2;

	.invert-colors & {
		color: #ffffffcc;
	}
`;

export const BenefitsSubTitle = styled.p`
	font-size: 21px;
	margin-bottom: 91px;
	line-height: 1.5;
`;

export const BenefitsTitleCard = styled.h3`
	font-size: 22px;
	align-self: center;
	text-align: left;
	pointer-events: none;
	line-height: 1.2;
`;

export const Title = styled.h1`
	font-size: 2rem;
	font-weight: bold;
	color: ${({ theme }) => theme.frenchBlue};
	margin: 1rem 0 2rem;
	line-height: 1.2;

	.invert-colors & {
		color: #ffffffcc;
	}
`;

export const CardName = styled.h5`
	grid-area: title;
	font-size: 20px;
	font-weight: bold;
	color: #ffffff;
	line-height: 1.2;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const CardType = styled.h5`
	grid-area: subtitle;
	font-size: 16px;
	color: #ffffff;
	margin: 2px;
	line-height: 1.5;
`;

export const Info = styled.p`
	font-size: 18px;
	margin-top: 19px;
	line-height: 1.5;
`;

export const DateInfo = styled.span`
	display: flex;
	flex-direction: row;
	height: 19px;
	font-size: 16px;
	color: #ffffff;
	line-height: 1.5;
	margin-left: 9px;
`;

export const TimeInfo = styled.span`
	grid-area: time;
	height: 19px;
	font-size: 16px;
	color: #ffffff;
	line-height: 1.5;
	margin-left: 9px;
`;

export const Tab = styled.span`
	font-size: 18px;
	font-weight: bold;
	margin-right: 30px;
	margin-bottom: 20px;
	color: #4a4a4a;
	border: none;
	cursor: pointer;

	&:disabled,
	&[disabled],
	&.disabled {
		opacity: 0.5;
		pointer-events: none;
	}

	&[data-active='true'] {
		height: 21px;
		font-size: 18px;
		font-weight: bold;
		color: ${({ theme }) => theme.violetRed};
		border-bottom: 2px solid;
		margin-bottom: 15px;
	}
	&:last-child {
		margin-right: 0;
	}

	&[data-small] {
		font-size: 15px;
		margin-right: 25px;
	}

	.invert-colors & {
		color: #ffffff99;
	}
	.invert-colors &[data-active='true'] {
		color: #ffffff;
	}
`;

export const List = styled.ul`
	display: grid;
	font-size: 20px;
	list-style: disc;
	width: 100%;
	padding-left: 22px;
	margin-bottom: 42px;
`;

export const ListItem = styled.li`
	font-size: 20px;
	line-height: 2;
`;

export const CardInfo = styled.h6`
	font-size: 12px;
	margin-bottom: 9px;
	align-self: center;
	line-height: 1.2;
`;

export const Divider = styled.hr`
	width: 100%;
	margin-top: 10px;
	margin-bottom: 14px;
`;

export const Pay = styled.span`
	font-size: 11px;
	margin-bottom: 10px;
	line-height: 1.5;
`;
export const Plan = styled.span`
	font-size: 16px;
	margin-bottom: 12px;
	line-height: 1.5;
`;
export const Price = styled.h3`
	font-size: 32px;
	font-weight: bold;
	color: #1e1e1e;
	align-self: center;
	line-height: 1.2;
`;

export const BestOption = styled.span`
	width: calc(100% + 18px);
	align-items: center;
	justify-content: center;
	background-color: #80a9d8;
	margin-bottom: 8px;
	color: #fff;
	margin-top: -6px;
	text-align: center;
	padding-bottom: 5px;
	padding-top: 5px;
	font-size: 16px;
`;

export const TitleSearch = styled.h1`
	font-size: 32px;
	margin-bottom: 10px;
	font-weight: 700;
	line-height: 1.2;
`;

export const Verified = styled.div`
	display: inline;
	border: 1px solid #4973af;
	color: #4973af;
	border-radius: 18px;
	padding: 3px 10px;
	height: 16px;
	width: fit-content;
	font-size: 13px;
	vertical-align: middle;

	&[data-check] {
		display: grid;
		padding-left: 26px;
		background: url('/img/check-solid.svg') no-repeat;
		background-repeat: no-repeat;
		background-repeat: no-repeat;
		background-size: 16px;
		background-position-x: 7px;
		background-position-y: 4px;
	}
`;

export const TitleDoctor = styled(TitleSearch)`
	margin: 0.3rem 0;
	font-size: 16px;
	color: #4a4a4a;
	line-height: 1.2;

	.invert-colors & {
		color: #ffffff;
	}
`;

export const GeneralInfo = styled.div`
	font-size: 14px;
	color: #4a4a4a;
	margin: 0.3rem 0;
	line-height: 1.2;

	.invert-colors & {
		color: #ffffffcc;
	}
`;

export const Likes = styled(GeneralInfo)`
	margin-top: 16px;
`;

export const Real = styled.span`
	position: absolute;
	font-size: 10px;
	top: 10px;
	line-height: 1.5;
`;

export const PriceDoctor = styled.h3`
	grid-area: price;
	display: inline-block;
	font-size: 24px;
	margin-left: 15px;
	line-height: 1.2;
`;

export const Range = styled.h3`
	position: relative;
	grid-area: range;
	display: inline-block;
	font-size: 24px;
	margin-left: 15px;
	text-align: right;
	line-height: 1.2;

	&:after {
		content: 'R$';
		position: absolute;
		font-size: 10px;
		left: -16px;
		top: 6px;
	}
`;

export const Value = styled(Range)`
	grid-area: value;
`;

export const Media = styled.span`
	grid-area: media;
	font-size: 16px;
	line-height: 1.5;
`;

export const Type = styled.span`
	grid-area: type;
	font-size: 16px;
	line-height: 1.5;
`;

export const Detail = styled.span`
	grid-area: detail;
	font-size: 12px;
	text-align: right;
	line-height: 1.5;
`;

export const TitleDetail = styled(TitleSearch)`
	margin-bottom: 5px;
	line-height: 1.2;
`;

export const DetailInfo = styled(GeneralInfo)`
	margin-top: 0px;
	margin-bottom: 13px;

	p {
		font-size: initial;
		line-height: 1.5;
	}
`;

export const Avatar = styled.img`
	grid-area: avatar;
	justify-self: start;
`;

export const Deposition = styled.div`
	grid-area: description;
	height: 49px;
	overflow-y: hidden;
`;

export const Name = styled.h6`
	grid-area: name;
	padding-top: 10px;
	padding-left: 70px;
	font-weight: bolder;
`;

export const DoctorName = styled.h1`
	font-size: 32px;
	font-weight: 700;
`;

export const DoctorNameType = styled.p`
	font-size: 16px;
	margin-bottom: 40px;
`;

export const Disclaimer = styled.span`
	font-style: italic;
	color: #7d7d7d;

	${({ borderless }) => !borderless && `
		padding-left: 8px;
		border-left: 5px solid #d6d6d6;
		border-radius: 3px;
	`};
`

export const Subtitle = styled.h4`
	font-weight: bold;
	font-size: 18px;
`;

export const ButtonSelect = styled.select`
    background-color: rgba(167, 166, 189, 0.3);
    color: #fff;
    padding: 2px;
    font-size: 16px;
    font-weight: bold;
    border: none;
	border-radius: 4px;
`;