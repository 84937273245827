import styled from 'styled-components';

const Card = styled.div`
	display: grid;
	align-self: center;
	width: 100%;
	grid-template-areas:
		'header header header'
		'error error error'
		'new-senha-label new-senha-label new-senha-label'
		'new-senha-input new-senha-input new-senha-input'
		'senha-label senha-label senha-label'
		'senha-input senha-input senha-input'
		'entrar entrar entrar';
	grid-gap: 16px;
	margin: 8px;
	padding: 16px;
	border-radius: 4px;
`;

export default Card;
