import { useState } from 'react';

const useTabs = values => {
    if (!values instanceof Array) {
        throw new TypeError(`[useTabs] Values must be an array`);
    }

    const [tab, setTab] = useState(values[0]);
    const isTabActive = value => [].concat(value).includes(tab);
    const changeTab = value => setTab(values.includes(value) ? value : values[0]);

    const nextTab = cicle => {
        const current = values.findIndex(item => item === tab);
        const next = values[current + 1];

        if (next != null) setTab(next);
        else cicle && setTab(values[0]);
    }

    const prevTab = cicle => {
        const current = values.findIndex(item => item === tab);
        const next = values[current - 1];

        if (next != null) setTab(next);
        else cicle && setTab(values[values.length - 1]);
    }

    return { tab, isTabActive, setTab: changeTab, nextTab, prevTab };
}

export default useTabs;