import React from 'react';
import styled from 'styled-components';

const DotsLoader = ({ color }) => (
	<Wave>
		<Dot style={{ animationDelay: '-0.1s' }} color={color} />
		<Dot style={{ animationDelay: '-0.5s' }} color={color} />
		<Dot style={{ animationDelay: '-0.4s' }} color={color} />
	</Wave>
);

const Dot = styled.div`
	@keyframes bounce {
		0%,
		60%,
		100% {
			transform: translateY(0);
		}

		30% {
			transform: translateY(-15px);
		}
	}

	display: inline-block;
	width: 0.7rem;
	height: 0.65rem;
	border-radius: 50%;
	background: #000;
	margin: 0 0.3rem;
	background: ${({ theme, color }) => theme[color] || theme.violetRed};

	animation: bounce 0.5s infinite;
`;

const Wave = styled.div`
	display: flex;
	align-items: center;
	width: 100px;
	height: 30px;
	margin: 0 auto;
	margin-top: 1rem;
`;

export default DotsLoader;
