import React, { useState, useCallback } from 'react';

import { Container, SelectContainer } from 'Components/Containers';
import { RegularTitle, Info } from 'Components/Typography';
import { LinkButton } from 'Components/Buttons';
import { doCancel } from 'Hooks/Cancel/effect';
import { CadastroButton, SelectExam as Select, LabelExam } from 'Components/Forms';
import useLoading from 'Hooks/Loading';

const reasons = ['Não precisava mais', 'Imprevistos', 'Agendou em outra unidade', 'Solicitação incorreta'];

const Confirm = ({ match: { params } }) => {
	const [reason, setReason] = useState();
	const { loading, loaded } = useLoading();

	const onCancel = useCallback(async () => {
		try {
			loading('cancel');
			await doCancel({ ...params, reason });
			loaded('cancel');
		} catch (e) {
			loaded('cancel');
		}
	}, [reason]);

	return (
		<Container style={{ marginTop: '3rem' }}>
			<RegularTitle>Atenção!</RegularTitle>
			<Info>Você deseja cancelar o agendamento?</Info>

			<SelectContainer style={{ margin: '10px' }}>
				<LabelExam>Selecione o motivo</LabelExam>
				<Select onChange={({ target }) => setReason(target.value)}>
					<option></option>
					{reasons.map(item => (
						<option key={item}>{item}</option>
					))}
				</Select>
			</SelectContainer>

			<CadastroButton disabled={!reason} aria-disabled={!reason} style={{ width: '100%' }} onClick={onCancel}>
				Cancelar
			</CadastroButton>
			<LinkButton as="button" onClick={window.history.back} style={{ width: '100%', marginTop: '16px' }}>
				Voltar
			</LinkButton>
		</Container>
	);
};

export default Confirm;
