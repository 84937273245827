import { useState } from 'react';
import useField from 'Hooks/General/useField';

import get from 'lodash/get';
import size from 'lodash/size';

import axios from 'axios';
import apiFilters from 'Utils/api-filters';
import sendPasswordRequestByEmail from '../sendPasswordRequestByEmail';

const useRecoverPass = () => {
	const [submited, setSubmited] = useState(false);
	const cpfField = useField();
	const [errors, setErrors] = useState([]);

	const onSubmit = async () => {
		setSubmited(false);
		cpfField.value = cpfField.value.replace(/\D/g, '');
		const { value: cpf } = cpfField;

		try {
			if (size(cpf) === 0) throw new Error('Informe seu CPF (Somente os números)');
			const { data: response } = await axios.get(
				'/api/data/Contact/find',
				{
					...apiFilters({
						term: 'cpf',
						operator: 'equals',
						value: cpf,
					}),
					headers: { 'agent-ip': Date.now() }
				}
			);
			if (!response.success) throw response.errors;
			if (!response.data.length) throw new Error('CPF não cadastrado.');
			const {
				data: [data],
			} = response;

			const success = await sendPasswordRequestByEmail({ cpf, id: data._id, to: get(data, 'login.emails.0.address', undefined) });
			if (!success) throw new Error('Ocorreu um erro na requisição de senha!');
			setSubmited(true);
		} catch (e) {
			console.error(e);
			setErrors([{ message: e.message }]);
			setSubmited(true);
		}
	};

	return { submited, errors, hasError: errors.length > 0, onSubmit, cpfField };
};

export default useRecoverPass;
