import styled from 'styled-components';

const ErrorList = styled.ul`
	grid-area: error;
	width: 100%;
	border-radius: 4px;
	color: #ffffff;
	background: ${({ theme }) => theme.violetRed};
	padding: 4px;
	font-weight: 500;
	padding: 0.5rem 0.3rem;
  margin-bottom: 1rem;
`;

export default ErrorList;
