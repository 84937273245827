import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Time, Date } from '../../Components/Containers';
import { CardName, CardType, DateInfo } from '../../Components/Typography';
import { IconDate, IconClock } from '../../Components/Icons';
import moment from 'moment';
import pt_br from 'moment/locale/pt-br';
moment.locale('pt-br');

const CardShedule = styled.div`
	display: grid;
	grid-template-areas:
		'title title'
		'subtitle subtitle'
		'subtitle subtitle'
		'date  time'
		'status status';
	width: 100%;
	height: 'auto';
	box-sizing: 'border-box';
	border-radius: 4px;
	background-color: ${({ theme }) => theme.fadedBlue};
	margin-top: 13px;
	box-sizing: border-box;
	color: white;
	padding: 9px 18px;
	margin-bottom: 13px;
`;

const Status = styled.h3`
	display: flex;
	height: 19px;
	margin-top: 8px;
	background-color: #fff;
	border-radius: 4px;
	color: #4774b1;
	justify-content: center;
	align-items: center;
`;

const colorCard = status => {
	if (status === 'Agendada') return '#4774b1';
};

const Card = ({ doctor }) => {
	const redirectGo = () => (window.location.hash = `/appointment-detail?code=${doctor.code}`);

	return (
		<CardShedule onClick={redirectGo} style={{ backgroundColor: colorCard(doctor.status) }}>
			<CardName>{doctor.credentialed.name.full}</CardName>
			<CardType>
				{doctor.speciality.specialityName}
				<br />
				{doctor.beneficiary.boundType === 'Dependente' && <span>Paciente: {doctor.beneficiary.beneficiary.name.full}</span>}
			</CardType>

			{doctor.startAt !== undefined && (
				<>
					<Date>
						<IconDate />
						<DateInfo>{moment(doctor.startAt).format('l')}</DateInfo>
					</Date>
					<Time>
						<IconClock />
						<DateInfo>{moment(doctor.startAt).format('LT')}</DateInfo>
					</Time>
				</>
			)}

			<Status>{doctor.status}</Status>
		</CardShedule>
	);
};

Card.propTypes = {
	doctor: PropTypes.object,
};

export default Card;
