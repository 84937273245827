import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const VoltarSpan = styled.span`
	color: ${({ theme }) => theme.violetRed};
	text-decoration: underline;
	cursor: pointer;
	font-size: 14px;
`;

const AngleLeft = styled.img`
	transform: rotate(85deg);
	width: 12px;
`;

const Voltar = ({ onClick, text, style }) => (
	<div style={style}>
		<AngleLeft src="/img/angle-down-solid-red.svg" alt="<" />
		&nbsp;
		<VoltarSpan onClick={onClick}>{text}</VoltarSpan>
	</div>
);

Voltar.propTypes = {
    onClick: PropTypes.func,
    text: PropTypes.string
};

Voltar.defaultProps = {
    text: "Voltar para lista"
}

export default Voltar;
