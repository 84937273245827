import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Container } from 'Components/Containers';
import Toast from 'Components/Toast';
import Loader from 'Components/DotsLoader';
import useUser from 'Hooks/User';

const isSafari = navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome');

const config = {
	'/health-monitor': {
		authUrl: 'https://lifelab.med.br/api/auth/login',
		frame: 'https://lifelab.med.br/auth/authenticate?token=',
		authorizaton: 'Bearer Nwfju1uYXKdUDb5PbATBVRzLcUtgp9LP',

		getUrl: async function ({ user }) {
			try {
				const { name, cpf: document, email } = user;
				const { data } = await axios.post(
					this.authUrl,
					{ name, document, email },
					{ headers: { Authorization: this.authorizaton } }
				);

				if (data && data.token) {
					return `${this.frame}${data.token}`;
				}

				return null;
			} catch (e) {
				return null;
			}
		},
	},
};

const Frame = ({ match }) => {
	const [url, setUrl] = useState();
	const [error, setError] = useState();
	const [toast, setToast] = useState(false);
	const { user } = useUser();

	useEffect(() => {
		const context = config[match.path];
		if (!context) return setError(true);

		if (!user.userLoaded) return;

		context.getUrl({ user, match }).then(response => {
			if (response === null) return setError(true);
			if (url === response) return;

			setUrl(response);
			if (isSafari && (url == null || !url.endsWith('&'))) {
				setToast(true);
			}
		});
	}, [match.url, user]);

	useEffect(() => {
    if (toast === true && url) {
      document.getElementById("link").click();
    }
	}, [toast]);

	if (url != null && error !== true) {
		return (
			<Container noPadding style={{ paddingTop: 54 }}>
				<a style={{ display: 'none' }} aria-hidden="true" id="link" href={url}>
					Link
				</a>

				{!isSafari || (isSafari && url.endsWith('&')) ? (
					<iframe
						src={url}
						sandbox="allow-scripts allow-same-origin	allow-top-navigation"
						frameBorder="0"
						width="100%"
						style={{ position: 'absolute', height: 'calc(100% - 54px)' }}
					></iframe>
				) : null}
			</Container>
		);
	}

	if (error) {
		return (
			<Container>
				<p>Houve um erro ao carregar sua url</p>
			</Container>
		);
	}

	return (
		<Container>
			<Loader />
		</Container>
	);
};

export default Frame;
