import styled from 'styled-components';
import { TimeButton } from 'Components/Buttons';

const Price = styled.div`
	font-size: 20px;
	width: 45%;

	> span:first-of-type {
		color: #8a8a8a;
	}

	> span:nth-of-type(2) {
		color: #104886d1;
		font-family: Lato, Arial, Helvetica, sans-serif;
		font-size: larger;
		font-weight: 700;

		> span {
			font-size: 12px;
			position: absolute;
			margin-left: 0px;
			margin-top: 3px;
		}
	}
`;

const Profile = styled(TimeButton)`
	width: 50%;
	font-size: initial;
	white-space: nowrap;

	&[disabled='true'] {
		opacity: 0.7;
		pointer-events: none;
	}
`;

// eslint-disable-next-line import/prefer-default-export
export { Price, Profile };
