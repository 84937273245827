import axios from 'axios';
import get from 'lodash/get';
import pick from 'lodash/pick';
import React, { useCallback, useMemo, useState } from 'react';

import Toast from 'Components/Toast';
import { SearchButton } from '../../Components/Buttons';
import { Container, SelectContainer } from '../../Components/Containers';
import { LabelExam, SelectExam } from '../../Components/Forms';

import useBoolean from '../../Hooks/General/useBool';
import useField from '../../Hooks/General/useField';
import useUser from '../../Hooks/User';

import { Dependent } from '../Register/Components';

// http://localhost:3000/api/v2/search/Clínico Geral?telemedicine=true&planId=X7umXJSAAtscTEBpP&beneficiaryId=8wvKAw7gLzvAJgfMs

const getSubsidy = async (planData, beneficiary) => {
	const asMoney = value => (value != null ? { currency: 'BRL', value } : null);

	if (beneficiary != null) {
		const { data } = await axios.get(
			`/api/v2/search/Plant%C3%A3o24h?telemedicine=true&planId=${beneficiary.contractPlan._id}&beneficiaryId=${beneficiary._id}`
		);
		if (data.success === true) {
			const entry = data.data[0];
			return {
				isDutyFree: entry.finalPrice === 0 ? true : false,
				subsidy: asMoney(entry.discount),
				price: asMoney(entry.finalPrice),
				credentialed: pick(entry, ['_id', 'code']),
				speciality: pick(entry.speciality, ['_id', 'code']),
			};
		}
		return { error: data.error };
	}
	return {
		isDutyFree: null,
		subsidy: asMoney(0),
	};
};

const Duty = () => {
	const { user } = useUser();
	const { value: client, onChange: onClientChange } = useField(get(user, 'beneficiary._id'));
	const [toast, setToast] = useState({ isOpen: false, message: '', variant: 'info' });
	const { isTrue: isLoading, toggle: toggleLoading } = useBoolean();

	const getDutyUrl = useCallback(
		async (appointmentId, beneficiary, discounts) => {
			if (user == null) return;

			const payload = {
				isDutyFree: discounts.isDutyFree, // get(user, 'planData.isDutyFree'),
				subsidy: discounts.subsidy.value, // get(user, 'planData.subsidyTele.value', 0),
				name: beneficiary.beneficiary.name,
				cpf: user.cpf,
				appointmentId,
				contactId: get(beneficiary, 'beneficiary._id'),
			};

			try {
				const { data } = await axios.get('/api/v2/fetchDuty24hs', { params: payload });

				if (/https:\/\/.+?\.com/.test(data)) {
					return data;
				}

				return null;
			} catch (e) {
				console.error(e);
				return null;
			}
		},
		[user]
	);

	const createAppointmentFor = useCallback(
		async (beneficiary, discounts) => {
			const payload = {
				speciality: discounts.speciality,
				credentialed: discounts.credentialed,
				beneficiary: { _id: beneficiary._id },
				subsidyType: user.planData.subsidyType,
				planData: user.planData,
				status: 'Aberta',
				startAt: { $date: new Date().toISOString() },
				telemedicine: true,
				subsidy: discounts.subsidy,
				appointmentPrice: discounts.price,
				isFree: discounts.isDutyFree,
			};

			try {
				const { data } = await axios.post('/api/data/Appointment', payload);
				if (data.success) {
					return data.data[0]._id;
				}

				console.error(data.errors);
				return null;
			} catch (e) {
				console.error(e);
				return null;
			}
		},
		[user]
	);

	const checkIsAllowedDuty = useCallback(patient => {
		if (patient.status !== "Ativo") return "Seu plano não esta ativo";
	}, [user]);

	const onDutyClick = useCallback(async () => {
		toggleLoading();
		const beneficiary = [user.beneficiary].concat(user.dependents || []).find(usr => usr._id === client) || user.beneficiary;

		const checkResult = checkIsAllowedDuty(beneficiary);
		if (checkResult != null) {
			toggleLoading();
			setToast({ isOpen: true, message: checkResult, variant: 'error' });
			return;
		}

		const discounts = await getSubsidy(get(user, 'planData', null), beneficiary);
		if (discounts.error != null) {
			toggleLoading();
			setToast({ isOpen: true, message: discounts.error, variant: 'error' });
			setTimeout(() => {
				window.location.hash = '/';
			}, 5e3);
			return;
		}

		const appointmentId = await createAppointmentFor(beneficiary, discounts);
		if (appointmentId == null) {
			toggleLoading();
			setToast({ isOpen: true, message: 'Houve um erro ao criar sua consulta!', variant: 'error' });
			return;
		}

		const dutyUrl = await getDutyUrl(appointmentId, beneficiary, discounts);
		toggleLoading();

		if (dutyUrl != null) {
			window.open(dutyUrl);
			window.location.hash = '/';
			return;
		}

		setToast({ isOpen: true, message: 'Houve um erro ao abrir seu plantão! Tente revisar seus dados.', variant: 'error' });
	}, [createAppointmentFor, getDutyUrl, client, user]);

	const closeToast = useCallback(() => setToast({ isOpen: false }), []);

	const registerLink = useMemo(() => {
		if (client == null || client === user.beneficiary._id) return '/register';

		const dependentIndex = user.dependents.findIndex(dep => dep._id === client);
		if (dependentIndex === -1) return '/register';

		return `/register/dependent-${dependentIndex + 1}`;
	}, [client, user]);

	return (
		<Container>
			<SelectContainer style={{ marginTop: '24px' }}>
				<LabelExam htmlFor="client">Paciente (Titular ou Dependente)</LabelExam>
				<SelectExam id="client" name="client" onChange={onClientChange} value={client} defaultValue={get(user, 'beneficiary._id')}>
					<option value={get(user, 'beneficiary._id')} key="default">
						{user.name}
					</option>

					{(user.dependents || [])
						.filter(e => e.status === 'Ativo')
						.map(dependent => (
							<option value={dependent._id} key={dependent._id}>
								{get(dependent, 'beneficiary.name.full', '')}
							</option>
						))}
				</SelectExam>

				<SearchButton disabled={isLoading} onClick={onDutyClick} keepOpacityOnDisable>
					{isLoading ? (
						<img src="/img/loading.svg" alt="Loading" width="40" height="35" style={{ verticalAlign: 'bottom' }} />
					) : (
						'Abrir plantão'
					)}
				</SearchButton>
			</SelectContainer>

			<div style={{ marginTop: '3rem' }}>
				<Dependent to={registerLink}>
					<span className="primary">Certifique-se de preencher os dados do paciente como telefone, email e endereço.</span>
					<img src="/img/right.svg" width="10px" style={{ filter: 'brightness(0.7)' }} alt="" />
				</Dependent>
			</div>

			<Toast text={toast.message} isOpen={toast.isOpen} onClose={closeToast} variant={toast.variant} duration={5e3} />
		</Container>
	);
};

export default Duty;
