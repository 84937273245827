import React from 'react';
import axios from 'axios';

import Popup, { ClosePopup, PopupHeader, PopupActions, PopupContent } from 'Components/Popup';
import { EntrarButton, CadastroButton } from 'Views/Login/Form';

const Terms = ({ close, user = {} }) => {
  const { newTerm = {} } = user.term;

	const acceptTerm = () => {
		axios.post('/api/v2/acceptTerms', {
			terms: newTerm._id,
			beneficiary: user.beneficiary._id,
		});
		close();
  };

	return (
		<Popup>
			<PopupHeader>
				<h4>Termos de uso</h4>
				<ClosePopup onClick={close} />
			</PopupHeader>
			<PopupContent data-bg="true">
				<div style={{ maxHeight: 'calc(80vh - 140px)', overflow: 'auto', minHeight: 'calc(50vh)', paddingTop: '5px' }}>
					<div dangerouslySetInnerHTML={{ __html: newTerm.html }} />
				</div>
			</PopupContent>
			{newTerm.link && (
				<div style={{ padding: "15px 10px" }}>
					Ou acesse{' '}
					<a href={newTerm.link} target="_blank" rel="noopener license alternate">
						nesse link
					</a>
				</div>
			)}
			<PopupActions>
				<CadastroButton onClick={close}>Agora não</CadastroButton>
				<EntrarButton onClick={acceptTerm} type="submit">
					Aceito os termos
				</EntrarButton>
			</PopupActions>
		</Popup>
	);
};

export const checkTerms = async ({ user }) => {
  const { data: response } = await axios.get(`/api/v2/checkTerms?beneficiary=${user.beneficiary._id}`);

  return !response.success;
}

export default Terms;
