import styled from 'styled-components';

export const Page = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
	grid-gap: 1px;
	background-color: #bababa;
	border: 1px solid #046daa;
	height: 230px;
	text-align: center;
	margin-bottom: 43px;
`;

export const TimeContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr 1fr;
	grid-gap: 12px 29px;
	margin-top: 26px;
`;

// export const Time = styled.button`
// 	display: inherit;
// 	text-align: center;
// 	width: 100%;
// 	color: #046daa;
// 	border: 1px solid #979797;
// 	border-radius: 4px;
// 	background-color: #fff;
// 	font-size: 16px;

// 	&[data-selected='true'] {
		// background-color: ${({ theme }) => theme.violetRed};
		// color: #fff;
		// border: none;
// 	}
// `;
export const Time = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;

	text-align: center;
	background-color: #fff;
	font-size: 16px;

	> button {
		background: #fff;
		border: 1px solid ${({ theme }) => theme.violetRed};
		color: ${({ theme }) => theme.violetRed};
		padding: 7px;
		outline: none;

		&:first-of-type {
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
			border-bottom: 0;
		}

		&:last-of-type {
			border-bottom-left-radius: 4px;
			border-bottom-right-radius: 4px;
		}
	}

	> button[data-selected=true] + button[data-selected=true] {
		border-top: 1px solid #124e71;
	}

	&> button[data-selected=true] {
		background-color: ${({ theme }) => theme.violetRed};
		color: #fff;
	}
`;

export const MonthContainer = styled.div`
	display: grid;
	width: 100%;
	height: 24px;
	margin-bottom: 22px;
	grid-template-columns: 1fr 2fr 1fr;
	text-align: center;
	margin-top: 37px;
`;

export const Left = styled.button`
	width: 100%;
	background: url(/img/left.svg);
	background-repeat: no-repeat;
	background-size: 16px;
	border: 0;
	background-position-x: 100%;
`;

export const Right = styled.button`
	width: 100%;
	background: url(/img/right.svg);
	background-repeat: no-repeat;
	background-size: 16px;
	border: 0;
`;

export const Month = styled.div`
	width: 100%;
	font-size: 22px;
`;
