import styled from 'styled-components';

const Back = styled.div`
	grid-area: back;
	background: url('/img/left_w.svg');
	background-size: 25%;
	background-position: center;
	background-repeat: no-repeat;
	display: block;
	height: 32px;
	width: 32px;
	align-self: center;
	justify-self: left;
	margin-left: 16px;
	color: white;
	&:hover {
		opacity: 0.5;
	}

	/*temporary*/
	opacity: 0 !important;
`;

export default Back;
