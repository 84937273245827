import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import get from 'lodash/get';
import size from 'lodash/size';
import map from 'lodash/map';

import useUser from 'Hooks/User';

import DotLoader from 'Components/DotsLoader';
import { Container } from 'Components/Containers';
import { RegularTitle, Divider, Paragraph } from 'Components/Typography';
import { Plan, PlanDescription, PlanActions } from './Components';
import { SubmitButton } from '../Register/Components';

const ContactPlans = () => {
	const [plans, setPlans] = useState([]);
	const [open, setOpen] = useState();
	const [loading, setLoading] = useState(true);
	const { user } = useUser();

	const history = useHistory();

	const loadPlans = async () => {
		const { data } = await axios.get(`/api/v2/getContractPlans/${get(user, 'beneficiary.contract._id')}`);
		return data;
	};

	useEffect(() => {
		if (get(user, 'beneficiary') && !size(plans)) {
			loadPlans()
				.then(setPlans)
				.finally(() => setLoading(false));
		}
	}, [user]);

	const onPlanClick = code => setOpen(t => (t === code ? null : code));
	const isOpen = code => open === code;
	const onSelect = plan => history.push(`/payment?plan=${plan}`);

	return (
		<Container>
			<RegularTitle>Planos Disponíveis</RegularTitle>
			{loading && <DotLoader />}
			{!loading && !size(plans) && <p>Nenhum plano disponível.</p>}

			{map(plans, pln => (
				<Plan onClick={() => onPlanClick(pln.code)} key={pln.code}>
					<div style={{ fontWeight: 600 }}>{pln.promoteTitle || pln.name}</div>
					<div>
						<span dangerouslySetInnerHTML={{ __html: pln.promoteValue }} />
					</div>
					<PlanDescription data-active={isOpen(pln.code)}>
						<div dangerouslySetInnerHTML={{ __html: pln.promoteDescription }} style={{ marginBottom: '10px' }} />
						<PlanActions>
							<SubmitButton onClick={() => onSelect(pln._id)}>Selecionar</SubmitButton>
						</PlanActions>
					</PlanDescription>
				</Plan>
			))}

			<Divider />
			<br />
			<Paragraph>
				Para outras opções, entre em contato com o atendimento da Avus através do <a href="tel:08005000404">0800.500.0404</a> ou
				pelo{' '}
				<a target="_blank" href="https://wa.me/5508005000404">
					WhatsApp
				</a>{' '}
				(0800.500.0404)
			</Paragraph>
		</Container>
	);
};

export default ContactPlans;
