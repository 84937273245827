import React from 'react';
import PropTypes from 'prop-types';
import { Overlay, PopupContainer } from './Overlay';
import Close from './Close';
import styled from 'styled-components';

const Popup = ({ children, hidden }) => (
	<Overlay hidden={hidden}>
		<PopupContainer>{children}</PopupContainer>
	</Overlay>
);

const ClosePopup = (props) => <Close {...props}>&times;</Close>;

const PopupHeader = styled.div`
	display: flex;
	padding: 16px;
	justify-content: space-between;
	align-items: center;

	& > h4 {
		font-weight: 600;
	}
`;
const PopupActions = styled.div`
	display: flex;
	padding: 12px 16px;
	justify-content: center;
	align-items: center;

	& > button {
		font-size: 16px;
		white-space: nowrap;
		width: auto;
		padding: 4px 8px;
		margin: 0 4px;
	}
`;
const PopupContent = styled.div`
	padding: 0 16px;
	flex: 1 0 auto;
	font-size: 12px;
    color: rgb(90, 90, 90);
    height: 100%;

    &[data-bg=true] {
        background-color: rgba(226, 226, 226, 0.5);
    }

	& p {
		margin-bottom: 16px;
		line-height: 1.4;
	}

	& a {
		color: ${({ theme }) => theme.violetRed};
	}

    & b {
        font-weight: 600;
    }

	& h1,
	& h2,
	& h3 {
		margin-top: 24px;
		margin-bottom: 16px;
		font-weight: 700;
	}
	& h1 {
		font-size: 16px;
	}
	& h2 {
		font-size: 14px;
	}
	& h3 {
		font-size: 12px;
	}
`;

Popup.propTypes = {
	children: PropTypes.node,
	hidden: PropTypes.bool,
};

export { ClosePopup, PopupHeader, PopupActions, PopupContent };
export default Popup;
