import React, { useState } from 'react';
import get from 'lodash/get';

import { RegularTitle } from '../../Components/Typography';
import { ContainerTech } from '../../Components/Containers';
import { Control, Input, SubmitButton } from './Components';

const Contato = ({ data, onSave = () => {} }) => {
	const contact = get(data, 'beneficiary', {});

	const [mask, setMask] = useState("(99) 99999-9999");
	const [values, setValues] = useState({
		email: get(contact, 'email.0.address', ''),
		phone: get(contact, 'phone.0.phoneNumber', ''),
		extraPhone: get(contact, 'phone.1.phoneNumber', ''),
	});

	const onChange = (name) => ({ target: { value } }) => setValues((v) => ({ ...v, [name]: value }));
	const beforeMaskChange = newState => {
		const value = (newState.value || "").replace(/\D/g, '');

		if (value.charAt("2") == "9") {
			setMask("(99) 99999-9999");
		} else {
			setMask("(99) 9999-9999");
		}
		return newState;
	}

	return (
		<>
			<ContainerTech>
				<RegularTitle>Dados do Contato</RegularTitle>
				<Control>
					<span>Email</span>
					<Input type="email" defaultValue={values.email} onChange={onChange('email')} autoComplete="off" />
				</Control>

				<Control data-required={data.boundType === "Titular"}>
					<span>Telefone Celular</span>
					<Input type="text" mask="(99) 99999-9999" defaultValue={values.phone} onChange={onChange('phone')} autoComplete="off" />
				</Control>

				<Control>
					<span>Telefone Adicional</span>
					<Input
						type="text"
						mask={mask}
						defaultValue={values.extraPhone}
						onChange={onChange('extraPhone')}
						beforeMaskedValueChange={beforeMaskChange}
						autoComplete="off"
					/>
				</Control>

				<SubmitButton style={{ marginTop: 16 }} onClick={() => onSave('contact', values)}>
					Salvar Alterações
				</SubmitButton>
			</ContainerTech>
		</>
	);
};

export default Contato;
