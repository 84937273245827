import React from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';

import map from 'lodash/map';
import range from 'lodash/range';
import { TimeContainer, Time } from './Cal';
import { Disclaimer } from '../../Components/Typography';

import './style.scss';

/* eslint-disable no-use-before-define */
const Cal = ({ selectedDays, onDayClick, onHourClick, selectedHours, invalidHours }) => (
	<>
		<DayPicker
			onDayClick={onDayClick}
			selectedDays={selectedDays}
			modifiers={{ disabled: disabledDays }}
			months={MONTHS}
			weekdaysLong={WEEKDAYS_LONG}
			weekdaysShort={WEEKDAYS_SHORT}
		/>

		<p>Quais os horários de sua preferência?</p>
		{invalidHours() && (
			<Disclaimer borderless>Precisamos de no mínino 6 horas úteis para realizar o seu agendamento.</Disclaimer>
		)}

		<TimeContainer>
			{map(range(7, 19), hour => (
				<Time key={`${hour}:00`}>
					<button data-selected={selectedHours.includes(`${hour}:00`)} onClick={onHourClick} role="button">
						{`${hour}:00`}
					</button>
					<button data-selected={selectedHours.includes(`${hour}:30`)} onClick={onHourClick} role="button">
						{`${hour}:30`}
					</button>
				</Time>
			))}
		</TimeContainer>
	</>
);

const today = new Date();
const disabledDays = day => {
	day.setHours(23, 59);

	// Past days
	if (day < today) {
		return true;
	}

	// Less than 6 work hours in the same day
	if (DateUtils.isSameDay(day, today) && today.getHours() >= 12) {
		return true;
	}

	// Weekends
	return [0, 6].includes(day.getDay());
};

const MONTHS = [
	'Janeiro',
	'Fevereiro',
	'Março',
	'Abril',
	'Maio',
	'Junho',
	'Julho',
	'Agosto',
	'Setembro',
	'Outubro',
	'Novembro',
	'Dezmbro',
];

const WEEKDAYS_LONG = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feia', 'Sábado'];
const WEEKDAYS_SHORT = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];

export default Cal;
