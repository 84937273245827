import React, { useState, useEffect } from 'react';
import axios from 'axios';
import get from 'lodash/get';

import { RegularTitle } from '../../Components/Typography';
import { ContainerTech } from '../../Components/Containers';
import { Control, Input, SubmitButton } from './Components';

const Endereco = ({ data, onSave = () => {} }) => {
	const address = get(data, 'beneficiary.address.0', {});
	const isHolder = data.boundType === 'Titular';

	const [values, setValues] = useState({
		cep: get(address, 'postalCode', ''),
		place: get(address, 'placeType', '') + ' ' + get(address, 'place', ''),
		number: get(address, 'number', ''),
		complement: get(address, 'complement', ''),
		district: get(address, 'district', ''),
		city: get(address, 'city', ''),
		state: get(address, 'state', ''),
		isHolder,
	});

	const onChange = name => ({ target: { value } }) => setValues(v => ({ ...v, [name]: value }));
	const searchCep = async () => {
		const { data = [] } = await axios.get(`/api/v2/cepQuery/${values.cep}`);
		const [res] = data;
		if (!res) return;

		setValues(v => ({
			...v,
			place: `${res.placeType} ${res.place}`,
			district: res.district,
			city: res.city,
			state: res.state,
			placeType: res.placeType,
			isHolder,
		}));
	};

	return (
		<>
			<ContainerTech>
				<RegularTitle>Dados de Endereço</RegularTitle>
				<Control data-required={isHolder}>
					<span>CEP</span>
					<Input type="text" value={values.cep} onChange={onChange('cep')} onBlur={searchCep} autoComplete="off" />
				</Control>

				<Control data-required={isHolder}>
					<span>Endereço</span>
					<Input type="text" value={values.place} onChange={onChange('place')} autoComplete="off" />
				</Control>

				<Control data-required={isHolder}>
					<span>Número</span>
					<Input type="text" value={values.number} onChange={onChange('number')} autoComplete="off" />
				</Control>

				<Control>
					<span>Complemento</span>
					<Input type="text" value={values.complement} onChange={onChange('complement')} autoComplete="off" />
				</Control>

				<Control data-required={isHolder}>
					<span>Bairro</span>
					<Input type="text" value={values.district} onChange={onChange('district')} autoComplete="off" />
				</Control>

				<Control data-required={isHolder}>
					<span>Cidade</span>
					<Input type="text" value={values.city} onChange={onChange('city')} autoComplete="off" />
				</Control>

				<Control data-required={isHolder}>
					<span>Estado</span>
					<Input type="text" value={values.state} onChange={onChange('state')} autoComplete="off" />
				</Control>
				<SubmitButton style={{ marginTop: 16 }} onClick={() => onSave('address', values)}>
					Salvar Alterações
				</SubmitButton>
			</ContainerTech>
		</>
	);
};

export default Endereco;
