import React from 'react';
import styled from 'styled-components';
import { Avatar, Deposition, Name } from '../../Components/Typography';
import { DepositionsCard } from '../../Components/Cards';
import { Container as OutroContainer } from '../../Components/Containers';

export const CardContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	width: 100%;
	height: 298px;
	margin-top: 54px;
`;

export const OptionHeader = styled.div`
	display: flex;
	flex-direction: column;
	width: 102px;
`;

export const Container = styled(OutroContainer)`
	& > div[data-open='false'] {
		& + p,
		& + ul {
			display: none;
			& + p,
			& + ul {
				display: none;
			}
		}
	}
`;

const data = {
	depositionsCard: [
		{
			name: ['Leonardo', 'Eduardo', 'Derotino', 'Vinícius'][Math.floor((Math.random() * 10) % 4)],
			img: `https://randomuser.me/api/portraits/thumb/men/${Math.floor(Math.random() * 60)}.jpg`,
			deposition:
				'Quando cheguei no consultório do dr Drummond já não sabia mais o que fazer! Tinha passado por 3 médicos que só receitaram um monte de',
		},
	],
};

export const ListDepositons = Array.isArray(data.depositionsCard)
	? data.depositionsCard.map((entry) => (
			<DepositionsCard key={entry.name}>
				<Avatar src={entry.img} alt={entry.name} />
				<Deposition>
					{entry.deposition.slice(0, 95)}
					&nbsp;
					{entry.deposition.length > 95 ? '...' : ''}
				</Deposition>
				<Name>- {entry.name}</Name>
			</DepositionsCard>
	  ))
	: 'erro em depositionsCard';
