import styled from 'styled-components';

export const Overlay = styled.div`
	position: fixed;

	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 300;

	background: rgba(0, 0, 0, 0.3);

	display: flex;
	align-items: center;
	justify-content: center;

	&[hidden] {
		display: none;
	}
`;

export const ModalContainer = styled.div`
	position: fixed;
	background: #fff;

	width: 80vw;
	height: 70vw;

	max-width: 400px;
	max-height: 450px;
`;
