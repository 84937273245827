import React from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
	position: fixed;
	display: flex;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;

	justify-content: center;
  align-items: center;
	z-index: 1000;
	background: rgba(0, 0, 0, 0.2);

  & img {
    max-width: 100px;
    max-height: 100px
  }

	&[hidden] {
		display: none;
	}
`;

const SpinnerLoader = props => (
	<Overlay {...props}>
		<img src="/img/loading.svg" alt="Loading" />
	</Overlay>
);

export default SpinnerLoader;
