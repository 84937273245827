import head from 'lodash/head';

import axios from 'axios';
import apiFilters from 'Utils/api-filters';
import formatDoctor from 'Utils/formatDoctor';

/* eslint-disable import/prefer-default-export */
export const getInitialData = async () => {
	try {
		if (window.history.state) return window.history.state;

		const [, , name, place] = window.location.hash.split('/');
		const { data: response } = await axios.get(
			'/api/data/Credentialed/find',
			apiFilters(
				{
					term: 'name.full',
					operator: 'contains',
					value: decodeURI(name),
				},
				{
					term: 'address.place',
					operator: 'equals',
					value: decodeURI(place),
				},
				{
					term: 'status',
					operator: 'equals',
					value: 'Ativo',
				}
			)
		);
		if (!response.success) throw response.errors;
		if (!response.data.length) throw new Error('Credenciado não existe.');

		const credentialed = head(response.data);

		return formatDoctor(credentialed);
	} catch (e) {
		console.error(e);
		return {};
	}
};
