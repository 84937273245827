import { useState, useEffect } from 'react';
import useField, { useCheckbox } from 'Hooks/General/useField';
import useUser from 'Hooks/User';
import useLoading from 'Hooks/Loading';

import axios from 'axios';
import apiFilters from 'Utils/api-filters';
import get from 'lodash/get';

import createAppointment from './createAppointment';
import updateAppointment from './updateAppointment';
import calendarFunctions from './calendar';

const useSchedule = ({ params: { name: clinicName, code, speciality, telemedicine } }, { search }) => {
	const [selectedDays, setSelectedDays] = useState([]);
	const [selectedHours, setSelectedHours] = useState([]);
	const [existingAppointment, setExistingAppointment] = useState(null);
	const [currentPatient, setCurrentPatient] = useState(null);
	const [doctor, setDoctor] = useState(null);
	const [price, setPrice] = useState(null);
	const returnField = useCheckbox(false);
	const observationField = useField(``);

	const { loading, loaded } = useLoading();
	const { user } = useUser();
	const { invalidHours, onDayClick, onHourClick } = calendarFunctions({
		selectedDays,
		setSelectedDays,
		selectedHours,
		setSelectedHours,
	});

	useEffect(() => {
		window.scrollTo(0, 0);

		// If query has updateId, find the appointment
		(async () => {
			const existingId = get(search.match(/updateID=(.+?(?=$|&))/), '1');
			if (!existingId) return;

			const { data: appo } = await axios.get(
				'/api/data/Appointment/find',
				apiFilters({ term: '_id', operator: 'equals', value: existingId })
			);
			const existing = appo.data[0];
			setExistingAppointment(existing);

			if (existing) {
				// Get 'Reconsulta' from existing appointment
				setCurrentPatient(existing.beneficiary);
				returnField.setValue(existing.returnAppointment === 'Sim');
	
				// Get client observation from existing appointment
				const clientObs = existing.appointmentNotes.match(/Observação do cliente: (.+)/);
				observationField.setValue(get(clientObs, '1', ''));
			}
		})();
	}, []);

	useEffect(() => {
		if (user.patient != null && user.patient._id && currentPatient == null) {
			setCurrentPatient(user.patient);
		}
	}, [user.patient]);

	const calculatePrice = async (params = {}) => {
		if (params.credentialedId == null) return null;

		const { data } = await axios.get(`/api/v2/search/${speciality}`, {
			params: {
				planId: get(user, 'beneficiary.contractPlan._id'),
				beneficiaryId: get(currentPatient, '_id') || params.beneficiaryId,
				telemedicine: !!telemedicine,
				...params,
			},
		});

		if (data.success) {
			return data.data[0];
		}

		return null;
	};

	const confirm = async entry => {
		const beneficiary = user.patient || [].concat(user.beneficiary, user.dependents).find(item => item._id === entry);

		loading('schedule');
		try {
			const [{ data: contract }] = await Promise.all([
				axios.get('/api/data/Contract/find', apiFilters({ term: '_id', operator: 'equals', value: user.beneficiary.contract._id })),
			]);

			const { finalPrice, discount, originalPrice, speciality: spec } = price;
			const { appointmentPaymentsDependent, appointmentPaymentsHolder } = contract.data[0];

			if (existingAppointment) {
				const { data: responseUpdate } = await updateAppointment({
					existingAppointment,
					returnField,
					observationField,
					selectedDays,
					selectedHours,
				});

				if (responseUpdate.success) {
					window.location.hash = 'confirm';
				}
				loaded('schedule');
				return;
			}

			let doc = doctor;
			if (doctor == null) {
				const { data } = await axios.get(
					`/api/data/Credentialed/find`,
					apiFilters({ term: 'code', operator: 'equals', value: Number(code) })
				);
				doc = get(data, 'data.0');
			}

			const { data: response } = await createAppointment({
				appointmentPaymentsDependent,
				appointmentPaymentsHolder,
				spec: spec || get(price, 'lookupSpecialities.0'),
				finalPrice,
				discount,
				originalPrice,
				user,
				beneficiary,
				observationField,
				returnField,
				selectedDays,
				selectedHours,
				credentialed: doc,
				telemedicine,
			});

			if (response.success) {
				window.scrollTo(0, 0);
				window.location.hash = 'confirm';
			}

			loaded('schedule');
		} catch (e) {
			console.error(e);
			loaded('schedule');
		}
	};

	return {
		confirm,
		selectedHours,
		selectedDays,
		onDayClick,
		onHourClick,
		observationField,
		returnField,
		hasNoBeneficiary: user.hasNoBeneficiary,
		existingAppointment,
		invalidHours,
		setPrice,
		calculatePrice,
		price,
		telemedicine,
		currentPatient,
		setDoctor
	};
};

export default useSchedule;
