import React from 'react';
import get from 'lodash/get';

import { ContainerTech } from '../../Components/Containers';
import { RegularTitle } from '../../Components/Typography';
import { LinkButton } from '../../Components/Buttons';
import { Control, Dependent, PrimaryColor, SubmitButton } from './Components';

const Dependentes = ({ data, maxDependents = 0 }) => {
	const dependents = get(data, 'dependents', []);
	const missingDependents = Math.max(0, maxDependents - dependents.length);

	return (
		<ContainerTech>
			<RegularTitle>Dependentes</RegularTitle>
			{Array.isArray(dependents) &&
				dependents.map(({ status, beneficiary, _id }, i) => (
					<Control key={_id}>
						<Dependent to={`/register/dependent-${i + 1}`}>
							<span>
								{get(beneficiary, 'name.full')} - {status}
							</span>
							<img src="/img/right.svg" width="10px" style={{ filter: 'brightness(0.7)' }} alt="" />
						</Dependent>
					</Control>
				))}
			{Array(missingDependents)
				.fill()
				.map((_, i) => (
					<Control key={i}>
						<Dependent to={`/register/dependent-${dependents.length + i + 1}`}>
							<span className="primary">Novo Dependente</span>
							<img src="/img/right.svg" width="10px" style={{ filter: 'brightness(0.7)' }} alt="" />
						</Dependent>
					</Control>
				))}

			{maxDependents === 0 ? <p>Seu plano não permite dependentes ou ainda está aguardando pagamento.</p> : null}

			<SubmitButton
				style={{ marginTop: 16 }}
				onClick={() => {
					location.hash = '/';
				}}
			>
				Finalizar e ir para início
			</SubmitButton>
		</ContainerTech>
	);
};

export default Dependentes;
