import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	justify-content: center;
	height: 100%;
	width: 100%;
`;

export default Container;
