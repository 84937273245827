import axios from 'axios';
import formatDoctor from 'Utils/formatDoctor';

import map from 'lodash/map';
import split from 'lodash/split';
import uniq from 'lodash/uniq';
import get from 'lodash/get';

import { countBy, path, prop, replace, pathEq } from 'ramda';
import { getUserLocation } from 'Hooks/Search/effects';

export const doSearch = async ({ speciality, place, telemedicine, user }) => {
	let userLocation;
	try {
		userLocation = await getUserLocation();
	} catch (e) {}

	try {
		const isCoords = /^@[-]\d+\.?\d*,[-]\d+\.?\d*$/.test(place);
		const filters = { telemedicine };

		if (isCoords) {
			const [lat, lng] = split(place, ',');
			filters.lat = lat.replace('@', '');
			filters.lng = lng;
		} else if (place) {
			const [city, state] = split(decodeURI(place), ' - ');

			const normalizedCity = city
				.split(' ')
				.map(e => {
					if (['DE', 'DA', 'DO', 'DOS', 'DAS'].includes(e)) {
						return e.toLowerCase();
					}
					return e.toLowerCase().replace(/\D/, e[0]);
				})
				.join(' ');

			filters.state = state;
			filters.city = normalizedCity;
		}

		const params = {
			...filters,
			userLocation,
			planId: user.beneficiary.contractPlan._id,
			beneficiaryId: user.patient._id,
		}

		const { data: response } = await axios.get(`/api/v2/search/${speciality}`, { params });
		if (!response.success) return { error: response.error };

		return { result: map(response.data, formatDoctor), warning: response.warning };
	} catch (e) {
		console.error(e);
		return { error: "Algo deu errado aqui =(" };
	}
};

export const buildFilterData = data => {
	const countByProp = props => countBy(path(props.split('.')));

	const cities = countByProp('address.city')(data);
	const districts = uniq(map(data, path(['address', 'district'])));
	const names = map(data, prop('name'));

	return { cities, districts, names };
};

export const applyFilter = ({ filters, data = [] }) => {
	if (filters.name) {
		data = data.filter(item => new RegExp(filters.name, 'i').test(item.name));
	}

	if (Array.isArray(filters.cities) && filters.cities.length) {
		const cities = filters.cities.map(replace(/\s\(\d{1,2}\)$/, ''));
		data = data.filter(item => cities.includes(item.address.city));
	}

	if (filters.district) {
		const district = replace(/\s\(\d{1,2}\)$/, '')(filters.district);
		data = data.filter(pathEq(['address', 'district'], district));
	}

	return data;
};

