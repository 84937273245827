import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Date, Time } from '../../Components/Containers';
import { IconClock, IconDate } from '../../Components/Icons';
import { CardName, CardType, DateInfo } from '../../Components/Typography';

moment.locale('pt-br');

const Done = styled.div`
	display: grid;
	grid-template-areas:
		'title title'
		'subtitle subtitle'
		'date  time'
		'status status';
	width: 100%;
	height: 'auto';
	box-sizing: 'border-box';
	border-radius: 4px;
	background-color: #fff;
	margin-top: 13px;
	box-sizing: border-box;
	color: #4A4A4A;
	padding: 9px 18px;
	margin-bottom: 13px;
	border: 1px solid #4a4a4a
`;

const Status = styled.h3`
	display: flex;
	height: 19px;
	margin-top: 8px;
	border-radius: 4px;
	justify-content: center;
	align-items: center;
`;

const colorCard = (status) => {
	if (status === 'confirmada') return '#4774b1';
	if (status === 'aguardando') return '#6ca3d3';
};

const CardDone = ({ doctor }) => {
	const [redirect, setRedirect] = useState(false);

	const redirectGo = () => {
		setRedirect(true);
	};

	if (redirect) {
		window.location.href = `/#/appointment-detail?code=${doctor.code}`;
	}

	return (
		<Done onClick={redirectGo}>
			<CardName style={{ color: '#4a4a4a' }}>{doctor.credentialed.name.full}</CardName>
			<CardType style={{ color: '#4a4a4a' }}>
				{doctor.speciality.specialityName} <br />
				{doctor.beneficiary.boundType === 'Dependente' && <span>Paciente: {doctor.beneficiary.beneficiary.name.full}</span>}
			</CardType>
			<Date>
				<IconDate />
				<DateInfo style={{ color: '#4a4a4a' }}>{moment(doctor.startAt).format('l')}</DateInfo>
			</Date>
			<Time>
				<IconClock />
				<DateInfo style={{ color: '#4a4a4a' }}>{moment(doctor.startAt).format('LT')}</DateInfo>
			</Time>
			<Status style={{ color: '#fff', backgroundColor: '#046DAA' }}>{doctor.status}</Status>
		</Done>
	);
};

CardDone.propTypes = {
	doctor: PropTypes.object,
};

export default CardDone;
