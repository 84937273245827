/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from 'react';
import useUser from 'Hooks/User';
import axios from 'axios';
import { Container } from '../../Components/Containers';
import Card from './Card';
import CardDone from './CardDone';
import apiFilters from '../../Utils/api-filters';
import { DetailInfo } from '../../Components/Typography';
import DotsLoader from '../../Components/DotsLoader';

const Appointments = () => {
	const { user } = useUser();
	const [appointments, setAppointments] = useState([]);
	const [isLoading, setLoading] = useState(true);

	const getAppointments = async ({ _id }) => {
		const { data: result } = await axios.get(
			'/api/data/Appointment/find',
			apiFilters({ term: 'beneficiary._id', operator: 'equals', value: _id })
		);

		if (result.data && result.data.length) {
			setAppointments((curr) => [...curr, ...result.data]);
		}
		return null;
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		(async () => {
			await getAppointments(user.beneficiary);
			setLoading(false);

			user.dependents.forEach(getAppointments);
		})();
	}, []);

	const filterOpen = ({ status }) => ['Agendada', 'Em Agendamento', 'Aberta', 'Aguardando Código'].includes(status);
	const filterClosed = ({ status }) => ['Realizada', 'Cancelada', 'Não Compareceu'].includes(status);

	const openAppointments = appointments.filter(filterOpen);
	const closedAppointments = appointments.filter(filterClosed);

	return (
		<Container>
			<h1 style={{ fontSize: '32px', marginBottom: '16px' }}> Minhas Consultas </h1>

			{!isLoading && openAppointments.length === 0 && <DetailInfo> Não existem solicitações ativas</DetailInfo>}
			{isLoading && <DotsLoader />}

			{Array.isArray(openAppointments) && openAppointments.map((entry) => <Card doctor={entry} key={entry._id} />)}

			<h1 style={{ fontSize: '32px', marginBottom: '16px', marginTop: '8px' }}> Consultas realizadas: </h1>

			{!isLoading && closedAppointments.length === 0 && <DetailInfo> Não existe histórico de consulta</DetailInfo>}
			{isLoading && <DotsLoader />}

			{Array.isArray(closedAppointments) && closedAppointments.map((entry) => <CardDone doctor={entry} key={entry._id} />)}
		</Container>
	);
};

export default Appointments;
