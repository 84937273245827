import MaskInput from 'react-input-mask';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { InputWrapper } from '../../Components/Forms';

export const Control = styled(InputWrapper)`
	flex-direction: column;
	align-items: baseline;
	margin-bottom: 5px;
	max-width: none;

	&[data-required='true'] {
		span:first-of-type::after {
			content: '*';
		}
	}
`;

export const Input = styled(MaskInput)`
	width: calc(100% - 32px);
	background-color: white;
	margin-top: 4px;
	height: 28px;
	appearance: none;
	box-sizing: initial;

	&[data-invalid=true] {
		border-color: rgba(255, 0, 0, 0.5);
	}
`;

export const Dependent = styled(Link)`
	width: calc(100% - 32px);
	display: flex;
	background: white;
	border: 1px solid lightgrey;
	border-radius: 4px;
	justify-content: space-between;
	padding: 13px;
	color: rgb(56, 56, 56);
	text-decoration: none;
	height: 24px;
	align-items: center;
	& > .primary {
		color: ${({ theme }) => theme.violetRed};
	}
`;

export const HelperText = styled.p`
	line-height: 1.25;
	& a {
		color: ${({ theme }) => theme.violetRed};
	}

	&[data-invalid] {
		color: rgba(255, 0, 0, 0.8);
		margin: 5px 0;
		font-size: 10pt;
	}
`;

export const SubmitButton = styled.button`
	height: 2em;
	font-size: 18px;
	border-radius: 5px;
	color: #ffffff;
	background: ${({ theme }) => theme.violetRed};
	font-family: Lato, sans-serif;
	border: 0;
	padding: 2px 16px;
`;
