import React from 'react';
import PropTypes from 'prop-types';

import useClinicSelect from 'Hooks/Clinic/ClinicSelect';

import { Comparative, AvatarContainer, InfoContainer } from '../Containers';
import { Verified, TitleDoctor, GeneralInfo } from '../Typography';
import { DoctorCard } from '../Cards';
import { DoctorAvatar } from '../Icons';
import { Price, Profile } from './Component';

const DoctorInfo = ({ data, disabled, params }) => {
	const { onClinicSelect } = useClinicSelect();

	return (
		<DoctorCard>
			<AvatarContainer style={{ maxWidth: '40vw' }}>
				<DoctorAvatar style={{ height: '100%' }} src={data.avatarUrl} alt="" />
			</AvatarContainer>
			<InfoContainer style={{ paddingLeft: '8px' }}>
				<div>
					{data.verified ? <Verified data-check>verificado</Verified> : null}
					<TitleDoctor>{data.name}</TitleDoctor>
					<GeneralInfo>{data.email}</GeneralInfo>
					{!params.telemedicine && <GeneralInfo>{data.distance}</GeneralInfo>}
				</div>

				<div style={{ display: 'flex', alignItems: 'flex-end' }}>
					<Price>
						{data.price !== null && data.price > 0 && (
							<>
								<span>R$</span>
								<span>
									{Math.floor(data.price)} <span>,{Number(data.price).toFixed(2).split('.')[1]}</span>
								</span>
							</>
						)}
            {data.price !== null && data.price === 0 && (
							<>
								<span></span>
								<span style={{ fontSize: '12pt', position: "initial" }}>
									Consulta gratuita
								</span>
							</>
						)}
					</Price>
					<Profile disabled={disabled} onClick={onClinicSelect({ data, params })}>
						Ver perfil
					</Profile>
				</div>
			</InfoContainer>
		</DoctorCard>
	);
};

export default DoctorInfo;

DoctorInfo.propTypes = {
	data: PropTypes.object,
};
