import React from 'react';
import map from 'lodash/map';
import omit from 'lodash/omit';

import { Link } from 'react-router-dom';
import useLogin from 'Hooks/Login';

import {
	CPFLabel,
	CPFInput,
	SenhaLabel,
	SenhaInput,
	EmailInput,
	EntrarButton,
	CadastroButton,
	Buttons,
	TooglePassword,
	SmallLink,
	EmailLabel,
} from './Form';
import { Header, Logo } from './Header';
import ErrorList from './ErrorList';
import Card from './Card';
import { Container } from '../../Components/Containers';

const Login = () => {
	const {
		userField,
		emailField,
		passwordField,
		isCadastro,
		isPassShown,
		submited,
		errors,
		hasError,
		toogleShowPass,
		setCadastro,
		onCadastroClick,
		onLoginClick,
	} = useLogin();

	// Before cpf mask apply, onChange
	const beforeChange = (newState, oldState) => {
		const { value: newValue = "" } = newState;
		const { selection = {}, value = "" } = oldState;

		// If the value is erased in more than 1 char, but there's no selection
		// Input autofill bug crops last 2 characters
		if (newValue.length - value.length === -2 && !selection.length && !['.', '-'].some(e => value.endsWith(e))) {
			return oldState;
		}

		return newState;
	};

	return (
		<Container primaryBg>
			<Card>
				<Logo src="/img/logo-header.svg" style={{ margin: '0 auto 2rem', width: '80px' }} />
				<div style={{ display: 'flex', flexFlow: 'wrap-reverse', justifyContent: 'space-between', marginBottom: '36px' }}>
					{submited && !hasError ? (
						<Header style={{ width: 'fit-contain' }}>Quase pronto!</Header>
					) : (
						<Header>{isCadastro ? 'Cadastre-se' : 'Identifique-se'}</Header>
					)}
					{hasError ? (
						<ErrorList>
							{map(errors, (error, i) => (
								<li key={`error-${i}`}>{error.message}</li>
							))}
						</ErrorList>
					) : null}
				</div>
				{submited && !hasError ? (
					<p style={{ gridArea: 'cpf-label' }}>Você receberá um email com instruções de como prosseguir</p>
				) : (
					<form style={{ display: 'flex', flexFlow: 'column', position: 'relative' }} onSubmit={e => e.preventDefault()}>
						{!isCadastro && (
							<>
								<CPFLabel htmlFor="cpf">CPF</CPFLabel>
								<CPFInput
									mask="999\.999\.999\-99"
									id="cpf"
									type="text"
									placeholder="Seu CPF"
									maskChar={null}
									beforeMaskedValueChange={beforeChange}
									{...omit(userField, ['setValue'])}
								/>

								<SenhaLabel htmlFor="senha">Senha</SenhaLabel>
								<div style={{ position: 'relative', display: 'flex' }}>
									<SenhaInput
										type={isPassShown ? 'text' : 'password'}
										id="senha"
										{...omit(passwordField, ['setValue'])}
										placeholder="Sua senha"
									/>
									<TooglePassword onClick={toogleShowPass} role="button" title={isPassShown ? 'Esconder senha' : 'Mostrar senha'}>
										<img src={`/img/eye${isPassShown ? '-slash' : ''}.svg`} alt="Show password" />
									</TooglePassword>
								</div>
							</>
						)}

						{isCadastro && (
							<>
								<EmailLabel htmlFor="email">Email</EmailLabel>
								<EmailInput id="email" type="email" placeholder="Seu email" {...omit(emailField, ['setValue'])} />
							</>
						)}

						<Buttons>
							{!isCadastro && (
								<Buttons>
									<EntrarButton style={{ width: '100%' }} type="submit" onClick={onLoginClick}>
										Entrar
									</EntrarButton>
									{/* <CadastroButton onClick={setCadastro(true)}>Cadastre-se</CadastroButton> */}

									<div style={{ marginTop: '27px' }}>
										<div style={{ marginBottom: '40px' }}>
											<SmallLink as={Link} to="/recover-password">
												Esqueceu a senha?
											</SmallLink>
										</div>

										{/* <SenhaLabel>Ainda não é cliente Avus?</SenhaLabel> */}
										{/* <br /> */}
										{/* <SmallLink onClick={() => window.open('https://app.galaxpay.com.br/avus/planos')}>
											Veja os nossos planos e vantagens
										</SmallLink> */}
									</div>
								</Buttons>
							)}

							{isCadastro && (
								<>
									<CadastroButton
										style={{ width: '100%', marginTop: '18px' }}
										onClick={isCadastro ? onCadastroClick : setCadastro(true)}
									>
										Cadastrar
									</CadastroButton>
									<SmallLink data-back onClick={setCadastro(false)}>
										voltar
									</SmallLink>
								</>
							)}
						</Buttons>
					</form>
				)}
			</Card>
		</Container>
	);
};

export default Login;
