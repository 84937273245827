import React from 'react';
import propTypes from 'prop-types';
// import { compose } from 'ramda';
// import withProps from 'Utils/useProps';

// import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

// const Map = ({ position: [lng, lat], mapStyle }) =>
// 	lat && lng ? (
// 		<GoogleMap defaultZoom={15} defaultCenter={{ lat, lng }}>
// 			<Marker position={{ lat, lng }} />
// 		</GoogleMap>
// 	) : null;

// Map.propTypes = {
// 	position: propTypes.arrayOf(propTypes.number),
// 	mapStyle: propTypes.object
// };

// export default compose(
// 	withProps(props => ({
// 		googleMapURL: `https://maps.googleapis.com/maps/api/js?key=AIzaSyCxEDIFclh43VR60JdK__Y6KdC_jUqVtfw`,
// 		loadingElement: (
// 			<div
// 				className="has-background-grey-lighter is-flex"
// 				style={{ minHeight: `250px`, width: '100%', alignItems: 'center', justifyContent: 'center' }}
// 			>
// 				<span className="button is-loading" />
// 			</div>
// 		),

// 		containerElement: <div style={{ minHeight: `250px`, ...props.mapStyle }} />,
// 		mapElement: <div style={{ minHeight: `250px`, ...props.mapStyle }} />
// 	})),
// 	withScriptjs,
// 	withGoogleMap
// )(Map);

const Map = ({ position: [lng, lat] }) => <a target="_blank" href={`https://google.com/maps/search/${lat},${lng}`}>Abrir Mapa</a>;
export default Map;
