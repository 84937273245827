module.exports = (...items) => {
	return {
		params: {
			filter: {
				match: 'and',
				conditions: [...items]
			}
		}
	};
};
